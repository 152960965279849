import React from 'react';

export type FocusContainmentContextValue = {
  contain: boolean;
  setContain: (contain: boolean) => void;
};

export const FocusContainmentContext =
  React.createContext<FocusContainmentContextValue>({
    contain: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setContain: () => {},
  });

export const FocusContainmentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [contain, setContain] = React.useState(true);
  return (
    <FocusContainmentContext.Provider value={{contain, setContain}}>
      {children}
    </FocusContainmentContext.Provider>
  );
};

export const useFocusContainment = () => {
  const {contain, setContain} = React.useContext(FocusContainmentContext);
  return {contain, setContain};
};
