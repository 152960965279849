// This file was generated using `pay js:run gql-v2 --type=fragment --name=SessionStatic --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';

import type {GraphQlDocument} from '@sail/data';
import type {
  Exact,
  VerificationCreationMethod,
  Country,
  DocumentTypes,
  ConsentImageMode,
  ConsentUseCase,
} from '@stripe-internal/data-gelato/schema/types';
import type {GelatoPermissionToken} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  fragment SessionStaticFragment on Session {
    addressSupportedCountries
    branding {
      additionalPlatformName
      buttonColor
      buttonColorOverride
      isStripe
      platformColor
      platformColorOverride
      platformIcon
      platformIconOverride
      platformName
      platformNameOverride
    }
    consentConfig {
      additionalPrivacyPolicyUrl
      imageMode
      privacyPolicyUrl
      stripeInternal
      useCase
    }
    creationMethod
    defaultCountry
    documentTypeAllowlist
    fMobile
    idNumberCountryAllowlist
    idNumberSupportedCountries
    livemode
    phoneSupportedCountries
    rLCapture
    returnUrl
    skipSuccessPage
    skipWelcomePage
    welcomeHandoff
    support {
      email
      url
    }
    useConnectIframeDesign
  }
`;

export const sessionStaticFragment: SessionStaticFragmentDocument = doc;

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SessionStaticFragmentData = {
  readonly __typename: 'Session';
  readonly addressSupportedCountries: ReadonlyArray<string>;
  readonly creationMethod: VerificationCreationMethod | null;
  readonly defaultCountry: Country;
  readonly documentTypeAllowlist: ReadonlyArray<DocumentTypes> | null;
  readonly fMobile: boolean;
  readonly idNumberCountryAllowlist: ReadonlyArray<Country> | null;
  readonly idNumberSupportedCountries: ReadonlyArray<string>;
  readonly livemode: boolean;
  readonly phoneSupportedCountries: ReadonlyArray<string>;
  readonly rLCapture: boolean;
  readonly returnUrl: string | null;
  readonly skipSuccessPage: boolean | null;
  readonly skipWelcomePage: boolean | null;
  readonly welcomeHandoff: boolean;
  readonly useConnectIframeDesign: boolean | null;
  readonly branding: {
    readonly __typename: 'BrandingData';
    readonly additionalPlatformName: string | null;
    readonly buttonColor: string;
    readonly buttonColorOverride: string;
    readonly isStripe: boolean;
    readonly platformColor: string;
    readonly platformColorOverride: string;
    readonly platformIcon: string;
    readonly platformIconOverride: string;
    readonly platformName: string;
    readonly platformNameOverride: string;
  };
  readonly consentConfig: {
    readonly __typename: 'ConsentConfig';
    readonly additionalPrivacyPolicyUrl: string | null;
    readonly imageMode: ConsentImageMode;
    readonly privacyPolicyUrl: string | null;
    readonly stripeInternal: boolean;
    readonly useCase: ConsentUseCase;
  };
  readonly support: {
    readonly __typename: 'SupportData';
    readonly email: string;
    readonly url: string;
  };
};

export type SessionStaticFragmentVariables = Exact<{[key: string]: never}>;

const sessionStaticFragmentPermissions = {} as const;

doc.permissions = sessionStaticFragmentPermissions;

export type SessionStaticFragmentPermissions = GelatoPermissionToken<
  typeof sessionStaticFragmentPermissions
>;

export type SessionStaticFragmentDocument = GraphQlDocument<
  SessionStaticFragmentData,
  SessionStaticFragmentVariables,
  SessionStaticFragmentPermissions
>;
