import * as React from 'react';
import type {RefObject} from 'react';
import {createView, view} from '@sail/engine';
import {focusSafely, getFocusableTreeWalker} from '@sail/react-aria';
import type {FocusManager} from '@sail/react-aria';
import type {FocusableElement} from '@react-types/shared';
import {visuallyHidden} from '../../utilStyles/visuallyHidden';
import type {View} from '../../view';
import type {PopoverProps} from './PopoverProps';

/**
 * Strips out deprecated props from the Popover props object and returns
 * the rest.
 */
export const replaceDeprecatedProps = <
  T extends View.RenderProps<PopoverProps>,
>(
  props: T,
) => {
  const {
    shouldFlip,
    shouldUpdatePosition,
    shouldRespectScrollBoundary,
    shouldCloseOnBlur,
    ...rest
  } = props;

  if (shouldCloseOnBlur !== undefined) {
    rest.dismissible = shouldCloseOnBlur;
  }

  if (shouldFlip !== undefined) {
    rest.flip = shouldFlip;
  }

  return rest;
};

export const FocusGuard = createView((props: View.IntrinsicElement<'span'>) => {
  return <view.span hidden tabIndex={0} uses={[visuallyHidden]} {...props} />;
});

export const moveFocus =
  <T extends HTMLElement>(ref: RefObject<FocusManager>, dir: 'next' | 'prev') =>
  (event: React.FocusEvent<T>) => {
    const manager = ref.current;
    // first try moving focus within the popover
    const node = dir === 'next' ? manager?.focusFirst() : manager?.focusLast();

    // if the manager didn't find a focusable element within scope,
    // focus the next/previous element outside the popover.
    if (!node) {
      const root = event.currentTarget.getRootNode() as Element;
      const walker = getFocusableTreeWalker(root, {
        tabbable: true,
        from: event.currentTarget,
      });

      const el = dir === 'next' ? walker.nextNode() : walker.previousNode();

      if (el) {
        focusSafely(el as FocusableElement);
      }
    }
  };
