import type {
  CollectionBase,
  MultipleSelection,
  Node,
} from '@react-types/shared';
import type {Key} from 'react';
import {useEffect, useMemo} from 'react';
import {
  SelectionManager,
  useMultipleSelectionState,
} from '../../@react-stately/selection';
import {useControlledState} from '../../@react-stately/utils';
import {TreeCollection} from './ReactAriaTreeCollection';
import type {Collection, Item} from '../types';
import type {TreeState, Expandable} from './types';

function getItemNode(
  item: Item,
  index: number,
  parentItem?: Item,
  level = 0,
): Node<unknown> {
  return {
    type: item.type,
    props: item.data,
    rendered: null,
    textValue: item.textValue || '',
    'aria-label': item['aria-label'],
    hasChildNodes: Boolean(item.childItems?.length),
    childNodes:
      item.childItems?.map((child, i) =>
        getItemNode(child, i, item, level + 1),
      ) || [],

    key: item.key,
    parentKey: parentItem ? parentItem.key : undefined,
    level,
    index,
    value: item.data,
  };
}

export function toReactAriaCollection(
  getItems: Collection['getItems'],
  filter?: (items: Array<Item>) => Array<Item>,
): Array<Node<unknown>> {
  const items = filter ? filter(getItems()) : getItems();
  return items.map((item: Item, i: number) => getItemNode(item, i));
}

export interface TreeProps
  extends Omit<CollectionBase<unknown>, 'children'>,
    Expandable,
    MultipleSelection {
  getItems: Collection['getItems'];
  filter?: (items: Array<Item>) => Array<Item>;
}

function toggleKey(set: Set<string>, key: string): Set<string> {
  const res = new Set(set);
  if (res.has(key)) {
    res.delete(key);
  } else {
    res.add(key);
  }

  return res;
}

/**
 * Provides state management for tree-like components. Handles building a collection
 * of items from props, item expanded state, and manages multiple selection state.
 */
export function useReactAriaTreeState({
  expandedKeys,
  defaultExpandedKeys,
  onExpandedChange,
  disabledKeys,
  getItems,
  filter,
  defaultSelectedKeys,
  selectedKeys,
  disallowEmptySelection,
  onSelectionChange,
  selectionMode,
  expandable,
}: TreeProps): TreeState {
  const allKeys = () => getItems().map((x) => x.key);
  const [expandedKeysSet, setExpandedKeys] = useControlledState<Set<string>>(
    expandedKeys ? new Set(expandedKeys) : undefined,
    defaultExpandedKeys
      ? new Set(defaultExpandedKeys === 'all' ? allKeys() : defaultExpandedKeys)
      : new Set(),
    onExpandedChange,
  );

  const selectionState = useMultipleSelectionState({
    selectionMode,
    disallowEmptySelection,
    allowDuplicateSelectionEvents: true,
    selectedKeys,
    defaultSelectedKeys,
    onSelectionChange,
    disabledKeys,
  });

  const disabledKeysSet = useMemo(
    () => (disabledKeys ? new Set(disabledKeys) : new Set<Key>()),
    [disabledKeys],
  );

  const collection = useMemo(
    () =>
      new TreeCollection(toReactAriaCollection(getItems, filter), {
        expandedKeys: expandedKeysSet,
      }),
    [getItems, filter, expandedKeysSet],
  );

  // Reset focused key if that item is deleted from the collection.
  useEffect(() => {
    if (
      selectionState.focusedKey != null &&
      !collection.getItem(selectionState.focusedKey)
    ) {
      selectionState.setFocusedKey(null);
    }
    // This is copied from react-aria. We cannot add `selectionState.setFocusedKey`
    // here as it's a new function each render. All that function does it set refs
    // so this is safe, but we must disabled this rule because of react-aria's code
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, selectionState.focusedKey]);

  const onToggle = (key: string) => {
    setExpandedKeys(toggleKey(expandedKeysSet, key));
  };

  return {
    collection,
    expandedKeys: expandedKeysSet as Set<string>,
    disabledKeys: disabledKeysSet as Set<string>,
    toggleKey: onToggle,
    selectionManager: new SelectionManager(collection, selectionState),
    setExpandedKeys,
    expandable: Boolean(expandable),
  };
}
