import {createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {css, baseline} from '../css';
import {tokens} from '../tokens';

export type CounterProps = View.IntrinsicElement<
  'span',
  {
    type: 'emphasized' | 'micro' | 'default';
    children?: React.ReactNode;
  }
>;

export const CounterConfig = createViewConfig({
  props: {} as CounterProps,
  name: 'Counter',
  flattens: true,
  defaults: {
    type: 'default',
  },
});

export const Counter = CounterConfig.createView('span', {
  uses: [baseline.css('x-middle')],
  css: {
    display: 'inline',
    whiteSpace: 'nowrap',
    stack: 'x',
    alignY: 'center',
    alignX: 'center',
    fontSize: '12px',
    height: 'space.250',
    lineHeight: '20px',
    fontWeight: 'semibold',
    borderRadius: 'rounded',
    paddingX: 'space.75',
  },
  variants: {
    type: {
      default: css({
        minWidth: 'space.250',
        color: 'text',
        backgroundColor: tokens.color.neutral[100],
      }),
      emphasized: css({
        minWidth: 'space.250',
        textColor: tokens.color.neutral[0],
        backgroundColor: tokens.color.feedback.critical[400],
      }),
      micro: [
        css({
          height: 'space.75',
          width: 'space.75',
          borderRadius: 'rounded',
          backgroundColor: tokens.color.feedback.critical[400],
          border: '2px solid',
          borderColor: tokens.color.background.surface,
          padding: 'space.0',
          boxSizing: 'content-box',
        }),
        css({
          textIndent: '-9999px',
        }),
      ],
    },
  },
});
