// This file was generated using `pay js:run gql-v2 --type=mutation --name=SendDocumentImage --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  DocumentFileDataInput,
  CaptureFrameTypes,
  FieldErrors,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation SendDocumentImageMutation(
    $fileData: DocumentFileDataInput!
    $frameType: CaptureFrameTypes!
  ) {
    captureDocumentImageFrame(fileData: $fileData, frameType: $frameType) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        path
        type
        hardBlock
        restartCollection
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const sendDocumentImageMutation: SendDocumentImageMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const sendDocumentImageMutationOptions: GelatoMutationOptionsWithoutVariables<SendDocumentImageMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useSendDocumentImageMutation(
  options: GelatoMutationOptions<SendDocumentImageMutationDocument> = {},
): GelatoMutationReturn<SendDocumentImageMutationDocument> {
  return useGelatoMutation(sendDocumentImageMutation, {
    ...sendDocumentImageMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SendDocumentImageMutationVariables = Exact<{
  fileData: DocumentFileDataInput;
  frameType: CaptureFrameTypes;
}>;

export type SendDocumentImageMutationData = {
  readonly __typename: 'Mutation';
  readonly captureDocumentImageFrame: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<{
      readonly __typename: 'ValidationError';
      readonly path: ReadonlyArray<string>;
      readonly type: FieldErrors;
      readonly hardBlock: boolean;
      readonly restartCollection: boolean;
    }>;
  };
};

const sendDocumentImageMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = sendDocumentImageMutationPermissions;

export type SendDocumentImageMutationPermissions = GelatoPermissionToken<
  typeof sendDocumentImageMutationPermissions
>;

export type SendDocumentImageMutationDocument = GraphQlDocument<
  SendDocumentImageMutationData,
  SendDocumentImageMutationVariables,
  SendDocumentImageMutationPermissions
>;
