import type {Style} from '@sail/engine';
import {paddingBottomProp, paddingTopProp} from './pluginsBoxModel';
import {
  baselineAlignmentContent,
  hasBaselineLayout,
  columnGap,
  objectHeight,
} from './pluginsLayout';
import {transformAlignY} from './pluginsTransform';
import {universalSelectorDisabled} from '../utils';
import {
  getAlphabeticBaseline,
  getReferenceBaseline,
  getTargetBaseline,
} from '../intents/font-utils';
import {
  ascenderProp,
  capHeightProp,
  descenderProp,
  FontProperties,
  fontSizeProp,
  hasBaselineFont,
  lineHeightProp,
  xHeightProp,
} from '../custom-properties/font';

function divide(a: string, b: string) {
  return `calc(${a} / ${b})`;
}

function setFontProperties(
  set: Style.PluginAPI,
  properties: FontProperties,
  value: string,
) {
  set.property(properties.current, value);

  if (!universalSelectorDisabled) {
    set.selector(`& > *, &::before, &::after`, () => {
      set.property(properties.inherited, value);
    });
  }
}

export function setFontMetrics(
  set: Style.PluginAPI,
  unitsPerEm: string,
  ascender: string,
  capHeight: string,
  xHeight: string,
  descender: string,
  _lineGap: string,
  _valueHash: string,
): void {
  set.property('--font-metrics', () => {
    setFontProperties(set, ascenderProp, divide(ascender, unitsPerEm));
    setFontProperties(set, capHeightProp, divide(capHeight, unitsPerEm));
    setFontProperties(set, xHeightProp, divide(xHeight, unitsPerEm));
    setFontProperties(set, descenderProp, divide(descender, unitsPerEm));
  });
}

function createFontPropertyPlugin(
  name: 'fontSize' | 'lineHeight',
  properties: FontProperties,
) {
  return (value: string, set: Style.PluginAPI): void => {
    set.property(name, () => {
      if (value !== 'inherit') {
        setFontProperties(set, properties, value);
      }
      set.property(name, value);
      set.property('baselineAlign', 'auto');
    });
  };
}

function baselineAlign(value: string, set: Style.PluginAPI): void {
  set.property('--baseline-align', () => {
    const isActive = value === 'auto' || value === 'manual';

    if (!universalSelectorDisabled) {
      set.selector('& > *', () => {
        set.property(hasBaselineFont, isActive ? '1' : '0');
      });
    }

    if (value === 'auto') {
      set.selector('&::before', () => {
        set.reset({
          content: set.var(baselineAlignmentContent),
          userSelect: 'none',
          alignSelf: 'baseline',
          // The CSS `gap` property injects a gap between the ::before pseudo-element
          // and the node’s first child. We use a negative margin to remove that gap.
          marginRight: `calc(-1 * ${set.var(columnGap)})`,
        });
      });
    }
  });
}

function baseline(value: string, set: Style.PluginAPI): void {
  const inheritedBaseline = getAlphabeticBaseline(set, 'inherited');
  const currentBaseline = getAlphabeticBaseline(set, 'current');
  const baselineDiff = `(${inheritedBaseline} - ${set.var(
    objectHeight,
    currentBaseline,
  )})`;
  const multiplier = `${set.var(hasBaselineLayout)} * ${set.var(
    hasBaselineFont,
  )} * clamp(0, 10000 * ${set.var(ascenderProp.inherited)}, 1)`;
  function calc(value: string) {
    return `calc((${value}) * ${multiplier})`;
  }

  const marginTop = calc(
    `${baselineDiff} - ${set.var(paddingTopProp)} - 0.5px`,
  );
  set.property('marginTop', marginTop);

  const inheritedLineHeight = set.var(lineHeightProp.inherited);
  const currentLineHeight = set.var(lineHeightProp.current);
  const paddingBottom = set.var(paddingBottomProp);
  const marginBottom = `max(0, ${calc(
    `${inheritedLineHeight} - ${currentLineHeight} - ${baselineDiff} - ${paddingBottom}`,
  )})`;
  set.property('marginBottom', marginBottom);

  // eslint-disable-next-line prefer-const
  let [reference = 'alphabetic', target] = value.trim().split(' ');

  if (!target) {
    switch (reference) {
      case 'cap-middle':
      case 'x-middle':
      case 'x-height':
        target = 'cap-middle';
        break;
      default:
        target = 'alphabetic';
        break;
    }
  }

  const referenceBaseline = getReferenceBaseline(set, reference, 'inherited');
  const targetBaseline = getTargetBaseline(set, target);
  if (!referenceBaseline || !targetBaseline) {
    if (process.env.NODE_ENV === 'production') {
      return;
    }
    throw new Error(`Invalid baseline "${value}"`);
  }
  set.property(
    transformAlignY,
    calc(`${targetBaseline} - ${referenceBaseline}`),
  );
}

export const pluginsFont = {
  baseline,
  baselineAlign,
  fontSize: createFontPropertyPlugin('fontSize', fontSizeProp),
  lineHeight: createFontPropertyPlugin('lineHeight', lineHeightProp),
};
