import {Intent} from '@sail/engine';
import {external as externalIcon} from '@sail/icons/react/Icon';
import {Icon, view, css} from '@sail/ui';
import * as React from 'react';

import {
  allReset,
  underline as underlineAll,
  tBoldL,
  iconSubdued,
  tColorNormal,
} from 'gelato/frontend/src/components/stylesV2';
import addDOMPointerEventDragHandlers from 'gelato/frontend/src/lib/addDOMPointerEventDragHandlers';

type Props = {
  bold?: boolean;
  children?: React.ReactNode;
  external?: boolean;
  href?: string | null | undefined;
  onClick?: () => void | Promise<void> | null | undefined;
  uses?: Intent[] | null | undefined;
};

const {useCallback, useEffect, useRef} = React;

const UNDERLINE_STYLES = {
  textColor: '#000',
  textDecorationColor: '#000',
  textDecorationThickness: '2px',
};

const Styles = {
  element: css({
    cursor: 'pointer',
  }),
  underline: css({
    ':active': UNDERLINE_STYLES,
    ':focus': UNDERLINE_STYLES,
    ':hover': UNDERLINE_STYLES,
  }),
  icon: css({
    marginX: 'small',
    opacity: 0.75,
    position: 'relative',
    top: 'space.1',
  }),
};

/**
 * The side effect that marks the link as pressed when the user starts pressing
 * the link.
 * @param domRef The DOM ref of the link element.
 * @returns A ref object that indicates whether the link is pressed.
 */
function useIsPressed(domRef: React.RefObject<HTMLElement>) {
  const isPressed = useRef(false);
  useEffect(() => {
    const dom = domRef.current;
    if (!dom) {
      return;
    }
    const cancel = addDOMPointerEventDragHandlers(dom, {
      handleDragStart: () => {
        isPressed.current = true;
      },
      handleDragMove: () => {
        // no-op
      },
      handleDragEnd: () => {
        // no-op
      },
    });

    return () => {
      cancel();
      isPressed.current = false;
    };
  }, [domRef, isPressed]);
  return isPressed;
}

export default function Link({
  bold,
  children,
  external,
  href,
  onClick,
  uses = [],
  ...props
}: Props): JSX.Element {
  const styles: Intent[] = [
    allReset,
    Styles.element,
    Styles.underline,
    tColorNormal,
    underlineAll,
  ];
  if (bold) {
    styles.push(tBoldL);
  }

  const domRef = useRef<HTMLElement>(null);
  const isPressed = useIsPressed(domRef);

  const handleClick = useCallback(
    (event: React.SyntheticEvent<HTMLElement>) => {
      const me = event.nativeEvent as MouseEvent;
      const {clientX, clientY, screenX, screenY, isTrusted} = me;

      // Check if the click event is synthetic (e.g. clicked by a script from
      // synthetic test).
      const isSyntheticClick =
        // Click is not generated by a user action.
        !isTrusted &&
        clientX === 0 &&
        clientY === 0 &&
        screenX === 0 &&
        screenY === 0;

      if (!isPressed.current && !isSyntheticClick) {
        // Allow the synthetic click event which is generated by a script from
        // synthetic / unit test to pass through (e.g. link.click() in test
        // case).
        //
        // Ignore clicks that didn't originate from the link element. This
        // situation may occur when a user initially clicked a different element,
        // which was then rapidly replaced by the current link element during a
        // page or layer transition. In such cases, the mouse pointer is released
        // over the link element.
        return;
      }

      isPressed.current = false;
      onClick && onClick();
    },
    [isPressed, onClick],
  );

  if (uses && uses.length > 0) {
    styles.push(...uses);
  }

  if (href) {
    // As web link.
    const isNewWindow = /^http/.test(href);
    const target = isNewWindow ? '_blank' : undefined;
    const icon = external ? (
      <Icon
        icon={externalIcon}
        size="xsmall"
        uses={[Styles.icon, iconSubdued]}
      />
    ) : undefined;
    return (
      <view.a
        {...props}
        href={href}
        onClick={handleClick}
        ref={domRef}
        target={target}
        uses={styles}
      >
        {children}
        {icon}
      </view.a>
    );
  } else {
    // As button link.
    return (
      <view.button {...props} onClick={handleClick} ref={domRef} uses={styles}>
        {children}
      </view.button>
    );
  }
}
