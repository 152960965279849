import {useAnalytics, useReports} from '@sail/observability';
import {getEnvironment} from '@sail/utils';
import * as React from 'react';
import {extractAccesses, wrapWithProxy} from 'src/internal/deadwood/helpers';
import {requestIdleCallback} from 'src/internal/deadwood/idleCallback';
import useDataConfig from 'src/public/useDataConfig';

import type {Errors} from '@sail/observability';
import type {DocumentNode} from 'graphql';
import type {
  DataOfDoc,
  GraphQlDocument,
  MutationReturn,
  QueryReturn,
} from 'src/internal/apollo/types';

export const DEFAULT_TRACK_ACCESSES_SAMPLE_RATE = 0.005;

// Hardcoded for now -- might want this to be configurable per-query
// in the future. Fields to ignore tracking accesses for.
export const DEFAULT_IGNORE_TRACKING_FIELDS = ['metadata'];

const {useState, useRef, useEffect} = React;

const tryWrap = <T,>(obj: T, reportError: Errors['error']): T => {
  try {
    return wrapWithProxy(obj);
  } catch (error: unknown) {
    const project = 'sail_core';

    reportError(`[${project}]: Encountered an error while deadwooding`, {
      project,
      extras: {error},
    });

    return obj;
  }
};

type TrackingOptions = {
  // Proportion of views that get tracked.
  sampleRate: number | null | undefined;
  // Keys to ignore all sub-fields of. Intended for user-supplied fields outside
  // the schema like `metadata`.
  ignore?: Array<string>;
};

type GraphQLTrackingOptions = TrackingOptions & {
  // If tracking a GraphQL query, provide it.
  query: DocumentNode;
  ignoreFields: Array<string>;
};

const useShouldTrack = (options: TrackingOptions): boolean => {
  const config = useDataConfig();
  const disableTracking = config.graphql?.disableDeadwood;

  // Skip sample rate and always track in tests to avoid nondeterministic failures
  const random = useState(getEnvironment() === 'test' ? 0 : Math.random())[0];

  return (
    !disableTracking &&
    options &&
    options.sampleRate != null &&
    random < options.sampleRate
  );
};

const hasShallowDifferences = <Obj extends {}, Ref extends {}>(
  obj: Obj,
  ref?: Ref | null,
): boolean => {
  if (!ref) {
    return true;
  }

  const objKeys = Object.keys(obj);
  const refKeys = Object.keys(ref);

  if (objKeys.length !== refKeys.length) {
    return true;
  }

  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'. | No index signature with a parameter of type 'string' was found on type '{}'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'. | No index signature with a parameter of type 'string' was found on type '{}'.
  return objKeys.some((key) => obj[key] !== ref[key]);
};

export const useTrackGraphQlFieldAccesses = <
  TDocumentNode extends GraphQlDocument<any, any, any>,
  Result extends
    | QueryReturn<TDocumentNode>
    | MutationReturn<TDocumentNode>
    | {data?: DataOfDoc<TDocumentNode>},
>(
  obj: Result,
  options: GraphQLTrackingOptions,
): Result => {
  const analytics = useAnalytics();
  const {error} = useReports();
  const shouldTrackAccesses = useShouldTrack(options);
  const objRef = useRef<Result | undefined>();
  const proxyResultRef = useRef<Result | undefined>();

  // Manually memoize the proxy
  if (shouldTrackAccesses && hasShallowDifferences(obj, objRef.current)) {
    proxyResultRef.current = {
      ...obj,
      // if the objRef and obj have the same data, use the proxy object's data if defined
      // otherwise, wrap obj.data in a proxy and use it as the data in the proxy object
      data:
        objRef.current?.data === obj.data && proxyResultRef.current?.data
          ? proxyResultRef.current?.data
          : tryWrap(obj.data, error),
    };
    // This ref needs to change _after_ proxyResultRef because setting data on proxyResultRef requires comparing obj to objRef.current
    objRef.current = obj;
  } else if (!shouldTrackAccesses) {
    proxyResultRef.current = undefined;
  }

  // closest we can get to an "on unmount" hook
  useEffect(() => {
    if (shouldTrackAccesses) {
      return () => {
        requestIdleCallback(() => {
          const operationDefs = options.query.definitions
            .map((def) => (def.kind === 'OperationDefinition' ? def : null))
            .filter(Boolean);
          const queryName = operationDefs[0]?.name?.value ?? 'UnknownQuery';
          const accesses = extractAccesses(
            proxyResultRef.current?.data,
            options.ignore,
          );
          if (accesses.length > 0) {
            analytics.action('deadwood_accessed_fields', {
              field_accesses: accesses,
              query_name: queryName,
              page_name: null,
            });
          }
        });
      };
    }
    // eslint-disable-next-line @stripe-internal/stripe/exhaustive-deps
  }, []);

  return proxyResultRef.current ?? obj;
};
