// This file was generated using `pay js:run gql-v2 --type=mutation --name=Feedback --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import type {ApolloError, GraphQlDocument} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  Scalars,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  mutation FeedbackMutation(
    $email: String
    $message: String!
    $browser: String
    $operatingSystem: String
    $userAgent: String
    $width: Int
    $height: Int
    $locale: String
  ) {
    feedback(
      email: $email
      message: $message
      browser: $browser
      operatingSystem: $operatingSystem
      userAgent: $userAgent
      width: $width
      height: $height
      locale: $locale
    ) {
      success
    }
  }
`;

export const feedbackMutation: FeedbackMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const feedbackMutationOptions: GelatoMutationOptionsWithoutVariables<FeedbackMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useFeedbackMutation(
  options: GelatoMutationOptions<FeedbackMutationDocument> = {},
): GelatoMutationReturn<FeedbackMutationDocument> {
  return useGelatoMutation(feedbackMutation, {
    ...feedbackMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type FeedbackMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  browser?: InputMaybe<Scalars['String']>;
  operatingSystem?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
}>;

export type FeedbackMutationData = {
  readonly __typename: 'Mutation';
  readonly feedback: {
    readonly __typename: 'SuccessResult';
    readonly success: boolean;
  };
};

const feedbackMutationPermissions = {} as const;

doc.permissions = feedbackMutationPermissions;

export type FeedbackMutationPermissions = GelatoPermissionToken<
  typeof feedbackMutationPermissions
>;

export type FeedbackMutationDocument = GraphQlDocument<
  FeedbackMutationData,
  FeedbackMutationVariables,
  FeedbackMutationPermissions
>;
