// This file was generated using `pay js:run gql-v2 --type=mutation --name=SetTestmodeSetting --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {Exact, Scalars} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation SetTestmodeSettingMutation($verify: Boolean!) {
    setTestMode(verify: $verify) {
      success
      session {
        id
        ...SessionDynamicFragment
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const setTestmodeSettingMutation: SetTestmodeSettingMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const setTestmodeSettingMutationOptions: GelatoMutationOptionsWithoutVariables<SetTestmodeSettingMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useSetTestmodeSettingMutation(
  options: GelatoMutationOptions<SetTestmodeSettingMutationDocument> = {},
): GelatoMutationReturn<SetTestmodeSettingMutationDocument> {
  return useGelatoMutation(setTestmodeSettingMutation, {
    ...setTestmodeSettingMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SetTestmodeSettingMutationVariables = Exact<{
  verify: Scalars['Boolean'];
}>;

export type SetTestmodeSettingMutationData = {
  readonly __typename: 'Mutation';
  readonly setTestMode: {
    readonly __typename: 'SetTestModeResult';
    readonly success: boolean;
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
  };
};

const setTestmodeSettingMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = setTestmodeSettingMutationPermissions;

export type SetTestmodeSettingMutationPermissions = GelatoPermissionToken<
  typeof setTestmodeSettingMutationPermissions
>;

export type SetTestmodeSettingMutationDocument = GraphQlDocument<
  SetTestmodeSettingMutationData,
  SetTestmodeSettingMutationVariables,
  SetTestmodeSettingMutationPermissions
>;
