import type {Placement} from '@react-types/overlays';
import {useContext} from 'react';
import type {FlipValue} from '../components/popover';
import type {
  IUILayerRepositionValues,
  IframeAttachmentPoints,
  PlacementInput,
} from './types';
import {FauxTreeContext} from '../components/FauxTree';
import {ListBoxContext} from '../components/ListBox';

/**
 * Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
 * where each x is replaced with a random hexadecimal digit from 0 to f, and y
 * is replaced with a random hexadecimal digit from 8 to b.
 *
 * Taken from https://gist.github.com/jed/982883
 */
export const generateUUID = (a = ''): string => {
  /* eslint-disable no-bitwise */
  return a
    ? (
        parseInt(a, 10) ^
        ((Math.random() * 16) >> (parseInt(a, 10) / 4))
      ).toString(16)
    : '00000000-0000-4000-8000-000000000000'.replace(/[08]/g, generateUUID);
  /* eslint-enable no-bitwise */
};

export const getScrollParent = (
  node: HTMLElement | null,
): HTMLElement | null => {
  // If we hit the document.body, we want to return null so the IntersectionObserver uses the visual viewport instead.
  if (!node || node.tagName === 'BODY') {
    return null;
  }

  // We use offsetHeight instead of clientHeight because clientHeight returns 0 for elements with no CSS or inline layout boxes
  // See https://developer.mozilla.org/en-US/docs/Web/API/Element/clientHeight
  if (node.scrollHeight > node.offsetHeight) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
};

/**
 * Calculate the attachment points for the accessory frame with respect to the source element,
 * i.e. the element that triggered the accessory frame.
 */
export const getRepositionValues = ({
  sourceElement,
  margin,
  placement,
  flip,
}: {
  sourceElement: HTMLElement;
  margin: string;
  placement: Placement;
  flip: FlipValue;
}): IUILayerRepositionValues => {
  const sourceWindow = sourceElement.ownerDocument.defaultView ?? window;
  const sourceRect = sourceElement.getBoundingClientRect();
  const bottom = sourceRect.bottom + sourceWindow.scrollY;
  const top = sourceRect.top + sourceWindow.scrollY;
  const left = sourceRect.left + sourceWindow.scrollX;
  const right = sourceRect.right + sourceWindow.scrollX;
  const attachmentPoints: IframeAttachmentPoints = {
    width: sourceRect.width,
    height: sourceRect.height,
    margin,
    bottom,
    top,
    left,
    right,
    centerX: left + (right - left) / 2,
    centerY: top + (bottom - top) / 2,
  };

  return {
    attachmentPoints,
    placement: placement
      .replace('start', 'left')
      .replace('end', 'right') as PlacementInput,
    sourceId: sourceWindow.name,
    flip,
  };
};

function canAccessFrame(frame: Window) {
  try {
    // Accessing frame.name will result in a "Blocked a frame with origin "XXX" from accessing a cross-origin frame."
    const name = frame.name;
    return !!name;
  } catch (e) {
    return false;
  }
}

export const getAccessibleFrames = (frame: Window = window): Window[] => {
  const {parent} = frame;
  if (frame === parent) {
    return [];
  }

  const parentFrames = window.parent.frames;
  const accessibleFrames = [];
  // We have to iterate using an index since map/foreach/for-in all throw exceptions outside the iterator
  for (let i = 0; i < parentFrames.length; i += 1) {
    const frame = parentFrames[i];
    if (canAccessFrame(frame)) {
      accessibleFrames.push(frame);
    }
  }

  return [...getAccessibleFrames(parent.parent), ...accessibleFrames];
};

export const useIsWithinListBoxContext = () => {
  const listBoxContext = useContext(ListBoxContext);
  // FauxTree is a modified version of ListBox used in SelectField and SearchField to support multi-selection
  // The component will be replaced once we have a proper Tree component
  const fauxTreeContext = useContext(FauxTreeContext);
  return (
    Object.keys(listBoxContext).length > 0 ||
    Object.keys(fauxTreeContext).length > 0
  );
};
