import * as React from 'react';
import {useEffect} from 'react';
import type {SectionTransforms, TreeState} from '@sail/react-aria';
import {
  useCollectionState,
  setInteractionModality,
  useId,
  useItem,
  useListBoxSection,
} from '@sail/react-aria';
import type {View} from '@sail/engine';
import {assignProps, view, createViewConfig} from '@sail/engine';
import {chevronDown} from '@sail/icons/react/Icon';
import {Header} from './Header';
import {Icon} from './Icon';

export type ListBoxGroupProps = {
  id?: string;
  /**
   * A heading grouping the menu items.
   */
  title?: React.ReactNode;
  /** Child items that belong to the group. */
  children?: React.ReactNode;

  textValue?: string;

  /**
   * An accessibility label for the group.
   */
  'aria-label'?: string;

  subviews?: View.Subviews<{
    header: typeof Header;
    list: 'div';
    // collapsibleIcon: typeof Icon;
  }>;
};

export const ListBoxGroupTransforms: SectionTransforms<
  ListBoxGroupProps,
  'id'
> = {
  idField: 'id',
  makeGenericItem(props: ListBoxGroupProps) {
    return {
      type: 'section',
      'aria-label': props['aria-label'],
      textValue:
        typeof props.title === 'string' ? props.title : props.textValue ?? '',
    };
  },
};

export const ListBoxGroupConfig = createViewConfig({
  name: 'ListBoxGroup',
  props: {} as ListBoxGroupProps,
});

export const ListBoxGroup = ListBoxGroupConfig.createView(
  function ListBoxGroup({id, children, title, subviews, ...props}) {
    const headerRef = React.useRef<HTMLElement>(null);
    const key = useItem(
      id,
      ListBoxGroupTransforms.makeGenericItem({
        'aria-label': props['aria-label'],
        title,
      }),
    );

    const {
      itemProps,
      headingProps: {id: headingId},
      groupProps,
    } = useListBoxSection({
      heading: title,
      'aria-label': props['aria-label'],
    });

    const state = useCollectionState();
    const tree = state as TreeState;
    const isOpen =
      !tree.expandable ||
      tree.expandedKeys === 'all' ||
      tree.expandedKeys.has(key);

    const groupId = useId();
    const triggerProps = {
      role: 'button',
      'aria-expanded': isOpen,
      'aria-controls': groupId,
    };

    const focusedItem = state.collection.getItem(
      state.selectionManager.focusedKey,
    );

    useEffect(() => {
      if (focusedItem && tree.expandedKeys !== 'all') {
        // expand all parents of the focused item
        let {parentKey} = focusedItem;
        const keysToExpand = new Set<string>();

        while (parentKey) {
          keysToExpand.add(parentKey as string);
          const parent = tree.collection.getItem(parentKey);
          parentKey =
            parentKey === parent?.parentKey ? undefined : parent?.parentKey;
        }
        if (
          Array.from(keysToExpand).some(
            (key) => !(tree.expandedKeys as Set<string>).has(key),
          )
        ) {
          tree.setExpandedKeys(
            new Set([...keysToExpand, ...tree.expandedKeys]),
          );
          setInteractionModality('keyboard');
        }
      }
    }, [tree, focusedItem]);

    return (
      <view.div {...props} {...itemProps}>
        {title ? (
          <Header
            id={headingId}
            role="presentation"
            inherits={subviews.header}
            onClick={() => {
              if (tree.expandable) {
                tree.selectionManager.setFocusedKey(null);
                tree.toggleKey(key);
              }
            }}
            onMouseDown={(e) => {
              // Prevent focus going to the listbox when clicking the section header.
              if (headerRef.current?.contains(e.target as Node)) {
                e.preventDefault();
              }
            }}
            uses={[assignProps(tree.expandable ? triggerProps : {})]}
            css={{
              cursor: !tree.expandable ? 'default' : 'pointer',
            }}
            ref={headerRef}
          >
            {tree.expandable ? (
              <Icon
                size="xsmall"
                icon={chevronDown}
                css={{
                  transition: 'transform 150ms',
                  transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                  marginX: 'xxsmall',
                }}
              />
            ) : null}
            {title}
          </Header>
        ) : null}

        <section role="region" id={id} {...groupProps}>
          {isOpen ? children : null}
        </section>
      </view.div>
    );
  },
  {
    css: {
      padding: 'xsmall',
      bleedX: 'xsmall',
      borderWidth: '0',
      borderTopWidth: 'small',
      borderStyle: 'solid',
      borderColor: 'border',

      ':first-of-type': {
        borderTopWidth: '0',
        bleedTop: 'xsmall',
      },

      ':last-of-type': {
        bleedBottom: 'xsmall',
      },
    },
    subviews: {
      header: {
        css: {
          stack: 'x',
          gap: 'space.75',
          distribute: 'packed',
          font: 'label.small.emphasized',
          color: 'subdued',
          paddingY: 'xsmall',
          paddingX: 'space.75',
          userSelect: 'none',
        },
      },
      list: {css: {stack: 'y'}},
    },
  },
);
