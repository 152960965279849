// This file was generated using `pay js:run gql-v2 --type=mutation --name=SendSms --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import type {ApolloError, GraphQlDocument} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  Scalars,
  SuccessMessage,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  mutation SendSmsMutation($locale: String) {
    sendSmsLink(locale: $locale) {
      success
      message
    }
  }
`;

export const sendSmsMutation: SendSmsMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const sendSmsMutationOptions: GelatoMutationOptionsWithoutVariables<SendSmsMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useSendSmsMutation(
  options: GelatoMutationOptions<SendSmsMutationDocument> = {},
): GelatoMutationReturn<SendSmsMutationDocument> {
  return useGelatoMutation(sendSmsMutation, {
    ...sendSmsMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SendSmsMutationVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;

export type SendSmsMutationData = {
  readonly __typename: 'Mutation';
  readonly sendSmsLink: {
    readonly __typename: 'SuccessResult';
    readonly success: boolean;
    readonly message: SuccessMessage | null;
  };
};

const sendSmsMutationPermissions = {} as const;

doc.permissions = sendSmsMutationPermissions;

export type SendSmsMutationPermissions = GelatoPermissionToken<
  typeof sendSmsMutationPermissions
>;

export type SendSmsMutationDocument = GraphQlDocument<
  SendSmsMutationData,
  SendSmsMutationVariables,
  SendSmsMutationPermissions
>;
