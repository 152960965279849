import * as React from 'react';
import Analytics from 'src/public/analytics';
import Metrics from 'src/public/metrics';
import Reports from 'src/public/reports';
import useObservabilityConfig from 'src/public/useObservabilityConfig';

import type {IReports} from 'src/internal/errors/types';
import type {IAnalytics, IMetrics} from 'src/types';

type ObservabilityApi = {
  analytics: IAnalytics;
  metrics: IMetrics;
  reports: IReports;
};

// eslint-disable-next-line @stripe-internal/stripe/no-mixed-react-exports
export const ObservabilityApiContext =
  React.createContext<ObservabilityApi | null>(null);

// eslint-disable-next-line @stripe-internal/stripe/no-mixed-react-exports
export function useObservabilityApi(): ObservabilityApi | null {
  return React.useContext(ObservabilityApiContext);
}

type Props = {
  children: React.ReactNode;
};

export default function ObservabilityApiProvider({
  children,
}: Props): React.ReactElement {
  const config = useObservabilityConfig();

  const value: ObservabilityApi | null = React.useMemo(() => {
    if (!config) {
      return null;
    }

    return {
      analytics: new Analytics(config),
      metrics: new Metrics(config),
      reports: new Reports(config),
    };
  }, [config]);

  return (
    <ObservabilityApiContext.Provider value={value}>
      {children}
    </ObservabilityApiContext.Provider>
  );
}
