import {hash} from './util/hash';

/** ----------------------------------------------------------------------------
 * GENERATED CODE
 * Do not manually edit CURRENT_VERSION.
 * This is automatically generated by the packages-version script.
 */
const CURRENT_VERSION = '41.0.0';
/** ------------------------------------------------------------------------- */

/**
 * VERSION can have one of four values:
 * - 'JEST_VERSION' - when running in a test
 * - 'workspace' - when installed in a workspace context
 * - whatever `CURRENT_VERSION` is - when installed 'normally'
 * - whatever `process.env.SAIL_VERSION` is - this environment variable is used
 *   to allow multiple copies of the same version of Sail to coexist.
 */
export const VERSION = (function () {
  try {
    if (process.env.SAIL_VERSION) {
      return process.env.SAIL_VERSION;
    }
  } catch {}

  if (process.env.NODE_ENV === 'test') {
    return 'JEST_VERSION';
  }
  try {
    // this file only exists in `dist` if it wasn't deleted by `pay:publish`
    require('./workspaced');
    return 'workspace';
  } catch (e) {
    return CURRENT_VERSION;
  }
})();

const MAJOR_VERSION_OFFSET = -36;

export function getVersionHash(version: string): string {
  switch (version) {
    case 'workspace':
      return 's-';
    case 'JEST_VERSION':
      return 's';
    default:
  }

  const semver = version.match(/^(\d+)\.(\d+)\.(\d+)$/);
  if (!semver) {
    return `h${hash(version)}`;
  }

  const major = Number(semver[1]);
  const minor = Number(semver[2]);
  const patch = Number(semver[3]);

  let prefix = (major + MAJOR_VERSION_OFFSET).toString(36);
  if (minor > 0 || patch > 0) {
    prefix += minor.toString(36);
  }
  if (patch > 0) {
    prefix += patch.toString(36);
  }

  return `s${prefix}`;
}

export const VERSION_HASH = getVersionHash(VERSION);
