import {useApolloClient} from '@apollo/client';
import {
  useAnalytics,
  useMetrics,
  useObservabilityConfig,
  usePerformanceMonitorTrack,
  useProject,
  useReports,
} from '@sail/observability';
import {useMemoizedValue} from '@sail/react';
import * as React from 'react';
import PrefetchEnvContext from 'src/internal/prefetching/PrefetchEnvContext';
import PruningPrefetchCacheContext from 'src/internal/prefetching/PruningPrefetchCacheContext';
import useDefaultFetchPolicy from 'src/internal/prefetching/useDefaultFetchPolicy';
import useDataConfig from 'src/public/useDataConfig';

import type {
  GraphQlDocument,
  InternalOperation,
  OptionPermissions,
  PermissionsOfDoc,
  UseQueryOptionsWithoutVariables,
} from 'src/internal/apollo/types';

export type InternalOperationContext<
  TDocumentNode extends GraphQlDocument<any, any, any>,
> = ReturnType<InternalOperation<TDocumentNode>['getContext']>;

export default function useGetApolloContext<
  TDocumentNode extends GraphQlDocument<any, any, any>,
>(
  options: Pick<
    UseQueryOptionsWithoutVariables<TDocumentNode>,
    | 'expectedErrors'
    | 'keepAlive'
    | 'mutedErrors'
    | 'muteAllUnexpected'
    | 'trackAccesses'
    | 'component'
    | 'fetchPolicy'
  > &
    Partial<OptionPermissions<PermissionsOfDoc<TDocumentNode>>>,
): InternalOperationContext<PermissionsOfDoc<TDocumentNode>> {
  const analytics = useAnalytics();
  const dataConfig = useDataConfig();
  const defaultFetchPolicy = useDefaultFetchPolicy();
  const errors = useReports();
  const fetchPolicy = options.fetchPolicy || defaultFetchPolicy;
  const memoizedExpectedErrors = useMemoizedValue(options.expectedErrors);
  const memoizedMutedErrors = useMemoizedValue(options.mutedErrors);
  const metrics = useMetrics();
  const performanceMonitorTrack = usePerformanceMonitorTrack();
  const pruningSignal = React.useContext(PruningPrefetchCacheContext);
  const queryProject = useProject();
  const service = useObservabilityConfig()?.service;
  const token = options.token;

  const {expectedApiErrors, mutedApiErrors} = dataConfig.observability;
  const {isPrefetchMode} = React.useContext(PrefetchEnvContext);

  const client = useApolloClient();

  const computedExpectedErrors = React.useMemo(() => {
    return (memoizedExpectedErrors ?? []).concat(expectedApiErrors);
  }, [memoizedExpectedErrors, expectedApiErrors]);

  const computedMutedErrors = React.useMemo(() => {
    return (memoizedMutedErrors ?? []).concat(mutedApiErrors);
  }, [memoizedMutedErrors, mutedApiErrors]);

  const fetch = dataConfig.fetch;

  return React.useMemo((): InternalOperationContext<
    PermissionsOfDoc<TDocumentNode>
  > => {
    const fetchOptions = {
      keepalive: !!options.keepAlive,
    };

    const observabilityFns = {
      metrics,
      analytics,
      errors,
    };

    const context: InternalOperationContext<PermissionsOfDoc<TDocumentNode>> = {
      client,
      component: options.component,
      expectedErrors: computedExpectedErrors,
      fetch,
      fetchOptions,
      fetchPolicy,
      isPrefetchMode,
      prefetchCachePruningSignal: pruningSignal,
      muteAllUnexpected: options.muteAllUnexpected ?? false,
      mutedErrors: computedMutedErrors,
      observabilityFns,
      queryProject,
      performanceMonitorTrack,
      service,
      token,
    };

    return context;
  }, [
    analytics,
    client,
    computedExpectedErrors,
    computedMutedErrors,
    errors,
    fetch,
    fetchPolicy,
    isPrefetchMode,
    metrics,
    options.component,
    options.keepAlive,
    options.muteAllUnexpected,
    performanceMonitorTrack,
    pruningSignal,
    queryProject,
    service,
    token,
  ]);
}
