import type {Style} from '@sail/engine';

const getDeprecationMessage = (key: string, fallback?: string) => {
  const message = `Sail property "${key}" is deprecated. Please use ${
    fallback ? `"${fallback}"` : 'native CSS properties'
  } instead.`;

  return message;
};

export const deprecate = (
  key: string,
  plugin?: Style.Plugin,
  fallback?: string,
): Style.Plugin => {
  if (process.env.NODE_ENV !== 'production' || !plugin) {
    return () => {
      throw new Error(getDeprecationMessage(key, fallback));
    };
  }

  return plugin;
};
