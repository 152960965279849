import type {Key} from 'react';
import type {ListState} from '../../@react-stately/list';

interface ListData {
  id: string;
  shouldSelectOnPressUp?: boolean;
  shouldFocusOnHover?: boolean;
  shouldUseVirtualFocus?: boolean;
  isVirtualized?: boolean;
  onAction?: (key: Key) => void;
  linkBehavior?: 'action' | 'selection' | 'override';
}

export const listData = new WeakMap<ListState<unknown>, ListData>();

function normalizeKey(key: Key): string {
  if (typeof key === 'string') {
    return key.replace(/\s*/g, '');
  }

  return `${key}`;
}

export function getItemId<T>(state: ListState<T>, itemKey: Key): string {
  const data = listData.get(state);

  if (!data) {
    throw new Error('Unknown list');
  }

  return `${data.id}-option-${normalizeKey(itemKey)}`;
}
