import type {RefObject} from 'react';
import {
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useMemo,
} from 'react';
import {
  FocusScope,
  useControlledState,
  useFocusManager,
  focusSafely,
  getFocusableTreeWalker,
} from '@sail/react-aria';
import type {FocusableElement} from '@react-types/shared';
import type {Placement as FloatingPlacement} from '@floating-ui/react';
import type {Token} from '@sail/engine';
import * as React from 'react';
import {
  useFloating,
  useFloatingNodeId,
  shift,
  size,
  flip,
  offset,
  hide,
  inline,
  autoUpdate,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  useFocus,
  useHover,
  safePolygon,
  limitShift,
  FloatingNode,
} from '@floating-ui/react';
import {
  view,
  useTokenValue,
  assignProps,
  createView,
  dynamic,
  animate,
  AnimatePresence,
} from '@sail/engine';
import type {FocusManager} from '@sail/react-aria';
import type {View} from '../../../view';
import {Layer} from '../../../components/layers/Layer';
import {tokens} from '../../../tokens';
import {toFloatingPlacement} from '../../../components/utils/toFloatingPlacement';
import {useProps} from '../../../hooks/useProps';
import type {PopoverProps} from '../../../components/popover';
import {visuallyHidden} from '../../../utilStyles/visuallyHidden';

/**
 * Strips out deprecated props from the Popover props object and returns
 * the rest.
 */
const replaceDeprecatedProps = <T extends View.RenderProps<PopoverProps>>(
  props: T,
) => {
  const {
    shouldFlip,
    shouldUpdatePosition,
    shouldRespectScrollBoundary,
    shouldCloseOnBlur,
    ...rest
  } = props;

  if (shouldCloseOnBlur !== undefined) {
    rest.dismissible = shouldCloseOnBlur;
  }

  if (shouldFlip !== undefined) {
    rest.flip = shouldFlip;
  }

  return rest;
};

/**
 * Popover option props have either a boolean value, or an object with options.
 * This helper function returns a tuple of the boolean value indicating whether the option is enabled,
 * and the option object itself, defaulting to `{}` if not provided.
 */
const option = <T,>(
  value: boolean | T | undefined,
): [boolean | undefined, T] => {
  if (value === undefined) {
    return [undefined, {} as T];
  }
  if (typeof value === 'boolean') {
    return [value, {} as T];
  }
  return [true, value];
};

/**
 * A portal-friendly version of react-aria's `useInteractOutside` hook, allowing
 * the popover to be dismissed when focus moves outside the popover.
 *
 * `useInteractOutside` uses DOM event delegation to detect clicks outside the popover,
 * but this isn't safe for portalled elements because the event target may not be
 * a direct child of the popover.
 *
 * This hook uses the React tree (instead of the DOM tree) to detect when focus moves
 * outside the popover.
 */
const useFocusOutside = (
  callback: () => void,
): Pick<React.HTMLProps<Element>, 'onFocus' | 'onBlur'> => {
  const focusMovedRef = useRef(false);
  const timeoutRef = React.useRef<number>();
  useEffect(() => () => cancelAnimationFrame(timeoutRef.current as number), []);

  return useMemo(
    () => ({
      onFocus() {
        focusMovedRef.current = true;
      },
      // blur on the current element fires before focus on the next element,
      // so we use that to determine if the focus is moving outside the popover
      onBlur(e) {
        focusMovedRef.current = false;

        // Ignore blur events default prevented by children
        if (e.defaultPrevented) {
          return;
        }

        // Always preventDefault onBlur event, so parent Popovers don't close
        e.preventDefault();
        cancelAnimationFrame(timeoutRef.current as number);

        const isFocusWithin = e.currentTarget?.contains(
          e.relatedTarget as Element,
        );

        // Give browser time to focus the next element
        timeoutRef.current = requestAnimationFrame(() => {
          // Check if the new focused element is a child of the original container
          if (!focusMovedRef.current && !isFocusWithin) {
            callback();
          }
        });
      },
    }),
    [callback],
  );
};

const useDurationValue = <T,>(token: Token.Value<T>) => {
  return parseInt(useTokenValue(token) as string, 10);
};

const translate = (placement: FloatingPlacement) => {
  /* eslint-disable no-nested-ternary */
  const [attachedSide] = placement.split('-');
  const offset = `${tokens.space[150].toString()}`;
  const negativeOffset = `calc(-1 * ${offset})`;
  return `translate(${
    attachedSide === 'left'
      ? offset
      : attachedSide === 'right'
      ? negativeOffset
      : '0'
  }, ${
    attachedSide === 'top'
      ? offset
      : attachedSide === 'bottom'
      ? negativeOffset
      : '0'
  })`;
};

const FocusGuard = createView((props: View.IntrinsicElement<'span'>) => {
  return <view.span hidden tabIndex={0} uses={[visuallyHidden]} {...props} />;
});

const moveFocus =
  <T extends HTMLElement>(ref: RefObject<FocusManager>, dir: 'next' | 'prev') =>
  (event: React.FocusEvent<T>) => {
    const manager = ref.current;
    // first try moving focus within the popover
    const node = dir === 'next' ? manager?.focusFirst() : manager?.focusLast();

    // if the manager didn't find a focusable element within scope,
    // focus the next/previous element outside the popover.
    if (!node) {
      const root = event.currentTarget.getRootNode() as Element;
      const walker = getFocusableTreeWalker(root, {
        tabbable: true,
        from: event.currentTarget,
      });

      const el = dir === 'next' ? walker.nextNode() : walker.previousNode();

      if (el) {
        focusSafely(el as FocusableElement);
      }
    }
  };

export const PopoverLayer = createView(
  (allProps: View.RenderProps<PopoverProps>) => {
    const {
      targetRef,
      placement: reactAriaPlacement,
      offset: offsetOptions,
      flip: flipEnabled,
      shift: shouldShift,
      size: sizeOptions,
      inline: inlineEnabled,
      hide: hideOptions,
      onClose,
      subviews,
      dismissible,
      showOnPress,
      showOnFocus,
      showOnHover,
      role,
      defaultOpen,
      open: controlledOpen,
      onOpenChange: setControlledOpen,
      ...props
    } = replaceDeprecatedProps(allProps);

    const [open, setOpen] = useControlledState(
      // Here we default the controlled state to `true` if neither `open` nor `defaultOpen` are provided.
      // This provides backwards compatibility by continuing to call `onClose` events but not automatically
      // hiding the popover. This feels a little odd as you might reasonably expect the popover to be
      // uncontrolled by default, but it's the best we can do without breaking backwards compatibility. The
      // drawback is that you must explicitly provide the `defaultOpen` prop if you want the popover to be
      // uncontrolled.
      controlledOpen ?? (defaultOpen === undefined ? true : undefined),
      defaultOpen,
      setControlledOpen,
    );

    const padding = parseInt(useTokenValue(tokens.space.xsmall), 10);
    const placement = toFloatingPlacement(reactAriaPlacement);

    const [shiftEnabled, shiftOptions] = option(shouldShift);
    const [dismissEnabled, dismissOptions] = option(dismissible);
    const [clickEnabled, clickOptions] = option(showOnPress);
    const [focusEnabled, focusOptions] = option(showOnFocus);
    const [hoverEnabled, hoverOptions] = option(showOnHover);

    const onOpenChange = useCallback(
      (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
          // backwards compatibility for onClose
          onClose?.();
        }
      },
      [setOpen, onClose],
    );

    const nodeId = useFloatingNodeId();

    const floating = useFloating<HTMLDivElement>({
      nodeId,
      placement,
      whileElementsMounted: autoUpdate,
      middleware: [
        inlineEnabled && inline(),
        offset(offsetOptions ?? padding),
        hideOptions?.referenceHidden && hide(),
        hide({strategy: 'escaped'}),
        flip(({middlewareData}) => ({
          mainAxis: flipEnabled,
          crossAxis: flipEnabled,
          altBoundary: middlewareData?.hide?.escaped,
          /**
           * The `bestFit` strategy doesn't play nicely when comparing
           * overflows that are very close to each other (e.g. less than 1px difference).
           * When paired with the `altBoundary` option, this can cause the popover to
           * flip into a position that is clipped by the viewport.
           */
          fallbackStrategy: middlewareData?.hide?.escaped
            ? 'initialPlacement'
            : 'bestFit',
        })),
        size({
          padding: sizeOptions?.padding || padding,
          altBoundary: sizeOptions?.altBoundary,
          apply({availableHeight, availableWidth, elements, rects}) {
            // intentionally using style here to avoid generating a new class
            // for each pixel value of width|max{width|height}.
            const style = elements.floating?.style || {};
            if (sizeOptions?.maxWidth) {
              Object.assign(style, {maxWidth: `${availableWidth}px`});
            }
            if (sizeOptions?.maxHeight) {
              Object.assign(style, {maxHeight: `${availableHeight}px`});
            }
            if (sizeOptions?.matchWidth) {
              Object.assign(style, {width: `${rects.reference.width}px`});
            }
          },
        }),
        shift({
          mainAxis: shiftEnabled !== false,
          padding: shiftOptions.padding || padding,
          altBoundary: shiftOptions.altBoundary,
          limiter: limitShift(),
        }),
      ],
      open,
      onOpenChange,
    });

    const {refs, floatingStyles, context, middlewareData} = floating;

    const scope = useRef<FocusManager>(null);

    const dismissOnFocusOutside = useFocusOutside(
      useCallback(() => {
        if (dismissEnabled && dismissOptions.outsideFocus !== false) {
          onOpenChange(false);
        }
      }, [dismissEnabled, dismissOptions.outsideFocus, onOpenChange]),
    );

    const interactions = useInteractions([
      useRole(context, {
        enabled: Boolean(role),
        role,
      }),
      useDismiss(context, {
        enabled: dismissEnabled,
        escapeKey: dismissOptions.escapeKey,
        outsidePress: dismissOptions.outsidePress,
        // outsidePressEvent needs to be set to 'click' to ensure compatibility with
        // react-aria's Pressable implementation.
        outsidePressEvent: 'click',
        bubbles: dismissOptions.bubbles,
      }),
      useClick(context, {
        enabled: clickEnabled,
        toggle: clickOptions.toggle,
      }),
      useFocus(context, {
        enabled: focusEnabled,
        keyboardOnly: focusOptions.visibleOnly,
      }),
      useHover(context, {
        enabled: hoverEnabled,
        delay: hoverOptions.delay,
        restMs: hoverOptions.restMs,
        handleClose: safePolygon(),
      }),
      // wire up the reference element to dismiss the popover when it loses focus.
      // This logic is only necessary because the `useDismiss` hook relies on
      // event delegation and react-aria "pressable" elements don't propagate mouse/touch events.
      {reference: dismissOnFocusOutside},
    ]);

    useProps(targetRef, interactions.getReferenceProps());

    // set up refs for the trigger element for floating-ui
    useEffect(() => refs.setReference(targetRef.current), [targetRef, refs]);

    const easing = useTokenValue(tokens.animation.easing) as string;
    const medium = useDurationValue(tokens.animation.duration.medium);
    const short = useDurationValue(tokens.animation.duration.short);

    const layer = (
      <Layer
        inherits={subviews.layer}
        ref={(el: HTMLDivElement) => {
          // don't run positioning logic within JSDOM environment (speeds up tests)
          // JSDOM doesn't support `getBoundingClientRect` anyway, so positioning logic
          // wouldn't be correct there anyway.
          if (typeof jest === 'undefined') {
            refs.setFloating(el);
          }
        }}
        style={floatingStyles}
        uses={[
          assignProps(
            interactions.getFloatingProps() as React.HTMLAttributes<HTMLDivElement>,
          ),
        ]}
        css={{
          visibility:
            hideOptions?.referenceHidden && middlewareData.hide?.referenceHidden
              ? 'hidden'
              : undefined,
        }}
        {...dismissOnFocusOutside}
      >
        <FloatingNode id={nodeId}>
          <FocusScope restoreFocus>
            <view.div
              {...props}
              css={sizeOptions?.maxHeight ? {overflow: 'auto'} : undefined}
              uses={[
                dynamic(() => {
                  const manager = useFocusManager();
                  useImperativeHandle(scope, () => manager);
                }),
                animate.in({
                  opacity: 0,
                  transform: `scale(0.95) ${translate(floating.placement)}`,
                  easing,
                  duration: medium,
                }),
                animate.out({
                  opacity: 0,
                  transform: `scale(0.95) ${translate(floating.placement)}`,
                  easing,
                  duration: short,
                }),
              ]}
            />
          </FocusScope>
        </FloatingNode>
      </Layer>
    );

    // focus guards to allow focus to move between the page and the popover
    return (
      <>
        {open ? <FocusGuard onFocus={moveFocus(scope, 'next')} /> : null}
        <AnimatePresence>{context.open ? layer : null}</AnimatePresence>
        {open ? <FocusGuard onFocus={moveFocus(scope, 'prev')} /> : null}
      </>
    );
  },
  {
    css: {
      backgroundColor: 'surface',
      border: '1px solid',
      borderColor: 'border',
      borderRadius: 'medium',
      boxShadow: 'overlay',
      maxHeight: 'inherit',
    },
  },
);
