import useBrowserStorage from 'src/internal/browserStorage/useBrowserStorage';
import localStorage from 'src/internal/localStorage/localStorage';
import useDataConfig from 'src/public/useDataConfig';

import type {BrowserStorageHook} from 'src/internal/browserStorage/useBrowserStorage';

type LocalStorageHook = BrowserStorageHook;

/**
 * Declarative version of the `localStorage` API.
 *
 * It accepts an item name and it returns a pair of values: the current item
 * value and a function to update it. The setter function returns a promise
 * which resolves to true if the item was set successfully, or false if it was
 * blocked due to the user’s permissions.
 *
 * @example Basic {{include "./examples/useLocalStorage.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/data/useLocalStorage
 */
export default function useLocalStorage(
  /* Key of the localStorage to interact with */
  key: string,
): LocalStorageHook {
  const dataConfig = useDataConfig();

  return useBrowserStorage(dataConfig.browserStorage, localStorage, key);
}
