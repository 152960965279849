import * as React from 'react';

import {
  createView,
  createViewConfig,
  view,
  useIsomorphicLayoutEffect,
} from '@sail/engine';
import type {IconAsset, SvgType} from '@sail/icons/types';
import type {View} from '../view';
import {Input} from './Input';
import type {FormFieldProps} from './FormField';
import {useFormFieldProps, FormField} from './FormField';
import {css, baseline} from '../css';
import {Icon} from './Icon';

export type NativeCheckboxProps = View.IntrinsicElement<
  'input',
  {
    /**
     * Sets whether or not the element is in an invalid state. This is a display-only prop, and will not prevent form submission.
     * @external
     */
    invalid?: boolean;
    /**
     * Sets whether the Checkbox should be rendered as indeterminate ("partially checked") or not. Note that this is purely visual, and will not change the actual `checked` state of the Checkbox. If a Checkbox is both `indeterminate` and `checked`, it will display as `indeterminate`.
     * @external
     */
    indeterminate?: boolean;

    subviews?: View.Subviews<{
      checked: typeof Icon;
      indeterminate: typeof Icon;
    }>;
  }
>;

// NOTE: this is a custom icon instead of `check` from @sail/icons/react/Icon.
// `check` doesn't scale down well to the small size
const CheckAsset = createView((props: View.IntrinsicElement<'svg'>) => {
  return (
    <view.svg
      {...props}
      css={{
        pointerEvents: 'none',
      }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.346 3.301a.929.929 0 0 1 1.37 0 1.076 1.076 0 0 1 0 1.456l-4.64 4.94a.929.929 0 0 1-1.37 0L3.284 7.123a1.076 1.076 0 0 1 0-1.456.929.929 0 0 1 1.37 0L6.39 7.513l3.955-4.212z" />
    </view.svg>
  );
});

const checkIcon: IconAsset = {
  category: 'icon',
  colorable: true,
  name: 'check',
  svg: CheckAsset as SvgType,
};

const IndeterminateAsset = createView((props: View.IntrinsicElement<'svg'>) => {
  return (
    <view.svg
      {...props}
      css={{
        pointerEvents: 'none',
      }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 7C2 6.44772 2.44772 6 3 6H11C11.5523 6 12 6.44772 12 7C12 7.55228 11.5523 8 11 8H3C2.44772 8 2 7.55228 2 7Z" />
    </view.svg>
  );
});

const indeterminateIcon: IconAsset = {
  category: 'icon',
  colorable: true,
  name: 'indeterminate',
  svg: IndeterminateAsset as SvgType,
};

export const NativeCheckboxConfig = createViewConfig({
  props: {} as NativeCheckboxProps,
  name: 'NativeCheckbox',
  defaults: {
    indeterminate: false,
  },
});

const iconStyles = {
  height: 'space.175',
  width: 'space.175',
  pointerEvents: 'none',
} as const;

export const NativeCheckbox = NativeCheckboxConfig.createView(
  ({indeterminate, disabled, subviews, ...props}) => {
    const ref = React.useRef<HTMLInputElement>(null);

    // indeterminate is not supported as an attribute, so it needs to be set with JS
    useIsomorphicLayoutEffect(() => {
      const el = ref.current;
      if (el) {
        el.indeterminate = indeterminate || false;
      }
    }, [indeterminate]);

    // NOTE: we leave both icons in the DOM and toggle visibility instead of
    // adding and removing, or replacing one with the other, because of rendering
    // bugs that we found in chromium browsers where the replaced icon would have
    // visibility: visible, but would not be visible.
    // https://jira.corp.stripe.com/browse/SAIL-3339
    return (
      <view.div
        css={{
          display: 'inline',
          stack: 'z',
          alignX: 'center',
          alignY: 'center',
        }}
        uses={[baseline.css('cap-middle object-middle')]}
      >
        <Input
          type="checkbox"
          disabled={disabled}
          ref={ref}
          {...props}
          {...(indeterminate ? {'aria-checked': 'mixed'} : {})}
        />
        <Icon
          css={{
            ...iconStyles,
            visibility: 'hidden',
            ':checked ~ &': {
              // If a Checkbox is both `indeterminate` and `checked`, it will display as `indeterminate`.
              visibility: indeterminate ? 'hidden' : 'visible',
              fill: disabled ? 'onForm.accent.disabled' : 'onForm.accent',
            },
          }}
          // TODO: fix subrefs to support svgs so we don't need to cast to a lie
          icon={checkIcon}
          data-testid="checked-svg"
          inherits={subviews.checked}
        />
        <Icon
          css={{
            ...iconStyles,
            visibility: indeterminate ? 'visible' : 'hidden',
            fill: disabled ? 'onForm.accent.disabled' : 'onForm.accent',
          }}
          icon={indeterminateIcon}
          data-testid="indeterminate-svg"
          inherits={subviews.indeterminate}
        />
      </view.div>
    );
  },
  {
    css: {
      appearance: 'none',
      height: 'space.175',
      width: 'space.175',
      borderRadius: 'xsmall',
      backgroundColor: 'form',
      borderWidth: 0,
      margin: 'space.0',
      cursor: 'pointer',
      transition: 'background-color 0.24s, box-shadow 0.24s',
      keyline: 'border',

      ':not([disabled])': {
        boxShadow: 'small',
      },

      ':focus': {
        focusRing: 'focus',
      },

      ':disabled': {
        cursor: 'default',
        backgroundColor: 'form.disabled',
      },

      ':disabled:checked': {
        backgroundColor: 'form.accent.disabled',
        keyline: 'form.accent.disabled',
      },

      ':checked': {
        backgroundColor: 'form.accent',
        keyline: 'form.accent',
      },

      '[aria-invalid="true"]': {
        keyline: 'form.error',
      },

      '[aria-invalid="true"]:checked': {
        keyline: 'form.error',
      },
    },
    forward: {indeterminate: true},
    variants: {
      indeterminate: {
        true: css({
          backgroundColor: 'form.accent',
          keyline: 'form.accent',
          ':disabled': {
            backgroundColor: 'form.accent.disabled',
            keyline: 'form.accent.disabled',
          },
        }),
      },
    },
  },
);

export const CheckboxConfig = createViewConfig({
  props: {} as FormFieldProps<NativeCheckboxProps>,
  name: 'Checkbox',
  flattens: true,
});

/**
 * @external
 * @param autoFocus
 * @param defaultChecked
 * @param disabled
 * @param form
 * @param invalid
 * @param name
 * @param onChange
 * @param readOnly
 * @param required
 * @param tabIndex
 * @param value
 * @param checked
 */
export const Checkbox = CheckboxConfig.createView((props) => {
  const [fieldProps, controlProps] = useFormFieldProps(props);
  return (
    <FormField {...fieldProps} layout="horizontal">
      <NativeCheckbox
        {...controlProps}
        inherits={props.subviews.input}
        subviews={{
          checked: props.subviews.checked,
          indeterminate: props.subviews.indeterminate,
        }}
      />
    </FormField>
  );
});
