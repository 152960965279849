import {useCallback, useEffect, useRef, useState} from 'react';
import {useSSRSafeId} from '../ssr';
import {useLayoutEffect} from './useLayoutEffect';
import {useValueEffect} from './useValueEffect';

const idsUpdaterMap: Map<string, (v: string) => void> = new Map();

/**
 * If a default is not provided, generate an id.
 * @param defaultId - Default component id.
 */
export function useId(defaultId?: string): string {
  const [value, setValue] = useState(defaultId);
  const nextId = useRef(null);

  const res = useSSRSafeId(value);

  const updateValue = useCallback((val) => {
    nextId.current = val;
  }, []);

  idsUpdaterMap.set(res, updateValue);

  useLayoutEffect(() => {
    const r = res;
    return () => {
      idsUpdaterMap.delete(r);
    };
  }, [res]);

  // This cannot cause an infinite loop because the ref is updated first.
  // eslint-disable-next-line
  useEffect(() => {
    const newId = nextId.current;
    if (newId) {
      nextId.current = null;
      setValue(newId);
    }
  });

  return res;
}

/**
 * Merges two ids.
 * Different ids will trigger a side-effect and re-render components hooked up with `useId`.
 */
export function mergeIds(idA: string, idB: string): string {
  if (idA === idB) {
    return idA;
  }

  const setIdA = idsUpdaterMap.get(idA);
  if (setIdA) {
    setIdA(idB);
    return idB;
  }

  const setIdB = idsUpdaterMap.get(idB);
  if (setIdB) {
    setIdB(idA);
    return idA;
  }

  return idB;
}

/**
 * Used to generate an id, and after render, check if that id is rendered so we know
 * if we can use it in places such as labelledby.
 * @param depArray - When to recalculate if the id is in the DOM.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSlotId(depArray: ReadonlyArray<any> = []): string {
  const id = useId();
  const [resolvedId, setResolvedId] = useValueEffect(id);
  const updateId = useCallback(() => {
    setResolvedId(function* () {
      yield id;

      yield document.getElementById(id) ? id : undefined;
    });
  }, [id, setResolvedId]);

  useLayoutEffect(updateId, [id, updateId, ...depArray]);

  return resolvedId;
}
