import {isBrowser} from '../util/isBrowser';
import {isLinkUrl} from './isLinkUrl';

const getBaseUrl = (href: string = isBrowser ? window.location.href : '') => {
  if (isLinkUrl(href)) {
    if (href.match(/(\.)?link.co([^m]|\b)/i)) {
      return 'https://link.co';
    }

    return 'https://link.com';
  }

  return 'https://stripe.com';
};

export const resolveUrl = (
  path: string,
  href: string = isBrowser ? window.location.href : '',
) => {
  const endpoint = getBaseUrl(href) + path;

  // URL and URLSearchParams are not supported in older browsers.
  // This check is to protect against referencing unsupported APIs in prod.
  // If they are needed in contexts where they are not supported, we should
  // consider using a polyfill.
  if (!isBrowser || !window.URL || !window.URLSearchParams) {
    return endpoint;
  }
  const url = new URL(href);
  const endpointUrl = new URL(endpoint);

  // If we're running on a devbox or using the stripe js tunnel,
  // we want to call out to the user's instance of mkt-srv instead of to prod.
  if (
    url.hostname.match('-mydev.dev.stripe.me') ||
    url.hostname.match('-stripejs.tunnel.stripe.me')
  ) {
    const [host] = url.hostname.split('.', 1);
    let username;
    // If the devbox is remote then it has the pattern #{user}-#{qualifier}--#{service}
    if (host.split('--', 1)[0] !== host) {
      [username] = host.split('--', 1);
      username += '-';
    } else {
      [username] = host.split('-', 1);
    }
    endpointUrl.hostname = `${username}-mkt-mydev.dev.stripe.me`;

    // manage-dashboard-proxy should call prod mkt-srv cookie endpoints
    if (host.includes('manage-dashboard-proxy-mydev')) {
      endpointUrl.hostname = `stripe.com`;
    } else if (host.includes('manage-qa-dashboard-proxy-mydev')) {
      endpointUrl.hostname = `qa.stripe.com`;
    }

    // link.com and link.co is triggered on mkt-srv via the `$host` query parameter.
    // Ensure we set it.
    if (endpoint.includes('link.co')) {
      endpointUrl.search = new URLSearchParams({$host: 'link'}).toString();
    }
    return endpointUrl.toString();
  } else if (
    url.hostname.match(/(^|-)(dev|iso|corpiso|corpdev)\.dev\.stripe\.me$/)
  ) {
    // With fullstack PRR, carry the same base domain
    return endpoint.replace('stripe.com', url.hostname);
  } else if (
    url.hostname.match(/(^|\.)qa\.stripe\.com$/) ||
    url.hostname.match(/(^|\.)(link|admin)\.qa\.corp\.stripe\.com$/) ||
    url.hostname.match(/(^|\.)qa-dashboard\.stripe\.com$/)
  ) {
    // and similarly in the QA env, we want to use endpoints on the QA server
    return endpoint.replace('stripe.com', 'qa.stripe.com');
  } else if (
    url.hostname.match(/(^|\.)preprod\.stripe\.com$/) ||
    url.hostname.match(/(^|\.)(link|admin)\.preprod\.corp\.stripe\.com$/) ||
    url.hostname.match(/(^|\.)preprod-dashboard\.stripe\.com$/)
  ) {
    return endpoint.replace('stripe.com', 'preprod.stripe.com');
  } else if (
    url.hostname.match(/(^|\.)qa\.link\.co$/) ||
    url.hostname.match(/(^|\.)qa-app\.stripe\.co$/)
  ) {
    return endpoint.replace('link.co', 'qa.link.co');
  } else if (
    url.hostname.match(/(^|\.)qa\.link\.com$/) ||
    url.hostname.match(/(^|\.)qa-app\.link\.com$/)
  ) {
    return endpoint.replace('link.com', 'qa.link.com');
  } else if (
    url.hostname.match(/(^|\.)preprod\.link\.co$/) ||
    url.hostname.match(/(^|\.)preprod-app\.stripe\.co$/)
  ) {
    return endpoint.replace('link.co', 'preprod.link.co');
  } else if (
    url.hostname.match(/(^|\.)preprod\.link\.com$/) ||
    url.hostname.match(/(^|\.)preprod-app\.link\.com$/)
  ) {
    return endpoint.replace('link.com', 'preprod.link.com');
  } else if (
    url.hostname.match(/mkt(\.qa)?\.corp\.stripe\.com/i) ||
    url.hostname.match('-mkt.tunnel.stripe.me')
  ) {
    endpointUrl.hostname = url.hostname;

    // Link.co is triggered on mkt-srv via the `$host` query parameter.
    // Ensure we set it.
    if (endpoint.includes('link.co')) {
      endpointUrl.search = new URLSearchParams({$host: 'link'}).toString();
    }
    return endpointUrl.toString();
  }

  return endpointUrl.toString();
};
