import * as React from 'react';
import type {ReactNode, RefObject} from 'react';
import type {Merge} from '@sail/engine';
import {useId} from '@sail/react-aria';
import {warningCircle} from '@sail/icons/react/Icon';
import {
  createView,
  createViewConfig,
  extractRefFromProps,
  view,
} from '@sail/engine';
import type {View} from '../view';
import {Icon} from './Icon';
import {useInputId, LABEL_OPT_OUT_STRING} from './hooks/useInputId';
import {Label} from './Label';
import {Skeleton} from './Skeleton';
import {visuallyHidden} from '../utilStyles/visuallyHidden';
import {css} from '../css';

export const FormFieldMetadataContext = React.createContext<{
  elements: FormFieldMetadata;
  controlId: string;
}>({
  controlId: '',
  elements: {
    description: {},
    label: {},
    error: {},
  },
});

export type ControlProps = {
  /**
   * Visually hides the specified elements. The hidden elements will still be present and visible to screen readers.
   */
  hiddenElements?: FormFieldElements[];
  /**
   * Descriptive text that will be rendered adjacent to the control's label.
   */
  description?: ReactNode;
  /**
   * Marks the field as disabled. Affects the visual appearance of the label.
   */
  disabled?: boolean;
  /**
   * Error text that will be rendered below the control.
   */
  error?: ReactNode;
  /**
   * Text that describes the control. Will be both visible and clickable.
   */
  label?: ReactNode;
};

// This type is shaped carefully to make error messages sensible. This
// is how you make it required to specify at least one of these two
// props. If we specified `label` in the first type, it trips TS up
// when there is unrelated error on a different prop and it matches
// the wrong part of this union and will complain with something like
// "label is required" even though the user gave `aria-label`. It's
// also important that the one with `label` is second, as that forces
// TS to complain about `label` (and not aria-) missing when neither
// are present
export type OneOfLabelOrAriaLabel =
  | {
      'aria-label': string;
    }
  | {
      /**
       * Text that describes the control. Will be both visible and clickable.
       */
      label: ReactNode;
      /**
       * Text that describes the control. Only visible to screen readers, and is not clickable. Should not be used if `label` is set.
       */
      'aria-label'?: string;
    };

export type Layouts = 'horizontal' | 'vertical';

export type FieldMetadata<T extends string> = Record<
  T,
  {id?: string; present?: boolean}
>;

export type SubsetOfKeys<T, U extends keyof T> = U;

export type FormFieldElements = SubsetOfKeys<
  ControlProps,
  'error' | 'description' | 'label'
>;

export type FormFieldMetadata = FieldMetadata<FormFieldElements>;

export const ErrorText = createView(
  ({children, ...props}: View.IntrinsicElement<'div'>) => {
    return (
      <view.div {...props}>
        <Icon
          icon={warningCircle}
          size="xsmall"
          css={{bottom: 'space.1', position: 'relative'}}
        />
        <view.div
          css={{
            width: 'fill',
            alignSelfX: 'start',
            boxSizing: 'border-box',
          }}
        >
          {children}
        </view.div>
      </view.div>
    );
  },
  {
    css: {
      stack: 'x',
      gap: 'xsmall',
      color: 'feedback.critical',
      font: 'label.small',
    },
    variants: {
      hidden: {
        true: visuallyHidden,
      },
    },
    defaults: {hidden: false},
  },
);

export const Description = createView('div', {
  css: {
    color: 'subdued',
    font: 'label.small',
  },
  variants: {
    hidden: {
      true: visuallyHidden,
    },
  },
  defaults: {hidden: false},
});

export type FormFieldSubviews = {
  /**
   * Subview containing every input element.
   */
  inputs: 'div';
  /**
   * Subview that describes the control.
   */
  label: typeof Label;
  /**
   * Subview providing supplementary descriptive text.
   */
  description: typeof Description;
  /**
   * Subview displayed when the input is invalid.
   */
  error: 'div';
};

// this is what users should be customizing
export const FormFieldConfig = createViewConfig({
  props: {} as View.IntrinsicElement<
    'div',
    ControlProps & {
      layout: Layouts;
      id?: string;
      /**
       * The contents of the form field, typically an element to accept user input.
       */
      children?: React.ReactNode;
      isInCompositeField: boolean;
      subviews?: View.Subviews<FormFieldSubviews>;
    }
  >,
  name: 'FormField',
  defaults: {
    isInCompositeField: false,
    layout: 'vertical',
  },
});

export type FormFieldProps<T, Input = 'input'> = Merge<
  T,
  ControlProps &
    OneOfLabelOrAriaLabel & {
      children?: React.ReactNode;
      id?: string | undefined;
      subviews?: View.Subviews<FormFieldSubviews> &
        (T extends {
          subviews?: View.Subviews<infer S>;
        }
          ? View.Subviews<Merge<S, {input: Input}>>
          : View.Subviews<{input: Input}>);
    } & {
      ref?: View.InferRef<T, React.Ref<HTMLElement>>;
      outerRef?: RefObject<HTMLDivElement>;
    }
>;

/**
 * A low-level component for building custom form inputs.
 */
export const FormField = FormFieldConfig.createView(
  ({
    label,
    description,
    error,
    hiddenElements = [],
    id: propsId,
    children,
    isInCompositeField,
    subviews,
    ...props
  }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const {id} = useInputId({
      label,
      outerRef: ref,
      id: propsId,
    });
    const descriptionId = useId();
    const errorId = useId();
    const labelId = useId();
    const shouldRenderLabel = Boolean(label && label !== LABEL_OPT_OUT_STRING);

    const shouldHideLabel =
      isInCompositeField || hiddenElements.includes('label');
    const shouldHideDescription =
      isInCompositeField || hiddenElements.includes('description');
    const shouldHideError =
      isInCompositeField || hiddenElements.includes('error');

    return (
      <FormFieldMetadataContext.Provider
        value={{
          elements: {
            description: {
              id: descriptionId,
              present: Boolean(description),
            },
            label: {
              id: labelId,
              present: shouldRenderLabel,
            },
            error: {
              id: errorId,
              present: Boolean(error),
            },
          },
          controlId: id,
        }}
      >
        <view.div {...props} ref={ref}>
          {shouldRenderLabel ? (
            <Label
              hidden={shouldHideLabel}
              htmlFor={id}
              id={labelId}
              inherits={subviews.label}
            >
              {label}
            </Label>
          ) : null}
          {description ? (
            <Description
              hidden={shouldHideDescription}
              id={descriptionId}
              inherits={subviews.description}
            >
              {description}
            </Description>
          ) : null}
          <view.div inherits={subviews.inputs}>{children}</view.div>
          {error ? (
            <ErrorText
              hidden={shouldHideError}
              id={errorId}
              inherits={subviews.error}
            >
              {error}
            </ErrorText>
          ) : null}
        </view.div>
      </FormFieldMetadataContext.Provider>
    );
  },
  {
    css: {
      gapX: 'small',
      gapY: 'xsmall',
      font: 'label.medium',
    },
    subviews: {
      label: {
        css: {width: 'fit'},
      },
      description: {
        css: {bleedTop: 'xsmall'},
      },
    },
    variants: {
      disabled: {
        true: {
          subviews: {
            label: {
              css: {
                color: 'form.disabled',
              },
            },
            description: {
              css: {
                color: 'form.disabled',
              },
            },
          },
        },
      },
      layout: {
        horizontal: {
          subviews: {
            inputs: {
              css: {
                baselineAlign: 'auto',
              },
              uses: [css({gridArea: '1 / 1'})],
            },
            label: {
              uses: [css({gridColumn: '2'})],
            },
            description: {
              uses: [css({gridColumn: '2'})],
            },
            error: {
              uses: [css({gridColumn: '2'})],
            },
          },
          uses: [
            css({
              gridTemplateColumns: 'max-content 1fr',
            }),
          ],
        },
        vertical: css({
          gridTemplateColumns: '1fr',
        }),
      },
    },
    uses: css({
      display: 'grid',
      alignItems: 'baseline',
    }),
  },
);

/**
 * A hook that separates props for the form field from props for the control.
 *
 * Splits props like `label`, `description` and `error` from the control props.
 */
export function useFormFieldProps<
  T,
  Input,
  Props extends View.RenderProps<FormFieldProps<T, Input>>,
>(props: Props) {
  const [ref] = extractRefFromProps(props);
  const {
    description,
    disabled,
    error,
    hiddenElements,
    inherits,
    label,
    outerRef,
    subviews,
    ...rest
  } = props;

  const fieldProps = {
    description,
    disabled,
    error,
    hiddenElements,
    // TODO(koop): Should we still forward the id prop to the control?
    // The useFieldContexts hook will retrieve the controlId from the context
    // set by the wrapper FormField component. The current logic is safe and
    // doesn't result in duplicate ids in the DOM, but passing the prop may
    // be redundant.
    id: props.id,
    inherits,
    label,
    ref: outerRef,
    subviews,
  };

  const controlProps = {ref, disabled, ...rest};

  return [fieldProps, controlProps] as const;
}

export const FormFieldSkeleton = () => (
  <Skeleton
    css={{
      height: 'space.250',
      paddingX: 'small',
      paddingY: 'xsmall',
      borderRadius: 'small',
      boxSizing: 'content-box',
    }}
  />
);
