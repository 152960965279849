import {useMutation as useApolloMutation} from '@apollo/client';
import {useMemoizedValue} from '@sail/react';
import {useCallback, useMemo} from 'react';
import useGetApolloContext from 'src/internal/apollo/useGetApolloContext';
import {
  DEFAULT_IGNORE_TRACKING_FIELDS,
  DEFAULT_TRACK_ACCESSES_SAMPLE_RATE,
  useTrackGraphQlFieldAccesses,
} from 'src/internal/deadwood/deadwood';

import type {
  ApolloError,
  GraphQlDocument,
  MutationPromiseReturn,
  OperationContext,
  PermissionToken,
  UnverifiedToken,
  UseMutationOptions,
  UseMutationReturn,
  VariablesOfDoc,
} from 'src/internal/apollo/types';

const onErrorFallback = (): void => {
  // Empty function on purpose - otherwise mutations throw (which is not a
  // consistent behavior with `useLazyQuery`).
};

/**
 * React hook to execute GraphQL mutations using `@sail/data`.
 *
 * This hook is equivalent to the
 * [`useMutation()` Apollo hook](https://www.apollographql.com/docs/react/data/mutations)
 * but it has a few differences, all of them described
 * [here](/apis/data/graphql-usage/the-basics-runtime/#option-differences).
 *
 * For more detailed documentation about how to use this method, please head to the
 * ["Using GraphQL / Defining operations"](/apis/data/graphql-usage/defining-operations) page.
 *
 * @example Basic {{include './examples/useMutation.basic.tsx'}}
 *
 * @see https://sail.stripe.me/apis/data/useMutation
 */
export default function useMutation<
  /** The data returned by the GraphQL mutation (inferred from the passed GraphQL document). */
  TData,
  /** The variables that the GraphQL mutation accept (inferred from the passed GraphQL document). */
  TVariables,
  /** The permissions needed by the GraphQL mutation (inferred from the passed GraphQL document). */
  TPermissions,
  /** (internal) The token passed to the mutation via the hook (inferred from the passed options). */
  THookToken extends TPermissions | undefined = undefined,
  /** (internal) The variables passed to the mutation via the hook (inferred from the passed options). */
  THookVariables extends Partial<TVariables> | undefined = undefined,
  BaseTokenType extends PermissionToken = PermissionToken,
  UnverifiedTokenType extends UnverifiedToken = UnverifiedToken,
>(
  /**
   * The GraphQL mutation that is going to be executed. This must be created with the
   * [`gql`](/apis/data/gql) or
   * [`graphql`](/apis/data/graphql) template literal.
   */
  mutation: GraphQlDocument<TData, TVariables, TPermissions>,
  /**
   * The default options to pass to the mutation. This argument is optional.
   */
  options: UseMutationOptions<
    GraphQlDocument<TData, TVariables, TPermissions>,
    THookToken,
    THookVariables
  > = {},
): /**
 * Array with two elements:
 *
 * **callback**: Function to execute the actual mutation. Accepts an options object that will override any options passed to the hook (including the variables and the permissions token).
 *
 * **result**: Object with `data`, `loading` and `error` (similar to the one that the
 * [`useQuery` Apollo hook](https://www.apollographql.com/docs/react/api/react/hooks/#result) returns).
 */
UseMutationReturn<
  GraphQlDocument<TData, TVariables, TPermissions>,
  THookToken,
  THookVariables,
  BaseTokenType,
  UnverifiedTokenType
> {
  const baseContext = useGetApolloContext(options);

  const mutationOptions = useMemo(
    () => ({
      ...options,
      onError: options.onError ?? onErrorFallback,
      context: baseContext,
    }),
    [baseContext, options],
  );

  // refetchQueries
  mutationOptions.refetchQueries = addContextToRefetchQueries(
    mutationOptions.refetchQueries,
    baseContext,
  );

  const {hookVariables, hookOptionsWithoutVariables} = useMemo(() => {
    const {variables, ...rest} = mutationOptions;

    return {
      hookVariables: variables,
      hookOptionsWithoutVariables: rest,
    };
  }, [mutationOptions]);

  const memoizedHookVariables = useMemoizedValue(hookVariables);

  const [callback, result] = useApolloMutation(
    mutation,
    hookOptionsWithoutVariables,
  );

  const callbackWrapped = useCallback(
    (
      ...restArgs: Parameters<
        UseMutationReturn<
          GraphQlDocument<TData, TVariables, TPermissions>,
          THookToken,
          THookVariables
        >[0]
      >
    ): Promise<
      MutationPromiseReturn<GraphQlDocument<TData, TVariables, TPermissions>>
    > => {
      const cbOptions = restArgs[0];

      const mergedVariables = {
        ...memoizedHookVariables,
        ...cbOptions?.variables,
      } as VariablesOfDoc<GraphQlDocument<TData, TVariables, TPermissions>>;

      const mergedOptions = {
        ...cbOptions,
        variables: mergedVariables,
        refetchQueries: addContextToRefetchQueries(
          cbOptions?.refetchQueries,
          baseContext,
        ),
      };

      return callback(mergedOptions).then(({errors, ...rest}) => ({
        ...rest,
        // Fix for the mistyping of errors in Apollo
        // https://github.com/apollographql/apollo-client/blob/eb2cfee1846b6271e438d1a268e187151e691db4/src/react/hooks/useMutation.ts#L165-L166
        errors: errors as unknown as ApolloError | undefined,
        error: errors as unknown as ApolloError | undefined,
      }));
    },
    [baseContext, callback, memoizedHookVariables],
  );

  return [
    callbackWrapped,

    useTrackGraphQlFieldAccesses(result, {
      query: mutation,
      sampleRate: options.trackAccesses ?? DEFAULT_TRACK_ACCESSES_SAMPLE_RATE,
      ignoreFields: DEFAULT_IGNORE_TRACKING_FIELDS,
    }),
  ];
}

function addContextToRefetchQueries(
  refetchQueries: GraphQlDocument<any, any, any>[] | undefined,
  context: OperationContext,
): GraphQlDocument<any, any, any>[] | undefined {
  return refetchQueries?.map((queryData) => {
    if (queryData && typeof queryData === 'object' && 'query' in queryData) {
      return {
        ...queryData,
        context: {
          ...context,
        },
      };
    }
    return queryData;
  });
}
