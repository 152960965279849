// This file was generated using `pay js:run gql-v2 --type=mutation --name=UpdateConsent --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import {validationErrorFragment} from 'gelato/frontend/src/graphql/fragments/validationErrorFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  ConsentDataInput,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import type {ValidationErrorFragmentData} from 'gelato/frontend/src/graphql/fragments/validationErrorFragment';

const doc = graphql`
  mutation UpdateConsentMutation($consentData: ConsentDataInput!) {
    updateConsent(consentData: $consentData) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        ...ValidationErrorFragment
      }
    }
  }

  ${sessionDynamicFragment}
  ${validationErrorFragment}
`;

export const updateConsentMutation: UpdateConsentMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const updateConsentMutationOptions: GelatoMutationOptionsWithoutVariables<UpdateConsentMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useUpdateConsentMutation(
  options: GelatoMutationOptions<UpdateConsentMutationDocument> = {},
): GelatoMutationReturn<UpdateConsentMutationDocument> {
  return useGelatoMutation(updateConsentMutation, {
    ...updateConsentMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type UpdateConsentMutationVariables = Exact<{
  consentData: ConsentDataInput;
}>;

export type UpdateConsentMutationData = {
  readonly __typename: 'Mutation';
  readonly updateConsent: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<
      GraphQlFragmentUnion<
        {readonly __typename: 'ValidationError'},
        [ValidationErrorFragmentData]
      >
    >;
  };
};

const updateConsentMutationPermissions = {
  ...sessionDynamicFragment.permissions,
  ...validationErrorFragment.permissions,
} as const;

doc.permissions = updateConsentMutationPermissions;

export type UpdateConsentMutationPermissions = GelatoPermissionToken<
  typeof updateConsentMutationPermissions
>;

export type UpdateConsentMutationDocument = GraphQlDocument<
  UpdateConsentMutationData,
  UpdateConsentMutationVariables,
  UpdateConsentMutationPermissions
>;
