import {useMemo, useContext, useEffect, useLayoutEffect} from 'react';
import {useId} from '../@react-aria/utils';
import {useIndex} from './useIndexedChildren';
import type {Item, ItemDesc} from './types';
import {CollectionRegisterContext} from './context';

const isServer = typeof window === 'undefined';
const useIsomorphicLayoutEffect = isServer ? useEffect : useLayoutEffect;

/**
 * Use this inside all components that represent items. You can specify `type`
 * to create different types of items, and provide an arbitrary `data`
 * object that is later used to customize behavior.
 */
export function useItem(
  id: string | undefined,
  {ref, type = 'item', data, textValue, 'aria-label': ariaLabel}: ItemDesc,
): string {
  const register = useContext(CollectionRegisterContext);

  if (register == null) {
    // We cast away the undefined because in this code path, we enforce that an
    // id exists internally when processing `data`
    return id as string;
  }

  // TODO(jlongster): we now use this check to decide if we can skip this or
  // not. We could bring back this check with a symbol that we check if it has
  // been initialized or not
  //
  //
  // if (register == null) {
  //   throw new Error(
  //     `Item rendered without a collection context: ${JSON.stringify(data)}`,
  //   );
  // }

  // We disable this because we are taking two different code paths
  /* eslint-disable react-hooks/rules-of-hooks */

  const key = useId(id);
  const index = useIndex();

  if (index == null) {
    // `useCollection` sets up both `register` and `index`, but
    // `useIndex` may still return `null` according to the types.
    // This is mainly to narrow the type to be non-null
    throw new Error('Improper collection context: item has no index');
  }

  const item = useMemo(
    () => {
      return {
        data,
        type,
        key,
        indexPathString: index.indexPathString,
        ref,
        // Allow the user to provide different values for these, but if they
        // only provide one of them make the other one fall back to it
        textValue: textValue || ariaLabel,
        'aria-label': ariaLabel || textValue || '',
      } as ItemDesc;
    },
    // TODO: we intentionally ignore `data` because it's far to easy
    // to cause infinite rerenders by not memoizing data. However, if the
    // user wants to dynamically update props that data uses, we should
    // figure out a way to allow that
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, key, index, ref, textValue, ariaLabel],
  );

  useIsomorphicLayoutEffect(
    () => register(key, item as Item),
    [register, key, item],
  );

  /* eslint-enable react-hooks/rules-of-hooks */

  return key;
}
