import {InMemoryCache} from '@apollo/client';

import type {InMemoryCacheConfig} from '@apollo/client';
import type {GraphQlConfig, PossibleTypes} from 'src/types';

const dataIdFromObject: NonNullable<InMemoryCacheConfig['dataIdFromObject']> = (
  result,
) => {
  if (
    result.__typename &&
    result.cache_context_key !== undefined &&
    result.cache_context_key !== null &&
    result.cache_context_key !== '' &&
    result.id !== undefined &&
    result.id !== null &&
    result.id !== ''
  ) {
    return `${result.__typename}:${result.cache_context_key}:${result.id}`;
  }

  if (
    result.__typename &&
    result.id !== undefined &&
    result.id !== null &&
    result.id !== '' &&
    // This is a special-case hack in order to handle the fact that source ids
    // are not really unique, i.e. multiple charges can contain sources that
    // share ids, but have different payloads part from id.
    //
    // So we bail out of using source.id for purposes of `dataId` here, which
    // will force apollo-client to fall back to the parent object for cache key
    // purposes.
    //
    // See https://jira.corp.stripe.com/browse/RUN_PAYDASH-2220 and
    // https://confluence.corp.stripe.com/display/AUTH/Card+Ancestry+Tree
    result.__typename !== 'OpenapiSource'
  ) {
    return `${result.__typename}:${result.id}`;
  }

  return undefined;
};

const createCache = (schemas: GraphQlConfig['schemas']) => {
  const possibleTypes: PossibleTypes = {};
  const anonymousTypes: string[] = [];

  for (const schemaKey in schemas) {
    if (schemas.hasOwnProperty(schemaKey)) {
      const schema = schemas[schemaKey];

      if (schema?.possibleTypes) {
        for (const key in schema.possibleTypes) {
          if (schema.possibleTypes.hasOwnProperty(key)) {
            possibleTypes[key] = schema.possibleTypes[key];
          }
        }
      }

      if (schema?.anonymousTypes) {
        for (const anonymousType of schema.anonymousTypes) {
          anonymousTypes.push(anonymousType);
        }
      }
    }
  }

  return new InMemoryCache({
    possibleTypes,
    dataIdFromObject,
    typePolicies: Object.fromEntries(
      anonymousTypes.map((type) => [type, {merge: true}]),
    ),
  });
};

export default createCache;
