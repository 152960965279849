// This file was generated using `pay js:run gql-v1 --type=query --name=Gelato --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import type {GraphQlDocument, OperationVariables} from '@sail/data';

import {useQuery} from '@sail/data';

import type {GelatoQueryOptions, GelatoQueryReturn} from '../types';

export default function useGelatoQuery<
  TData,
  TVariables extends OperationVariables,
  TPermissions,
>(
  query: GraphQlDocument<TData, TVariables, TPermissions>,
  options: GelatoQueryOptions<GraphQlDocument<TData, TVariables, TPermissions>>,
): GelatoQueryReturn<GraphQlDocument<TData, TVariables, TPermissions>> {
  return useQuery(query, options);
}
