// This file was generated using `pay js:run gql-v1 --type=lazy-query --name=Gelato --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {useLazyQuery} from '@sail/data';

import type {GraphQlDocument, OperationVariables} from '@sail/data';

import type {GelatoLazyQueryOptions, GelatoLazyQueryReturn} from '../types';

export default function useGelatoLazyQuery<
  TData,
  TVariables extends OperationVariables,
  TPermissions,
  THookVariables extends Partial<TVariables> = {},
>(
  query: GraphQlDocument<TData, TVariables, TPermissions>,
  options: GelatoLazyQueryOptions<
    GraphQlDocument<TData, TVariables, TPermissions>,
    TPermissions,
    THookVariables
  >,
): GelatoLazyQueryReturn<
  GraphQlDocument<TData, TVariables, TPermissions>,
  undefined,
  THookVariables
> {
  return useLazyQuery(query, options) as GelatoLazyQueryReturn<
    GraphQlDocument<TData, TVariables, TPermissions>,
    undefined,
    THookVariables
  >;
}
