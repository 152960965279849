import React from 'react';

export const IframeScrollbarStyleContext = React.createContext<React.FC | null>(
  null,
);

export const useScrollbarStyleOverrides = (): React.FC => {
  const scrollbarStyleOverrides = React.useContext(IframeScrollbarStyleContext);
  if (!scrollbarStyleOverrides) {
    throw new Error(
      'useScrollbarStyleOverride must be used within a IframeScrollbarStyleProvider',
    );
  }
  return scrollbarStyleOverrides;
};

interface RequestIframeProviderProps {
  children: React.ReactNode;
  scrollbarStyleOverrides: React.FC;
}

export const IframeScrollbarStyleProvider: React.FC<
  RequestIframeProviderProps
> = ({children, scrollbarStyleOverrides}) => {
  return (
    <IframeScrollbarStyleContext.Provider value={scrollbarStyleOverrides}>
      {children}
    </IframeScrollbarStyleContext.Provider>
  );
};
