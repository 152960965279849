import * as React from 'react';
import {createContext, useContext} from 'react';
import type {Collection, RegisterableCollection, Item} from './types';
import type {CollectionState} from './state/types';

export const CollectionRegisterContext = createContext<
  RegisterableCollection['register'] | null
>(null);
export const CollectionItemsContext = createContext<
  Collection['getItems'] | null
>(null);
export const CollectionStateContext = createContext<
  CollectionState | null | undefined
>(null);

/**
 * This takes a collection returned from `useCollection` and makes it available
 * to all components. You must use this in the top-level component for the
 * system to work; otherwise `useItem` won't be able to register items.
 */
export function CollectionProvider({
  collection,
  state,
  children,
}: {
  collection: Collection;
  children: React.ReactNode;
  state?: CollectionState;
}): JSX.Element {
  return (
    <CollectionStateContext.Provider value={state}>
      <CollectionRegisterContext.Provider
        value={(collection as RegisterableCollection).register}
      >
        <CollectionItemsContext.Provider value={collection.getItems}>
          {children}
        </CollectionItemsContext.Provider>
      </CollectionRegisterContext.Provider>
    </CollectionStateContext.Provider>
  );
}

/**
 * This hook will return all the items.
 */
export function useCollectionItems(): Array<Item> {
  const items: Array<Item> = useContext(CollectionItemsContext)?.() || [];
  return items;
}

export function useCollectionState(): CollectionState {
  const state = useContext(CollectionStateContext);
  if (state == null) {
    throw new Error('Tried to access collection state outside of a collection');
  }
  return state;
}
