import * as React from 'react';
import {useRef} from 'react';

import {view, createViewConfig, dynamic, assignProps} from '@sail/engine';
import type {View} from '@sail/engine';
import {checkCircleFilled} from '@sail/icons/react/Icon';
import type {IconAsset, IconCategory} from '@sail/icons/types';

import type {ItemTransforms, TreeState} from '@sail/react-aria';
import {
  isFocusVisible,
  useOption,
  useItem,
  useCollectionState,
} from '@sail/react-aria';
import {Icon, IconConfig} from './Icon';
import {css} from '../css';

/**
 * These icon categories are ones that we feel need to be shown
 * slightly larger, due to their low visual weight at 12px
 */
const SPECIAL_ICON_CATEGORIES: IconCategory[] = [
  'flag',
  'brand',
  'card',
  'paymentmethod',
];

export type ListBoxItemProps = {
  /**
   * Unique identifier, used as the value in `onSelectionChange`
   */
  id: string;
  /**
   * Stops the item from being selectable
   */
  // disabled?: boolean;
  /**
   * The contents of the listbox item.
   */
  children?: React.ReactNode;
  /**
   * An optional way to add more context to a list item element. Will be exposed to screenreaders as `aria-describedby`
   */
  description?: React.ReactNode;
  /**
   * An optional way to add an icon to a list item element.
   */
  icon?: React.ReactNode;
  /**
   * If the item contains a non-textual visual representation of the item data (like a country flag)
   * then you must provide a label describing the item for accessibility.
   */
  'aria-label'?: string;
  /**
   * Used for filtering and accessibility features.
   */
  textValue?: string;
  /**
   * Additional data used during filtering or rendering, the lighter the better
   * */
  data?: Record<string, unknown>;

  subviews?: View.Subviews<{
    content: 'span';
    label: 'span';
    description: 'span';
    checked: typeof Icon;
  }>;
};

export const ListBoxItemTransforms: ItemTransforms<
  ListBoxItemProps,
  'textValue'
> = {
  defaultField: 'textValue',
  makeGenericItem(props: ListBoxItemProps) {
    const textValue =
      props.textValue ||
      (typeof props.children === 'string' ? props.children : props.id);
    return {
      'aria-label': props['aria-label'] || textValue,
      textValue,
      data: {icon: props.icon, children: props.children},
    };
  },
};

export const ListBoxItemConfig = createViewConfig({
  name: 'ListBoxItem',
  props: {} as ListBoxItemProps,
});

export const ListBoxItem = ListBoxItemConfig.createView(
  (allProps) => {
    const {
      id,
      children,
      textValue,
      subviews,
      icon,
      description,
      // `data` is not used anywhere right now
      data,
      ...props
    } = allProps;
    const key = useItem(
      id,
      ListBoxItemTransforms.makeGenericItem({
        id,
        'aria-label': props['aria-label'],
        children,
        textValue,
        icon,
      }),
    );
    const state = useCollectionState();
    const tree = state as TreeState;

    const ref = useRef<HTMLElement>(null);
    const {optionProps, isFocused, isSelected, labelProps, descriptionProps} =
      useOption({key}, state, ref);

    return (
      <view.div
        {...props}
        ref={ref}
        uses={[
          assignProps(optionProps as View.IntrinsicElement<'div'>),
          isFocusVisible() && isFocused
            ? css({
                focusRing: 'focus',
                // create new stacking context to allows the focus ring to appear on top
                // of the potential hover state in the item below it, without resorting to
                // bumping the zIndex
                position: 'relative',
              })
            : css({
                // focus ring will only apply when focus-visible and focused
                focusRing: 'none',
              }),
        ]}
      >
        <IconConfig.Provider
          value={{
            css: {
              '[aria-disabled="true"] &': {
                opacity: '30%',
              },
            },
            uses: [
              // certain categories of icons are too small at 12px, so we bump them up to 16px
              dynamic(({icon}: {icon: IconAsset}) => {
                const result = [];
                if (SPECIAL_ICON_CATEGORIES.includes(icon.category)) {
                  result.push(css({width: 'medium', height: 'medium'}));
                }
                return result;
              }),
              // irrespective of the icon's size, provision the same space
              css({flexBasis: '16px'}),
            ],
          }}
        >
          {icon}

          {!icon && tree.expandable ? (
            <view.span role="presentation" css={{width: 'medium'}} />
          ) : null}

          {description ? (
            <view.span inherits={subviews.content}>
              <view.span {...labelProps} inherits={subviews.label}>
                {children}
              </view.span>
              <view.span {...descriptionProps} inherits={subviews.description}>
                {description}
              </view.span>
            </view.span>
          ) : (
            children
          )}
        </IconConfig.Provider>
        <Icon
          icon={checkCircleFilled}
          role="img"
          css={{
            opacity: isSelected ? 1 : 0,
            // Show the checkbox selection changing on press to give the user
            // immediate feedback that their selection has been registered.
            // Doing this via styles only in order to retain the correct semantics
            // when in a combobox.
            ...(state.selectionManager.selectionMode !== 'multiple'
              ? {
                  // show the checkmark when the item is being selected
                  ':active:not([aria-disabled="true"]) > &': {opacity: 1},
                  // hide the current selection (that is being replaced)
                  ':active > :not(:active) > &': {opacity: 0},
                }
              : {}),
          }}
          inherits={subviews.checked}
        />
      </view.div>
    );
  },
  {
    css: {
      stack: 'x',
      font: 'label.medium.emphasized',
      color: 'form',
      gap: 'space.75',
      borderRadius: 'xsmall',
      paddingY: 'xsmall',
      paddingX: 'space.75',
      alignY: 'baseline',
      alignX: 'start',
      cursor: 'pointer',

      ':hover': {
        backgroundColor: 'offset',
      },

      '[aria-disabled="true"]': {
        color: 'form.disabled',
        cursor: 'default',
      },

      '[aria-disabled="true"]:hover': {
        backgroundColor: 'form',
      },
    },

    subviews: {
      checked: {
        css: {
          pointerEvents: 'none',
          transition: 'opacity 150ms',
          marginLeft: 'auto',
        },
      },
      description: {
        css: {
          font: 'label.small',
          color: 'subdued',
          fontWeight: 'normal',
          '[aria-disabled="true"] &': {
            color: 'form.disabled',
          },
        },
      },
      content: {
        css: {
          stack: 'y',
          // Prevents selection and cursor changes and event overrides
          pointerEvents: 'none',
        },
      },
    },

    uses: [IconConfig.customize({defaults: {size: 'xsmall'}})],
  },
);
