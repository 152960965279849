import type {
  AriaLabelingProps,
  DOMAttributes,
  DOMProps,
  LabelableProps,
} from '@react-types/shared';
import type {ElementType, LabelHTMLAttributes} from 'react';
import {useId, useLabels} from '../utils';

export interface LabelAriaProps
  extends LabelableProps,
    DOMProps,
    AriaLabelingProps {
  /**
   * The HTML element used to render the label, e.g. 'label', or 'span'.
   * @default 'label'
   */
  labelElementType?: ElementType;
}

export interface LabelAria {
  /** Props to apply to the label container element. */
  labelProps: {
    id?: DOMAttributes['id'];
    htmlFor?: LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  };
  /** Props to apply to the field container element being labeled. */
  fieldProps: AriaLabelingProps & DOMProps;
}

/**
 * Provides the accessibility implementation for labels and their associated elements.
 * Labels provide context for user inputs.
 * @param props - The props for labels and fields.
 */
export function useLabel(props: LabelAriaProps): LabelAria {
  const {label, 'aria-label': ariaLabel, labelElementType = 'label'} = props;

  const id = useId(props.id);
  const labelId = useId();
  let ariaLabelledby = props['aria-labelledby'];
  let labelProps = {};
  if (label) {
    ariaLabelledby = ariaLabelledby ? `${labelId} ${ariaLabelledby}` : labelId;
    labelProps = {
      id: labelId,
      htmlFor: labelElementType === 'label' ? id : undefined,
    };
  } else if (!ariaLabelledby && !ariaLabel) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'If you do not provide a visible label, you must specify an aria-label or aria-labelledby attribute for accessibility',
      );
    }
  }

  const fieldProps = useLabels({
    id,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
  });

  return {
    labelProps,
    fieldProps,
  };
}
