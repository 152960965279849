import {createContext} from 'react';
import {DEFAULT_SET_AND_UNSET_VALUE} from 'src/internal/constants';

export default createContext<{
  set(
    start: (reason?: string | undefined) => void,
    stop: (reason?: string | undefined) => void,
  ): void;

  unset(
    start: (reason?: string | undefined) => void,
    stop: (reason?: string | undefined) => void,
  ): void;
}>(DEFAULT_SET_AND_UNSET_VALUE);
