import {useMemoizedValue} from '@sail/react';
import {useContext, useMemo, useRef} from 'react';
import {
  DEFAULT_SET_AND_UNSET_VALUE,
  DEFAULT_START_VALUE,
} from 'src/internal/constants';
import ObservabilityApiProvider from 'src/internal/provider/ObservabilityApiProvider';
import ObservabilityConfigProvider from 'src/internal/provider/ObservabilityConfigProvider';
import PerformanceMonitorStartStopContext from 'src/internal/provider/PerformanceMonitorStartStopContext';
import PerformanceMonitorTreeContext from 'src/internal/provider/PerformanceMonitorTreeContext';
import DebugBoundary from 'src/public/DebugBoundary';

import type {ReactElement, ReactNode} from 'react';
import type {ObservabilityConfig} from 'src/types';

type Props = {
  children?: ReactNode;
} & ObservabilityConfig;

/**
 * Sets the observability context for the current component tree.
 *
 * `ObservabilityProvider` can be nested to override values deeper in the tree,
 * specifically to reset the owner of a particular section of the React tree.
 *
 * The configuration provided to ObservabilityProvider` is merged with the
 * configuration provided by the closest `ObservabilityProvider` in the
 * component tree. The configuration provided to the current provider takes
 * precedence over the ancestor. Configurations are merged in a shallow manner,
 * meaning that nested objects are not merged.
 *
 * It is also worth noting that `ObservabilityProvider` does not capture
 * errors. If you would like to do that you should nest an `ErrorBoundary`.
 *
 * @example Basic {{include "./examples/ObservabilityProvider.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/observability/ObservabilityProvider
 * @see https://sail.stripe.me/apis/observability/ErrorBoundary
 */
export default function ObservabilityProvider({
  children,
  ...config
}: Props): ReactElement {
  const stableConfig = useMemoizedValue(config);
  const startsRef = useRef(new Set<(reason?: string) => void>());
  const stopsRef = useRef(new Set<(reason?: string) => void>());
  const setUnsetParent = useContext(PerformanceMonitorTreeContext);
  const startStopParent = useContext(PerformanceMonitorStartStopContext);

  const setUnsetCurrent = useMemo(() => {
    return {
      set(
        start: (reason?: string | undefined) => void,
        stop: (reason?: string | undefined) => void,
      ) {
        startsRef.current.add(start);
        stopsRef.current.add(stop);
      },

      unset(
        start: (reason?: string | undefined) => void,
        stop: (reason?: string | undefined) => void,
      ) {
        startsRef.current.delete(start);
        stopsRef.current.delete(stop);
      },
    };
  }, []);

  const startStopCurrent = useMemo(() => {
    return {
      start: (reason?: string | undefined) => {
        startsRef.current.forEach((start) => start(reason));
      },

      stop: (reason?: string | undefined) => {
        stopsRef.current.forEach((stop) => stop(reason));
      },
    };
  }, []);

  const setUnset =
    setUnsetParent === DEFAULT_SET_AND_UNSET_VALUE
      ? setUnsetCurrent
      : setUnsetParent;

  const start =
    startStopParent === DEFAULT_START_VALUE
      ? startStopCurrent
      : startStopParent;

  return (
    <PerformanceMonitorTreeContext.Provider value={setUnset}>
      <PerformanceMonitorStartStopContext.Provider value={start}>
        <ObservabilityConfigProvider config={stableConfig}>
          <ObservabilityApiProvider>
            <DebugBoundary
              category="ObservabilityProvider"
              name={stableConfig.project}
              color="#513DD9"
            >
              {children}
            </DebugBoundary>
          </ObservabilityApiProvider>
        </ObservabilityConfigProvider>
      </PerformanceMonitorStartStopContext.Provider>
    </PerformanceMonitorTreeContext.Provider>
  );
}
