// This file was generated using `pay js:run gql-v2 --type=mutation --name=UpdateIndividual --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import {validationErrorFragment} from 'gelato/frontend/src/graphql/fragments/validationErrorFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  NameDataInput,
  DateInput,
  IdNumberDataInput,
  AddressDataInput,
  EmailDataInput,
  PhoneNumberDataInput,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import type {ValidationErrorFragmentData} from 'gelato/frontend/src/graphql/fragments/validationErrorFragment';

const doc = graphql`
  mutation UpdateIndividualMutation(
    $nameData: NameDataInput
    $dobData: DateInput
    $idNumberData: IdNumberDataInput
    $addressData: AddressDataInput
    $emailData: EmailDataInput
    $phoneNumberData: PhoneNumberDataInput
  ) {
    updateIndividual(
      nameData: $nameData
      dobData: $dobData
      idNumberData: $idNumberData
      addressData: $addressData
      emailData: $emailData
      phoneNumberData: $phoneNumberData
    ) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        ...ValidationErrorFragment
      }
    }
  }

  ${sessionDynamicFragment}
  ${validationErrorFragment}
`;

export const updateIndividualMutation: UpdateIndividualMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const updateIndividualMutationOptions: GelatoMutationOptionsWithoutVariables<UpdateIndividualMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useUpdateIndividualMutation(
  options: GelatoMutationOptions<UpdateIndividualMutationDocument> = {},
): GelatoMutationReturn<UpdateIndividualMutationDocument> {
  return useGelatoMutation(updateIndividualMutation, {
    ...updateIndividualMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type UpdateIndividualMutationVariables = Exact<{
  nameData?: InputMaybe<NameDataInput>;
  dobData?: InputMaybe<DateInput>;
  idNumberData?: InputMaybe<IdNumberDataInput>;
  addressData?: InputMaybe<AddressDataInput>;
  emailData?: InputMaybe<EmailDataInput>;
  phoneNumberData?: InputMaybe<PhoneNumberDataInput>;
}>;

export type UpdateIndividualMutationData = {
  readonly __typename: 'Mutation';
  readonly updateIndividual: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<
      GraphQlFragmentUnion<
        {readonly __typename: 'ValidationError'},
        [ValidationErrorFragmentData]
      >
    >;
  };
};

const updateIndividualMutationPermissions = {
  ...sessionDynamicFragment.permissions,
  ...validationErrorFragment.permissions,
} as const;

doc.permissions = updateIndividualMutationPermissions;

export type UpdateIndividualMutationPermissions = GelatoPermissionToken<
  typeof updateIndividualMutationPermissions
>;

export type UpdateIndividualMutationDocument = GraphQlDocument<
  UpdateIndividualMutationData,
  UpdateIndividualMutationVariables,
  UpdateIndividualMutationPermissions
>;
