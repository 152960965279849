import {createContext, useContext, useRef, useState} from 'react';
import {dynamic, assignProps, provider, toIntent} from '@sail/engine';
import type {FocusManagerOptions} from '@sail/react-aria';
import {createFocusManager, useId} from '@sail/react-aria';
import type {Key} from 'react';
import {PopoverConfig} from './popover';

interface FocusState {
  /** The current focused key in the collection. */
  readonly focusedKey?: Key;
  /** Sets the focused key */
  setFocusedKey(key: Key): void;
}

const RovingFocusContext = createContext<FocusState | null>(null);

type CompositeComponentProps = {
  onKeyDown: (e: React.KeyboardEvent) => void;
  ref: React.RefObject<HTMLDivElement>;
  /**
   * Indicates the directionality of the element's text.
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/dir
   */
  dir?: 'ltr' | 'rtl';
};

type CompositeOptions = {
  /**
   * Indicates the directionality of the element's text.
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/dir
   */
  dir?: 'ltr' | 'rtl';

  /**
   * Defines the orientation of the composite widget. If the composite has a single
   * row or column (one-dimensional), the orientation value determines which arrow keys can be used to move focus:
   *
   * If orientation is:
   * - undefined: arrow keys move focus in both dimensions.
   * - horizontal: focus moves left and right with arrow keys.
   * - vertical: focus moves up and down with arrow keys.
   */
  orientation?: 'horizontal' | 'vertical';
} & FocusManagerOptions;

/**
 * To correctly keep roving tabindex state in sync, the `rovingTabReset` intent should be applied to the
 * popover or dialog that contains the `rovingTabIndex` element (context is preserved).
 *
 * This reset allows memorizing last focused roving tabindex element when the popover or dialog is closed.
 * E.g. you have ButtonGroup, some Button has popover -> moving focus inside and pressing `Shift + Tab` should
 * focus the last focused Button in the ButtonGroup.
 */
export const rovingTabReset = dynamic(() => [
  provider(RovingFocusContext, null),
]);

export const createComposite = (
  options: CompositeOptions = {
    wrap: true,
    orientation: 'horizontal',
    dir: 'ltr',
  },
) =>
  toIntent([
    dynamic(() => {
      const [focusedKey, setFocusedKey] = useState<Key | undefined>(undefined);

      const focusState: FocusState = {
        focusedKey,
        setFocusedKey,
      };

      return [provider(RovingFocusContext, focusState)];
    }),
    assignProps((props: Partial<CompositeComponentProps>) => {
      const ref = useRef<HTMLDivElement>(null);
      const focusManager = createFocusManager(ref);
      const dir = props.dir || options?.dir;

      function onKeyDown(e: React.KeyboardEvent) {
        switch (e.key) {
          case 'ArrowRight':
          case 'ArrowLeft':
            if (options?.orientation === 'vertical') {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            if (
              (e.key === 'ArrowLeft' && dir !== 'rtl') ||
              (e.key === 'ArrowRight' && dir === 'rtl')
            ) {
              focusManager.focusPrevious(options);
            } else {
              focusManager.focusNext(options);
            }
            break;
          case 'ArrowDown':
          case 'ArrowUp':
            if (options?.orientation === 'horizontal') {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            if (e.key === 'ArrowUp') {
              focusManager.focusPrevious(options);
            } else {
              focusManager.focusNext(options);
            }
            break;
          default:
            break;
        }
      }

      return {onKeyDown, ref};
    }),
    /**
     * Currently it's applying roving tab reset only to `PopoverConfig`,
     * but can be later extended to `LayerConfig` if needed.
     */
    PopoverConfig.customize({
      subviews: {
        layer: {
          uses: [rovingTabReset],
        },
      },
    }),
  ]);

/**
 * `composite` is an intent that be applied to a component that may contain navigable items. This intent
 * is inspired by the [WAI-ARIA Composite Role](https://www.w3.org/TR/wai-aria-1.1/#composite) and implements
 * the necessary [keyboard navigation](https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#x6-6-1-managing-focus-within-components-using-a-roving-tabindex)
 * to ensure there is only one tab stop for the `Composite` element.
 *
 * The `composite` element can behave as a roving tabindex or aria-activedescendant container, when navigable items have a corresponding intent applied.
 */
export const composite = createComposite();

/**
 * `rovingTabIndex` is an intent that can be applied to navigable items within a `composite` element.
 *
 * When using roving tabindex to manage focus in a composite UI component, the element that is to be
 * included in the tab sequence has tabindex of "0" and all other focusable elements contained in the
 * composite have tabindex of "-1".
 */
export const rovingTabIndex = assignProps(() => {
  const focusState = useContext(RovingFocusContext);
  const key = useId();

  if (!focusState) {
    return {};
  }
  const isFocusWithin = focusState.focusedKey !== undefined;
  const isFocused = isFocusWithin && key === focusState.focusedKey;

  return {
    tabIndex: !isFocusWithin || isFocused ? 0 : -1,
    onFocus: () => focusState.setFocusedKey(key),
  };
});
