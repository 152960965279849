import {createCustomProperty} from '@sail/engine';
import type {Style} from '@sail/engine';
import {deprecate} from './utils';

// Add an additional variable for vertical alignment based on font metrics
export const transformAlignY = createCustomProperty('--transform-align-y', {
  initialValue: '0',
});

const transform = createCustomProperty('--transform', {
  initialValue: 'scale(1)',
});

function createTransformPlugin(
  customProperty: Style.CustomProperty,
): Style.Plugin {
  return function transformPlugin(value, set) {
    set.property(customProperty, () => {
      set.property(
        'transform',
        // https://www.w3.org/TR/css-transforms-2/#ctm
        `translateY(${set.var(transformAlignY)})
        ${set.var(transform)}`,
        {skipPlugin: true},
      );
      set.property(customProperty, value);
    });
  };
}

export const pluginsTransform = {
  [transformAlignY.intent.toString()]: createTransformPlugin(transformAlignY),
  x: deprecate('x', undefined, 'transform: translateX()'),
  y: deprecate('y', undefined, 'transform: translateY()'),
  scale: deprecate('scale', undefined, 'transform: scale()'),
  rotate: deprecate('rotate', undefined, 'transform: rotate()'),
  transform: createTransformPlugin(transform),
};
