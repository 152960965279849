import * as React from 'react';
import {createContext, useContext, useRef, useCallback, useMemo} from 'react';
import {view, createViewConfig} from '@sail/engine';
import type {View} from '@sail/engine';
import {flag} from '@sail/icons/react/Icon';
import {
  CollectionProvider,
  useListBox,
  useListCollection,
  useReactAriaTreeState,
} from '@sail/react-aria';
import type {
  Expandable,
  ItemDesc,
  KeyboardDelegate,
  ListCollection,
  ListCollectionComponent,
  MultipleSelectionStateProps,
  TreeState,
} from '@sail/react-aria';
import {FauxTreeItem, FauxTreeItemTransforms} from './FauxTreeItem';
import {FauxTreeGroup, FauxTreeGroupTransforms} from './FauxTreeGroup';
import type {FauxTreeItemProps} from './FauxTreeItem';
import type {FauxTreeGroupProps} from './FauxTreeGroup';
import {Icon} from './Icon';
import {FauxTreeSelectAllItem} from './FauxTreeSelectAllItem';
import {FauxTreeProvider} from '../hooks/useFauxTreeState';

type SelectionProps = Pick<
  MultipleSelectionStateProps,
  | 'onSelectionChange'
  | 'selectionMode'
  | 'defaultSelectedKeys'
  | 'selectedKeys'
  | 'disabledKeys'
>;

export type FauxTreeProps<T> = ListCollectionComponent<
  T,
  FauxTreeItemProps,
  typeof FauxTreeItemTransforms.defaultField,
  FauxTreeGroupProps,
  typeof FauxTreeGroupTransforms.idField
> &
  View.IntrinsicElement<
    'div',
    {
      /**
       * Required, but currently only used for accessibility purposes. In the future it may be
       * shown above the FauxTree in time when it's inline in the page.
       */
      label: React.ReactNode;

      /**
       * Content to render when the list is empty.
       */
      emptyState?: React.ReactNode;
      /**
       * If true, an option to select/unselect all items is visible at the top of the list
       */
      selectionAllEnabled?: boolean;

      /** Messages used to translate texts of the Faux Tree */
      messages?: {
        selectedText?: (itemsCount: number, allCount: number) => string;
      };

      /** Enables a counter with the selected items in each group. * */
      groupCounterEnabled?: boolean;
    }
  > &
  SelectionProps &
  Expandable;

export const FauxTreeConfig = createViewConfig({
  name: 'FauxTree',
  props: {} as FauxTreeProps<unknown>,
  defaults: {
    selectionMode: 'single',
    selectionAllEnabled: true,
    defaultExpandedKeys: 'all',
    expandable: false,
    groupCounterEnabled: true,
  },
});

const noResults = (
  <view.div
    css={{
      font: 'label.medium',
      marginX: 'xsmall',
      marginY: 'large',
      padding: 'small',
      color: 'subdued',
      textAlign: 'center',
    }}
  >
    <Icon size="xsmall" icon={flag} css={{marginX: 'space.75'}} /> No results
  </view.div>
);

// We allow for users to override advanced behaviors via context, and these are
// the options (in addition to the `state` and `collection`) that can be
// overriden
export interface FauxTreeOptions {
  /**
   * An optional keyboard delegate implementation for type to select,
   * to override the default.
   */
  keyboardDelegate?: KeyboardDelegate;
  /**
   * Whether the FauxTree items should use virtual focus instead of being focused directly.
   */
  shouldUseVirtualFocus?: boolean;
  /** Whether selection should occur on press up instead of press down. */
  shouldSelectOnPressUp?: boolean;
  /** Whether options should be focused when the user hovers over them. */
  shouldFocusOnHover?: boolean;
  /** Whether the collection allows empty selection. */
  readonly disallowEmptySelection?: boolean;

  /** Whether to auto focus the FauxTree or an option. */
  autoFocus?: boolean;

  selectAllRef?: React.MutableRefObject<HTMLInputElement | null>;
}

interface FauxTreeContextValue {
  state?: TreeState;
  collection?: ListCollection<FauxTreeItemProps, FauxTreeGroupProps>;
  options?: FauxTreeOptions;
}

export const FauxTreeContext = createContext<FauxTreeContextValue>({});

const useListBoxState = <T extends object>(
  props: Pick<
    FauxTreeProps<T>,
    | 'data'
    | 'item'
    | 'group'
    | 'children'
    | 'onSelectionChange'
    | 'selectionMode'
    | 'defaultSelectedKeys'
    | 'selectedKeys'
    | 'disabledKeys'
    | 'expandable'
    | keyof Expandable
  >,
) => {
  const {
    state: inheritedState,
    collection: inheritedCollection,
    options: inheritedOptions,
  } = useContext(FauxTreeContext);

  // Track whether we're using inherited state or not so we can't violate the
  // rules of hooks by switching between the two.
  const isUsingInheritedState = useRef(
    Boolean(inheritedState && inheritedCollection),
  );

  if (isUsingInheritedState.current) {
    return {
      state: inheritedState,
      collection: inheritedCollection,
      options: inheritedOptions,
    } as Required<FauxTreeContextValue>;
  }

  // Create collection and based on the incoming props

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const collection = useListCollection({
    ...props,
    itemTransforms: FauxTreeItemTransforms,
    groupTransforms: FauxTreeGroupTransforms,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const state = useReactAriaTreeState({
    getItems: collection.getItems,
    ...props,
  });

  return {collection, state, options: {} as FauxTreeOptions};
};

export const DO_NOT_USE_FauxTree = FauxTreeConfig.createGenericView(
  <T extends object>({
    data,
    item,
    group,
    children,
    label,
    onSelectionChange,
    selectionMode,
    selectionAllEnabled,
    defaultSelectedKeys,
    selectedKeys,
    disabledKeys,
    emptyState = noResults,
    expandedKeys,
    defaultExpandedKeys,
    onExpandedChange,
    expandable,
    messages,
    groupCounterEnabled,
    ...props
  }: View.RenderProps<FauxTreeProps<T>>) => {
    const {collection, state, options} = useListBoxState({
      data,
      item,
      group,
      children,
      selectionMode,
      onSelectionChange,
      defaultSelectedKeys,
      selectedKeys,
      disabledKeys,
      expandedKeys,
      defaultExpandedKeys,
      onExpandedChange,
      expandable,
    });
    const filter = useCallback(
      (item: ItemDesc) => {
        return Boolean(item?.key && state.collection.getItem(item.key));
      },
      [state],
    );
    const isEmpty = useMemo(
      () => collection.getItems().filter(filter).length === 0,
      [collection, filter],
    );
    const isMultiple = state.selectionManager.selectionMode === 'multiple';
    const ref = React.useRef(null);
    const {listBoxProps} = useListBox(
      {label, shouldFocusWrap: true, ...options},
      state,
      ref,
    );

    return (
      <FauxTreeProvider value={children}>
        <CollectionProvider collection={collection} state={state}>
          <view.div ref={ref} {...props} {...listBoxProps}>
            {isMultiple && selectionAllEnabled ? (
              <FauxTreeSelectAllItem
                selectedText={messages?.selectedText}
                {...(options.selectAllRef
                  ? {selectAllRef: options.selectAllRef}
                  : {})}
              />
            ) : null}
            {collection.render(
              (props) => (
                <FauxTreeItem key={props.id} {...props}>
                  {props.children || props.textValue}
                </FauxTreeItem>
              ),
              (props) => (
                <FauxTreeGroup
                  key={props.id}
                  counterEnabled={groupCounterEnabled}
                  {...props}
                />
              ),
              filter,
            )}
            {isEmpty && emptyState}
          </view.div>
        </CollectionProvider>
      </FauxTreeProvider>
    );
  },
  {
    css: {
      stack: 'y',
      gap: 'space.0',
      backgroundColor: 'surface',
      borderRadius: 'medium',
      keyline: 'border',
      keylineWidth: 'small',
      padding: 'xsmall',
      focusRing: 'none',
    },
  },
);
