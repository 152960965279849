import type {MutableRefObject, RefObject} from 'react';
import {useLayoutEffect} from './useLayoutEffect';

interface ContextValue<T> {
  ref?: MutableRefObject<T>;
}

// Syncs ref from context with ref passed to hook
export function useSyncRef<T>(context: ContextValue<T>, ref: RefObject<T>) {
  useLayoutEffect(() => {
    if (context && context.ref && ref) {
      // @ts-expect-error - Type 'T | null' is not assignable to type 'T'
      context.ref.current = ref.current;
      return () => {
        // @ts-expect-error - Type 'null' is not assignable to type 'T'
        context.ref.current = null;
      };
    }
  });
}
