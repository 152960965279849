import * as React from 'react';
import {useEffect} from 'react';
import type {SectionTransforms, TreeState} from '@sail/react-aria';
import {
  setInteractionModality,
  useCollectionState,
  useId,
  useItem,
  useListBoxSection,
} from '@sail/react-aria';
import type {View} from '@sail/engine';
import {assignProps, view, createViewConfig} from '@sail/engine';
import {chevronDown} from '@sail/icons/react/Icon';
import {Checkbox} from './Checkbox';
import {Counter} from './Counter';
import {Header} from './Header';
import {Icon} from './Icon';
import useFauxTreeState from '../hooks/useFauxTreeState';

export type FauxTreeGroupProps = {
  id?: string;
  /**
   * A heading grouping the menu items.
   */
  title?: React.ReactNode;
  /** Child items that belong to the group. */
  children?: React.ReactNode;

  textValue?: string;

  counterEnabled?: boolean;

  /**
   * An accessibility label for the group.
   */
  'aria-label'?: string;

  subviews?: View.Subviews<{
    header: typeof Header;
    list: 'div';
    // collapsibleIcon: typeof Icon;
  }>;
};

export const FauxTreeGroupTransforms: SectionTransforms<
  FauxTreeGroupProps,
  'id'
> = {
  idField: 'id',
  makeGenericItem(props: FauxTreeGroupProps) {
    return {
      type: 'section',
      'aria-label': props['aria-label'],
      textValue:
        typeof props.title === 'string' ? props.title : props.textValue ?? '',
    };
  },
};

export const FauxTreeGroupConfig = createViewConfig({
  name: 'FauxTreeGroup',
  props: {} as FauxTreeGroupProps,
  defaults: {
    counterEnabled: true,
  },
});

export const FauxTreeGroup = FauxTreeGroupConfig.createView(
  function FauxTreeGroup({
    id,
    children,
    title,
    subviews,
    counterEnabled,
    ...props
  }) {
    const headerRef = React.useRef<HTMLElement>(null);
    const key = useItem(
      id,
      FauxTreeGroupTransforms.makeGenericItem({
        'aria-label': props['aria-label'],
        title,
      }),
    );

    const {
      itemProps,
      headingProps: {id: headingId},
      groupProps,
    } = useListBoxSection({
      heading: title,
      'aria-label': props['aria-label'],
    });

    const state = useCollectionState();

    const tree = state as TreeState;
    const isOpen =
      !tree.expandable ||
      tree.expandedKeys === 'all' ||
      tree.expandedKeys.has(key);

    const {selectGroupItems, isMultipleSelection} = useFauxTreeState();

    const selectGroupItem = selectGroupItems.get(key) ?? {
      selectedCount: 0,
      totalCount: 0,
      selectedForeignCount: 0,
      checkableKeys: [],
      uncheckableKeys: [],
      isDisabled: false,
    };

    const {
      selectedCount,
      totalCount,
      selectedForeignCount,
      checkableKeys,
      uncheckableKeys,
      isDisabled,
    } = selectGroupItem;

    const areAllSelected = totalCount === selectedCount;
    const areAllEnabledSelected =
      checkableKeys.length - selectedForeignCount === selectedCount;
    const hasItemsSelected = selectedCount > 0;

    const groupId = useId();
    const triggerProps = {
      role: 'button',
      'aria-expanded': isOpen,
      'aria-controls': groupId,
    };

    const focusedItem = state.collection.getItem(
      state.selectionManager.focusedKey,
    );

    useEffect(() => {
      if (focusedItem && tree.expandedKeys !== 'all') {
        // expand all parents of the focused item
        let {parentKey} = focusedItem;
        const keysToExpand = new Set<string>();

        while (parentKey) {
          keysToExpand.add(parentKey as string);
          const parent = tree.collection.getItem(parentKey);
          parentKey =
            parentKey === parent?.parentKey ? undefined : parent?.parentKey;
        }
        if (
          Array.from(keysToExpand).some(
            (key) => !(tree.expandedKeys as Set<string>).has(key),
          )
        ) {
          tree.setExpandedKeys(
            new Set([...keysToExpand, ...tree.expandedKeys]),
          );
          setInteractionModality('keyboard');
        }
      }
    }, [tree, focusedItem]);

    // TODO: Sail Checkbox has a visual bug in Chrome when the values of indeterminate and checked are modified in the same render.
    // This is a workaround to avoid it, but this bug must be fixed directly in Checkbox
    const [indeterminate, setIndeterminate] = React.useState(
      hasItemsSelected && !areAllSelected,
    );

    React.useEffect(() => {
      setIndeterminate(hasItemsSelected && !areAllSelected);
    }, [hasItemsSelected, areAllSelected]);

    // TODO: Implement disable for groups

    return (
      <view.div {...props} {...itemProps}>
        {title ? (
          <Header
            id={headingId}
            role="presentation"
            inherits={subviews.header}
            onClick={() => {
              if (tree.expandable && !isMultipleSelection) {
                tree.selectionManager.setFocusedKey(null);
                tree.toggleKey(key);
              }
            }}
            onMouseDown={(e) => {
              // Prevent focus going to the FauxTree when clicking the section header.
              if (headerRef.current?.contains(e.target as Node)) {
                e.preventDefault();
              }
            }}
            uses={[assignProps(tree.expandable ? triggerProps : {})]}
            css={{
              alignItems: 'center',
              cursor:
                tree.expandable && !isMultipleSelection ? 'pointer' : 'default',
            }}
            ref={headerRef}
          >
            {!isMultipleSelection ? (
              <>
                {tree.expandable ? (
                  <Icon
                    size="xsmall"
                    icon={chevronDown}
                    css={{
                      transition: 'transform 150ms',
                      transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                      marginX: 'xxsmall',
                    }}
                  />
                ) : null}
                {title}
              </>
            ) : (
              <>
                <view.div
                  css={{
                    stack: 'x',
                    gap: 'space.75',
                    alignItems: 'center',
                    cursor: !isDisabled ? 'pointer' : 'default',
                  }}
                >
                  <view.div
                    css={{
                      stack: 'x',
                      gap: 'space.75',
                      alignItems: 'center',
                      minHeight: 'space.250',
                    }}
                  >
                    <Checkbox
                      indeterminate={indeterminate}
                      tabIndex={-1}
                      label={title}
                      subviews={{
                        label: {
                          css: {
                            marginLeft: '-2px', // Customize gap between checkbox and label
                            font: 'label.small.emphasized',
                            color: 'subdued',
                            cursor: !isDisabled ? 'pointer' : 'default',
                          },
                        },
                        input: {
                          css: {
                            ':focus': {
                              focusRing: 'none',
                            },
                          },
                        },
                      }}
                      checked={areAllSelected}
                      disabled={isDisabled}
                      onChange={() => {
                        if (areAllEnabledSelected) {
                          state.selectionManager.setSelectedKeys(
                            uncheckableKeys,
                          );
                        } else {
                          state.selectionManager.setSelectedKeys(checkableKeys);
                        }
                      }}
                    />
                  </view.div>
                  {selectedCount > 0 && counterEnabled ? (
                    <Counter data-testid={`region-count-${id}`}>
                      {selectedCount}
                    </Counter>
                  ) : null}
                </view.div>
                {tree.expandable ? (
                  <Icon
                    size="xsmall"
                    icon={chevronDown}
                    css={{
                      transition: 'transform 150ms',
                      transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                      marginX: 'xxsmall',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      tree.selectionManager.setFocusedKey(null);
                      tree.toggleKey(key);
                    }}
                  />
                ) : null}
              </>
            )}
          </Header>
        ) : null}

        <section role="region" id={id} {...groupProps}>
          {isOpen ? children : null}
        </section>
      </view.div>
    );
  },
  {
    css: {
      padding: 'xsmall',
      bleedX: 'xsmall',
      borderWidth: '0',
      borderTopWidth: 'small',
      borderStyle: 'solid',
      borderColor: 'border',

      ':first-of-type': {
        borderTopWidth: '0',
        bleedTop: 'xsmall',
      },

      ':last-of-type': {
        bleedBottom: 'xsmall',
      },
    },
    subviews: {
      header: {
        css: {
          stack: 'x',
          gap: 'space.75',
          distribute: 'packed',
          font: 'label.small.emphasized',
          color: 'subdued',
          paddingY: 'space.75',
          paddingX: 'space.125',
          minHeight: 'xlarge',
          userSelect: 'none',
        },
      },
      list: {css: {stack: 'y'}},
    },
  },
);
