import {
  useRef,
  useMemo,
  useEffect,
  useImperativeHandle,
  useCallback,
} from 'react';
import {
  FocusScope,
  useControlledState,
  useFocusManager,
  useFocusWithin,
  useInteractOutside,
  isElementInChildOfActiveScope,
} from '@sail/react-aria';
import * as React from 'react';
import {
  useFloating,
  useFloatingNodeId,
  shift,
  size,
  flip,
  offset,
  hide,
  inline,
  autoUpdate,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  useFocus,
  useHover,
  safePolygon,
  limitShift,
  FloatingNode,
} from '@floating-ui/react';
import type {
  ElementProps,
  FloatingContext,
  Placement as FloatingPlacement,
} from '@floating-ui/react';
import type {Token} from '@sail/engine';
import {
  createView,
  view,
  useTokenValue,
  assignProps,
  dynamic,
  animate,
  AnimatePresence,
} from '@sail/engine';
import type {FocusManager} from '@sail/react-aria';
import type {View} from '../../view';
import {css} from '../../css';
import {Layer} from '../layers/Layer';
import {tokens} from '../../tokens';
import {toFloatingPlacement} from '../utils/toFloatingPlacement';
import {useProps} from '../../hooks/useProps';
import {useNestedSheets} from '../../hooks/useNestedSheets';
import type {PopoverProps} from './PopoverProps';
import {replaceDeprecatedProps, moveFocus, FocusGuard} from './utils';

/**
 * Popover option props have either a boolean value, or an object with options.
 * This helper function returns a tuple of the boolean value indicating whether the option is enabled,
 * and the option object itself, defaulting to `{}` if not provided.
 */
const option = <T,>(
  value: boolean | T | undefined,
): [boolean | undefined, T] => {
  if (value === undefined) {
    return [undefined, {} as T];
  }
  if (typeof value === 'boolean') {
    return [value, {} as T];
  }
  return [true, value];
};

/**
 * A portal-friendly version of react-aria's `useInteractOutside` hook, allowing
 * the popover to be dismissed when focus moves outside the popover.
 *
 * `useInteractOutside` uses DOM event delegation to detect clicks outside the popover,
 * but this isn't safe for portalled elements because the event target may not be
 * a direct child of the popover.
 *
 * This hook uses the React tree (instead of the DOM tree) to detect when focus moves
 * outside the popover.
 */
const useFocusOutside = (
  callback: () => void,
  targetElement?: HTMLElement | null,
): Pick<React.HTMLProps<Element>, 'onFocus' | 'onBlur'> => {
  const focusMovedRef = useRef(false);
  const timeoutRef = React.useRef<number>();
  useEffect(() => () => cancelAnimationFrame(timeoutRef.current as number), []);

  return useMemo(
    () => ({
      onFocus() {
        focusMovedRef.current = true;
      },
      // blur on the current element fires before focus on the next element,
      // so we use that to determine if the focus is moving outside the popover
      onBlur(e) {
        focusMovedRef.current = false;

        // Ignore blur events default prevented by children
        if (e.defaultPrevented) {
          return;
        }

        cancelAnimationFrame(timeoutRef.current as number);

        const relatedTarget = e.relatedTarget as HTMLElement;

        const isFocusWithinCurrentTarget =
          e.currentTarget?.contains(relatedTarget) ?? false;

        /**
         * In some cases trigger element might not be inside the current target
         */
        const isFocusWithinTargetElement =
          targetElement?.contains(relatedTarget) ?? false;

        const isFocusWithin =
          isFocusWithinCurrentTarget || isFocusWithinTargetElement;

        /**
         * preventDefault onBlur event if focus is within targeted elements,
         * so parent Popovers don't close on internal focus changes
         */
        if (isFocusWithin) {
          e.preventDefault();
        }

        // Give browser time to focus the next element
        timeoutRef.current = requestAnimationFrame(() => {
          // Check if the new focused element is a child of the original container
          if (!focusMovedRef.current && !isFocusWithin) {
            callback();
          }
        });
      },
    }),
    [callback, targetElement],
  );
};

const useDurationValue = <T,>(token: Token.Value<T>) => {
  return parseInt(useTokenValue(token) as string, 10);
};

const useReactAriaFocusOutside = (
  context: FloatingContext<HTMLDivElement>,
  {enabled = false}: {enabled?: boolean},
): ElementProps => {
  const {
    refs,
    open: isOpen,
    onOpenChange,
    elements: {domReference, floating},
  } = context;

  const isElementWithin = useCallback(
    (element: Element) =>
      domReference?.contains(element) || floating?.contains(element),
    [domReference, floating],
  );

  /**
   * This ref keeps track of whether focus is within the overlay or not.
   * It needs to prevent the overlay from closing when focus moves to a child focus scope.
   * Example: Popover -> Popover -> Button, moving focus to Button should not close the popover.
   */
  const isFocusWithinRef = useRef(false);
  const timeoutRef = useRef<number>();
  useEffect(() => () => cancelAnimationFrame(timeoutRef.current as number), []);

  const onInteractOutside = useCallback(
    (e: React.SyntheticEvent) => {
      /**
       * Do not close the overlay if focus is within the overlay.
       */
      if (isFocusWithinRef.current) {
        return;
      }

      const isElementWithinPopover = isElementWithin(e.target as Element);

      if (!isElementWithinPopover) {
        onOpenChange(false, e.nativeEvent);
      }
    },
    [isElementWithin, onOpenChange],
  );

  // Handle clicking outside the overlay to close it
  useInteractOutside({
    ref: refs.floating,
    onInteractOutside: enabled && isOpen ? onInteractOutside : undefined,
  });

  const {focusWithinProps} = useFocusWithin({
    isDisabled: !enabled,
    onFocusWithinChange: (isFocusWithin) => {
      isFocusWithinRef.current = isFocusWithin;
    },
    onBlurWithin: (e) => {
      const relatedTarget = e.relatedTarget as Element | null;
      // Do not close if relatedTarget is null, which means focus is lost to the body.
      // That can happen when switching tabs, or due to a VoiceOver/Chrome bug with Control+Option+Arrow navigation.
      // Clicking on the body to close the overlay should already be handled by useInteractOutside.
      // https://github.com/adobe/react-spectrum/issues/4130
      // https://github.com/adobe/react-spectrum/issues/4922
      //
      // If focus is moving into a child focus scope (e.g. menu inside a dialog),
      // do not close the outer overlay. At this point, the active scope should
      // still be the outer overlay, since blur events run before focus.
      if (!relatedTarget || isElementInChildOfActiveScope(relatedTarget)) {
        return;
      }

      isFocusWithinRef.current = false;

      cancelAnimationFrame(timeoutRef.current as number);

      const isRelatedTargetWithin = isElementWithin(relatedTarget);

      timeoutRef.current = requestAnimationFrame(() => {
        const isFocusWithin = isFocusWithinRef.current;

        if (!isFocusWithin && !isRelatedTargetWithin) {
          onOpenChange(false, e.nativeEvent);
        }
      });
    },
  });

  return useMemo(() => {
    if (!enabled || !isOpen) {
      return {};
    }

    return {
      floating: focusWithinProps,
      reference: focusWithinProps,
    };
  }, [enabled, isOpen, focusWithinProps]);
};

const translate = (placement: FloatingPlacement) => {
  /* eslint-disable no-nested-ternary */
  const [attachedSide] = placement.split('-');
  const offset = `${tokens.space[150].toString()}`;
  const negativeOffset = `calc(-1 * ${offset})`;
  return `translate(${
    attachedSide === 'left'
      ? offset
      : attachedSide === 'right'
      ? negativeOffset
      : '0'
  }, ${
    attachedSide === 'top'
      ? offset
      : attachedSide === 'bottom'
      ? negativeOffset
      : '0'
  })`;
};

export const DesktopPopoverLayer = createView(
  (allProps: View.RenderProps<PopoverProps>) => {
    const {
      targetRef,
      placement: reactAriaPlacement,
      offset: offsetOptions,
      flip: flipEnabled,
      shift: shouldShift,
      size: sizeOptions,
      inline: inlineEnabled,
      hide: hideOptions,
      onClose,
      subviews,
      dismissible,
      showOnPress,
      showOnFocus,
      showOnHover,
      role,
      defaultOpen,
      open: controlledOpen,
      mobileTitle,
      onOpenChange: setControlledOpen,
      floatingStrategy,
      animate: shouldAnimate = true,
      useReactAriaDismissOutside = false,
      ...props
    } = replaceDeprecatedProps(allProps);

    const [open, setOpen] = useControlledState(
      // Here we default the controlled state to `true` if neither `open` nor `defaultOpen` are provided.
      // This provides backwards compatibility by continuing to call `onClose` events but not automatically
      // hiding the popover. This feels a little odd as you might reasonably expect the popover to be
      // uncontrolled by default, but it's the best we can do without breaking backwards compatibility. The
      // drawback is that you must explicitly provide the `defaultOpen` prop if you want the popover to be
      // uncontrolled.
      controlledOpen ?? (defaultOpen === undefined ? true : undefined),
      defaultOpen,
      setControlledOpen,
    );

    const padding = parseInt(useTokenValue(tokens.space.xsmall), 10);
    const placement = toFloatingPlacement(reactAriaPlacement);

    const [shiftEnabled, shiftOptions] = option(shouldShift);
    // eslint-disable-next-line prefer-const
    let [dismissEnabled, dismissOptions] = option(dismissible);
    const [clickEnabled, clickOptions] = option(showOnPress);
    const [focusEnabled, focusOptions] = option(showOnFocus);
    const [hoverEnabled, hoverOptions] = option(showOnHover);

    const onOpenChange = useCallback(
      (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
          // backwards compatibility for onClose
          onClose?.();
        }
      },
      [setOpen, onClose],
    );

    const nodeId = useFloatingNodeId();

    const floating = useFloating<HTMLDivElement>({
      nodeId,
      placement,
      strategy: floatingStrategy,
      whileElementsMounted: autoUpdate,
      middleware: [
        inlineEnabled && inline(),
        offset(offsetOptions ?? padding),
        hideOptions?.referenceHidden && hide(),
        hide({strategy: 'escaped'}),
        flip(({middlewareData}) => ({
          mainAxis: flipEnabled,
          crossAxis: flipEnabled,
          altBoundary: middlewareData?.hide?.escaped,
          /**
           * The `bestFit` strategy doesn't play nicely when comparing
           * overflows that are very close to each other (e.g. less than 1px difference).
           * When paired with the `altBoundary` option, this can cause the popover to
           * flip into a position that is clipped by the viewport.
           */
          fallbackStrategy: middlewareData?.hide?.escaped
            ? 'initialPlacement'
            : 'bestFit',
        })),
        size({
          padding: sizeOptions?.padding || padding,
          altBoundary: sizeOptions?.altBoundary,
          apply({availableHeight, availableWidth, elements, rects}) {
            // intentionally using style here to avoid generating a new class
            // for each pixel value of width|max{width|height}.
            const style = elements.floating?.style || {};
            if (sizeOptions?.maxWidth) {
              Object.assign(style, {maxWidth: `${availableWidth}px`});
            }
            if (sizeOptions?.maxHeight) {
              Object.assign(style, {maxHeight: `${availableHeight}px`});
            }
            if (sizeOptions?.matchWidth) {
              Object.assign(style, {width: `${rects.reference.width}px`});
            }
          },
        }),
        shift({
          mainAxis: shiftEnabled !== false,
          padding: shiftOptions.padding || padding,
          altBoundary: shiftOptions.altBoundary,
          limiter: limitShift(),
        }),
      ],
      open,
      onOpenChange,
    });

    const {refs, floatingStyles, context, middlewareData} = floating;

    const scope = useRef<FocusManager>(null);

    const shouldDismissOnFocusOutside =
      dismissEnabled && dismissOptions.outsideFocus !== false;

    const dismissOnFocusOutside = useFocusOutside(
      useCallback(() => {
        if (!useReactAriaDismissOutside && shouldDismissOnFocusOutside) {
          onOpenChange(false);
        }
      }, [
        onOpenChange,
        shouldDismissOnFocusOutside,
        useReactAriaDismissOutside,
      ]),
      targetRef.current,
    );

    const interactions = useInteractions([
      useRole(context, {
        enabled: Boolean(role),
        role,
      }),
      useDismiss(context, {
        enabled: dismissEnabled,
        escapeKey: dismissOptions.escapeKey,
        outsidePress: dismissOptions.outsidePress,
        // outsidePressEvent needs to be set to 'click' to ensure compatibility with
        // react-aria's Pressable implementation.
        outsidePressEvent: 'click',
        bubbles: dismissOptions.bubbles,
      }),
      useClick(context, {
        enabled: clickEnabled,
        toggle: clickOptions.toggle,
      }),
      useFocus(context, {
        enabled: focusEnabled,
        keyboardOnly: focusOptions.visibleOnly,
      }),
      useHover(context, {
        enabled: hoverEnabled,
        delay: hoverOptions.delay,
        restMs: hoverOptions.restMs,
        handleClose: safePolygon(),
      }),
      // wire up the reference element to dismiss the popover when it loses focus.
      // This logic is only necessary because the `useDismiss` hook relies on
      // event delegation and react-aria "pressable" elements don't propagate mouse/touch events.
      {reference: dismissOnFocusOutside, floating: dismissOnFocusOutside},
      useReactAriaFocusOutside(context, {
        enabled: useReactAriaDismissOutside && shouldDismissOnFocusOutside,
      }),
    ]);

    useProps(targetRef, interactions.getReferenceProps());

    // set up refs for the trigger element for floating-ui
    useEffect(() => {
      // floating UI will call `calculatePosition` as long as it has both a floating and reference element
      // we can clear this reference (to avoid calculating position) if switching to mobile
      refs.setReference(targetRef.current);
    }, [targetRef, refs]);

    const easing = useTokenValue(tokens.animation.easing) as string;
    const medium = useDurationValue(tokens.animation.duration.medium);
    const short = useDurationValue(tokens.animation.duration.short);
    const ref = React.useRef<HTMLDivElement>(null);

    const layer = (
      <Layer
        inherits={subviews.layer}
        uses={[
          assignProps(
            interactions.getFloatingProps() as React.HTMLAttributes<HTMLDivElement>,
          ),
        ]}
        ref={(el: HTMLDivElement) => {
          // don't run positioning logic within JSDOM environment (speeds up tests)
          // JSDOM doesn't support `getBoundingClientRect` anyway, so positioning logic
          // wouldn't be correct there anyway.
          if (typeof jest === 'undefined') {
            refs.setFloating(el);
          }
        }}
        style={floatingStyles}
        css={{
          visibility:
            hideOptions?.referenceHidden && middlewareData.hide?.referenceHidden
              ? 'hidden'
              : undefined,
        }}
      >
        {/* used to coordinate nested popovers */}
        <FloatingNode id={nodeId}>
          <FocusScope restoreFocus>
            <view.div
              // declare this before spreading props so user's test id wins out, but we can
              // still use this test id for sail next tests
              data-testid="popover-layer"
              {...props}
              css={sizeOptions?.maxHeight ? {overflow: 'auto'} : undefined}
              ref={ref}
              uses={[
                dynamic(() => {
                  const manager = useFocusManager();
                  useImperativeHandle(scope, () => manager);
                }),
                dynamic(() => {
                  const {sheetStackProvider} = useNestedSheets(
                    ref,
                    'bottom-sheet',
                  );
                  return [sheetStackProvider];
                }),
                ...(shouldAnimate
                  ? [
                      animate.in({
                        opacity: 0,
                        transform: `scale(0.95) ${translate(
                          floating.placement,
                        )}`,
                        easing,
                        duration: medium,
                      }),
                      animate.out({
                        opacity: 0,
                        transform: `scale(0.95) ${translate(
                          floating.placement,
                        )}`,
                        easing,
                        duration: short,
                      }),
                    ]
                  : []),
                css({maxHeight: 'inherit'}),
              ]}
            >
              {props.children}
            </view.div>
          </FocusScope>
        </FloatingNode>
      </Layer>
    );

    return (
      <>
        {/* focus guards to allow focus to move between the page and the popover */}
        {open ? <FocusGuard onFocus={moveFocus(scope, 'next')} /> : null}
        <AnimatePresence>{context.open ? layer : null}</AnimatePresence>
        {open ? <FocusGuard onFocus={moveFocus(scope, 'prev')} /> : null}
      </>
    );
  },
  {
    css: {
      backgroundColor: 'surface',
      border: '1px solid',
      borderColor: 'border',
      borderRadius: 'medium',
      boxShadow: 'overlay',
      maxHeight: 'inherit',
    },
  },
);
