// This file was generated using `pay js:run gql-v2 --type=mutation --name=UpdateDeviceStatus --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  DeviceInput,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation UpdateDeviceStatusMutation($deviceData: DeviceInput!) {
    updateDeviceStatus(deviceData: $deviceData) {
      id
      ...SessionDynamicFragment
    }
  }

  ${sessionDynamicFragment}
`;

export const updateDeviceStatusMutation: UpdateDeviceStatusMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const updateDeviceStatusMutationOptions: GelatoMutationOptionsWithoutVariables<UpdateDeviceStatusMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useUpdateDeviceStatusMutation(
  options: GelatoMutationOptions<UpdateDeviceStatusMutationDocument> = {},
): GelatoMutationReturn<UpdateDeviceStatusMutationDocument> {
  return useGelatoMutation(updateDeviceStatusMutation, {
    ...updateDeviceStatusMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type UpdateDeviceStatusMutationVariables = Exact<{
  deviceData: DeviceInput;
}>;

export type UpdateDeviceStatusMutationData = {
  readonly __typename: 'Mutation';
  readonly updateDeviceStatus: GraphQlFragmentUnion<
    {readonly __typename: 'Session'; readonly id: string},
    [SessionDynamicFragmentData]
  >;
};

const updateDeviceStatusMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = updateDeviceStatusMutationPermissions;

export type UpdateDeviceStatusMutationPermissions = GelatoPermissionToken<
  typeof updateDeviceStatusMutationPermissions
>;

export type UpdateDeviceStatusMutationDocument = GraphQlDocument<
  UpdateDeviceStatusMutationData,
  UpdateDeviceStatusMutationVariables,
  UpdateDeviceStatusMutationPermissions
>;
