import {dynamic, Style} from '@sail/engine';
import {css} from '../css';
import {Font} from '../properties';
import {
  hasBaselineLayout,
  objectHeight,
  paddingBottomProp,
  paddingTopProp,
} from '../plugins';
import {
  ascenderProp,
  hasBaselineFont,
  lineHeightProp,
} from '../custom-properties/font';
import {
  getAlphabeticBaseline,
  getReferenceBaseline,
  getTargetBaseline,
} from './font-utils';
import {useUiConfig} from '../../providers/UiConfigProvider';

const set = {
  var(property: Style.CustomProperty, fallback?: string): string {
    const propertyName = property.intent.toString() as `--${string}`;
    return typeof fallback === 'undefined'
      ? `var(${propertyName})`
      : `var(${propertyName}, ${fallback})`;
  },
} as Style.PluginAPI;

type BaselineProperties = {
  marginBottom?: string;
  marginTop?: string;
  transform?: string;
};

function baselinePlugin(
  value: string,
  set: Style.PluginAPI,
): BaselineProperties {
  const inheritedBaseline = getAlphabeticBaseline(set, 'inherited');
  const currentBaseline = getAlphabeticBaseline(set, 'current');
  const baselineDiff = `(${inheritedBaseline} - ${set.var(
    objectHeight,
    currentBaseline,
  )})`;
  const multiplier = `${set.var(hasBaselineLayout)} * ${set.var(
    hasBaselineFont,
  )} * clamp(0, 10000 * ${set.var(ascenderProp.inherited)}, 1)`;
  function calc(value: string) {
    return `calc((${value}) * ${multiplier})`;
  }

  const marginTop = calc(
    `${baselineDiff} - ${set.var(paddingTopProp)} - 0.5px`,
  );

  const inheritedLineHeight = set.var(lineHeightProp.inherited);
  const currentLineHeight = set.var(lineHeightProp.current);
  const paddingBottom = set.var(paddingBottomProp);
  const marginBottom = `max(0, ${calc(
    `${inheritedLineHeight} - ${currentLineHeight} - ${baselineDiff} - ${paddingBottom}`,
  )})`;

  // eslint-disable-next-line prefer-const
  let [reference = 'alphabetic', target] = value.trim().split(' ');

  if (!target) {
    switch (reference) {
      case 'cap-middle':
      case 'x-middle':
      case 'x-height':
        target = 'cap-middle';
        break;
      default:
        target = 'alphabetic';
        break;
    }
  }

  const referenceBaseline = getReferenceBaseline(set, reference, 'inherited');
  const targetBaseline = getTargetBaseline(set, target);
  if (!referenceBaseline || !targetBaseline) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(`Invalid baseline "${value}"`);
    }
    return {
      marginBottom: undefined,
      marginTop: undefined,
      transform: undefined,
    };
  }

  const transformY = calc(`${targetBaseline} - ${referenceBaseline}`);

  return {
    marginBottom,
    marginTop,
    transform: `translate(0, ${transformY})`,
  };
}

const cache = new Map<string, BaselineProperties>();

export const baseline = {
  css: (value: Font.Baseline) => {
    return dynamic(() => {
      const uiConfig = useUiConfig();

      return [
        uiConfig?.enableBaselineIntent
          ? css(baseline.props(value))
          : css({baseline: value}),
      ];
    });
  },
  props: (value: Font.Baseline) => {
    if (!cache.has(value)) {
      cache.set(value, baselinePlugin(value, set));
    }

    return cache.get(value)!;
  },
};
