import {useContext} from 'react';
import ObservabilityConfigContext from 'src/internal/config/ObservabilityConfigContext';

import type {ObservabilityConfig} from 'src/types';

/**
 * React hook to access the observability configuration provided by the
 * the closest `<ObservabilityProvider />`.
 *
 * The configuration provided by the closest `<ObservabilityProvider />`
 * is the result of merging the configuration provided by the closest
 * `<ObservabilityProvider />` with the configuration provided by its
 * parent providers.
 *
 * @see https://sail.stripe.me/apis/observability/useObservabilityConfig
 */
export default function useObservabilityConfig(): ObservabilityConfig {
  return useContext(ObservabilityConfigContext);
}
