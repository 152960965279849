import {UA_PATTERNS, APP_VERSION_PATTERN} from './botConfig';

let pattern: RegExp | null = null;

function getUAPattern(): RegExp {
  if (pattern) {
    return pattern;
  }
  pattern = new RegExp(new RegExp(UA_PATTERNS.join('|'), 'i'), 'i');
  return pattern;
}

function isBotUserAgent(userAgent: string): boolean {
  if (!userAgent) {
    return false;
  }
  try {
    return getUAPattern().test(userAgent);
  } catch (err) {
    // Some old browsers may not support the regular expressions we are using,
    // such as negative lookbehind ((?<! )) in old versions of Safari
    return false;
  }
}

function isBotAppVersion(appVersion: string): boolean {
  if (!appVersion) {
    return false;
  }
  return APP_VERSION_PATTERN.test(appVersion);
}

function isBotWebGLSignal(): boolean {
  const canvasElement = document.createElement('canvas');
  if (typeof canvasElement.getContext !== 'function') {
    return false;
  }
  const webGLContext = canvasElement.getContext('webgl');
  if (webGLContext === null) {
    return false;
  }
  if (typeof webGLContext.getParameter !== 'function') {
    return false;
  }
  const vendor = webGLContext.getParameter(webGLContext.VENDOR);
  const renderer = webGLContext.getParameter(webGLContext.RENDERER);
  return vendor === 'Brian Paul' && renderer === 'Mesa OffScreen';
}

let cachedIsBotResult: boolean | null = null;

export function isBot(
  {
    userAgent,
    appVersion,
  }: {
    userAgent: string;
    appVersion: string;
  } = navigator,
  {cache}: {cache: boolean} = {cache: true},
): boolean {
  if (cache && cachedIsBotResult !== null) {
    return cachedIsBotResult;
  }
  const result =
    isBotUserAgent(userAgent) ||
    isBotAppVersion(appVersion) ||
    isBotWebGLSignal();
  if (cache) {
    cachedIsBotResult = result;
  }
  return result;
}
