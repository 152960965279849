import {Style} from '@sail/engine';
import {
  ascenderProp,
  capHeightProp,
  descenderProp,
  FontProperties,
  fontSizeProp,
  lineHeightProp,
  xHeightProp,
} from '../custom-properties/font';

export function getFontMetric(
  set: Style.PluginAPI,
  property: FontProperties,
  key: 'current' | 'inherited' = 'current',
) {
  const fontSize = set.var(fontSizeProp[key]);
  const metric = set.var(property[key]);
  return `calc(${fontSize} * ${metric})`;
}

export function getReferenceBaseline(
  set: Style.PluginAPI,
  value: string,
  key: 'current' | 'inherited',
) {
  switch (value) {
    case 'cap-middle':
      return `calc(${getFontMetric(set, capHeightProp, key)} / 2)`;
    case 'x-middle':
      return `calc(${getFontMetric(set, xHeightProp, key)} / 2)`;
    case 'x-height':
      return getFontMetric(set, xHeightProp, key);
    case 'alphabetic':
    default:
      return '0px';
  }
}

export function getTargetBaseline(set: Style.PluginAPI, value: string) {
  switch (value) {
    case 'object-bottom':
      return '0%';
    case 'object-middle':
      return '50%';
    case 'object-top':
      return '100%';
    default:
      return getReferenceBaseline(set, value, 'current');
  }
}

export function getAlphabeticBaseline(
  set: Style.PluginAPI,
  key: 'current' | 'inherited',
): string {
  const ascender = getFontMetric(set, ascenderProp, key);
  const descender = getFontMetric(set, descenderProp, key);
  const lineHeight = set.var(lineHeightProp[key]);
  return `calc((${ascender} + ${descender} + ${lineHeight}) / 2)`;
}
