import type {InternalOperationContextObservability} from 'src/internal/apollo/types/helpers';
import type {
  DataAnalyticsReport,
  DataErrorReport,
  DataMetricReport,
} from 'src/internal/telemetry/types';

export default function logHttp(
  type: 'GraphQL' | 'Fetch',
  observabilityFns: InternalOperationContextObservability,
  analyticsParams: DataAnalyticsReport,
) {
  const {analytics, errors, metrics} = observabilityFns || {};

  // TODO(mjimenez): SDK-PARAMS (remove)
  const page = '<unknown>'; // This should be `useRoute()[0].name`.

  const metricTags: DataMetricReport = {
    service: analyticsParams.service,
    project: analyticsParams.project,
    component: analyticsParams.component,
    // TODO(mjimenez): SDK-PARAMS (remove)
    page,
    // TODO(mjimenez): SDK-PARAMS (remove)
    owner: analyticsParams.project,

    error_expected: analyticsParams.errorExpected,
    error_muted: analyticsParams.errorMuted,
    error_source: analyticsParams.errorSource,

    operation_name: analyticsParams.operationName,
    operation_tag: analyticsParams.operationTag,
    operation_deduplicate: analyticsParams.operationDeduplicate,
    operation_prefetch: analyticsParams.operationPrefetch,
    operation_prefetch_hit: analyticsParams.operationPrefetchHit,
  };

  // Report to Splunk and SFX.
  metrics?.increment('frontend.data.error', metricTags);
  analytics?.track('frontend.data.error', analyticsParams);

  // Report to Sentry.
  if (!analyticsParams.errorExpected && !analyticsParams.operationPrefetch) {
    const project = analyticsParams.project;
    const muted = analyticsParams.errorMuted;
    const errorMessage = `${type} error '${analyticsParams.errorCode}' on operation '${analyticsParams.operationName}'`;
    const method = muted ? errors.warning : errors.error;

    if (project !== '<unknown>') {
      const errorReportExtras: DataErrorReport = {
        ...analyticsParams,
        page,
      };

      method(errorMessage, {
        project,
        extras: errorReportExtras,
      });
    }
  }
}
