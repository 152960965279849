import * as React from 'react';
import type {RequestIframe} from '../iframe-overrides/types';
import type {useMatchMediaReturnType} from '../components/hooks/useMatchMedia/useRpcMatchMedia';

type Report = (
  error: Error,
  scope?: {
    tags?: Record<string, string>;
    extras?: Record<string, unknown>;
  },
) => void;

export type UiConfig = Readonly<{
  // We cannot import '@sail/observability' from '@sail/ui' since
  // '@sail/observability' is not versioned so we have to inject it externally.
  _internal_observability?: Readonly<{
    reports?: Readonly<{
      error: Report;
      warning: Report;
    }>;
    analytics?: Readonly<{
      track: (eventName: string, parameters?: Record<string, unknown>) => void;
    }>;
  }>;
  // The bottom sheet feature is disabled by default while it's being tested.
  enableBottomSheets?: boolean;
  // Enable multiple selection in Sail UI SelectField and SearchField components
  enableSailUiMultipleSelection?: boolean;
  // Enable the "baseline" intent for baseline alignment instead of using the CSS plugin.
  // This allows incremental rollout of the baseline alignment intent.
  enableBaselineIntent?: boolean;
  // Configuration options to apply iframe overrides
  _unstable_getUILayers?: () => Window[];
  _unstable_requestIframe?: RequestIframe;
  _unstable_shouldSkipIframePortal?: boolean;
  // Disable stable popover and use deprecated instead. Only used in Connect.
  disableStablePopover?: boolean;
  isSmallScreen?: useMatchMediaReturnType;
  isTouch?: useMatchMediaReturnType;
}>;

const UiConfigContext = React.createContext<UiConfig | null>(null);

export function useUiConfig(): UiConfig | null {
  return React.useContext(UiConfigContext);
}

export const UiConfigProvider: React.FC<{config: UiConfig}> = ({
  config,
  children,
}) => {
  const parentConfig = useUiConfig();
  const mergedConfig = React.useMemo(
    () => ({
      ...(parentConfig ?? {}),
      ...config,
    }),
    [parentConfig, config],
  );

  return (
    <UiConfigContext.Provider value={mergedConfig}>
      {children}
    </UiConfigContext.Provider>
  );
};
