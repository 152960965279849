import {arrowLeft, more} from '@sail/icons/react/Icon';
import {Icon, StripeLogo, css, view, tokens} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import DocumentManualCaptureToggle from 'gelato/frontend/src/components/DocumentManualCaptureToggle';
import DocumentUploadResetConfirmSheet from 'gelato/frontend/src/components/DocumentUploadResetConfirmSheet';
import {LINK_GREEN} from 'gelato/frontend/src/components/Link/constants';
import LinkLogo from 'gelato/frontend/src/components/Link/LinkLogo';
import Logo from 'gelato/frontend/src/components/Logo';
import Message from 'gelato/frontend/src/components/Message';
import SideMenuSheet from 'gelato/frontend/src/components/SideMenuSheet';
import {
  allReset,
  ellipsis,
  hidden,
} from 'gelato/frontend/src/components/stylesV2';
import {
  InputMethod,
  UserStep,
} from 'gelato/frontend/src/controllers/states/DocumentState';
import computeProgressValue from 'gelato/frontend/src/lib/computeProgressValue';
import experiments from 'gelato/frontend/src/lib/experiments';
import flags from 'gelato/frontend/src/lib/flags';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import useBrowserHistory from 'gelato/frontend/src/lib/hooks/useBrowserHistory';

const Messages = defineMessages({
  documentBackSideCaption: {
    defaultMessage: 'Back of ID',
    description: 'Title for the capturing the back side of document image.',
    id: 'verification.topNavigationBar.documentBackSideCaption',
  },
  documentFrontSideCaption: {
    defaultMessage: 'Front of ID',
    description: 'Title for the capturing the front side of document image.',
    id: 'verification.topNavigationBar.documentFrontSideCaption',
  },
});

const Styles = {
  iconButton: css({
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'block',
    height: 'medium',
    width: 'medium',
    textAlign: 'center',
    stack: 'x',
    alignX: 'center',
    alignY: 'center',
  }),
  caption: css({
    // "stack" needs to be defined before the other styles otherwise "alignY"
    // does noyt work.
    stack: 'x',
    alignX: 'center',
    alignY: 'center',
    bottom: 'space.0',
    gap: 'xsmall',
  }),
  brandCaptionText: css({
    maxWidth: '240px',
    minWidth: 'space.0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  brandCaptionIcon: css({
    marginX: 'space.0',
  }),
  col: css({
    // "stack" needs to be defined before the other styles otherwise "alignY"
    // does noyt work.
    stack: 'y',
    alignX: 'center',
    alignY: 'center',
    gap: 'small',
    paddingY: '5px',
    height: '30px',
  }),
  colCenter: css({
    width: 'fill',
  }),
  icon: css({
    '.icon-button-container :active:hover&': {
      fill: 'action.primary.disabled',
    },
    '.icon-button-container :hover&': {
      fill: 'action.primary.disabled',
    },
    fill: 'subdued',
  }),
  progressBarContainer: css({paddingX: 'large'}),
  progressBar: css({
    backgroundColor: 'offset',
    borderRadius: '3px',
    height: 'small',
    // This is needed to make up the space created by the
    // `transform: scaleY(0.5)` that makes the progress bar thinner.
    marginBottom: '-1px',
    overflow: 'hidden',
    transform: 'scaleY(0.5)',
  }),
  progressBarValue: css({
    backgroundColor: 'brand',
    borderRadius: '3px',
    height: 'fill',
    transition: 'width 300ms ease-in',
  }),
  topNavigationBar: css({
    // "stack" needs to be defined before the other styles otherwise "alignY"
    // does not work.
    stack: 'x',
    alignX: 'start',
    alignY: 'top',
    font: 'label.medium.emphasized',
    backgroundColor: 'white',
    padding: 'space.150',
    paddingX: 'large',
    position: 'relative',
    zIndex: 0,
  }),
};

const Spacer = () => <view.div css={{display: 'block', width: 'medium'}} />;

const PagesWithoutBackButton = [
  /\/success/,
  /\/phone_verification/,
  /\/email_verification/,
  /\/individual/,
];

const PagesWithoutProgressBar = [/\/invalid/];

function goBack() {
  window.history.go(-1);
}

function BackButton() {
  const {appController, appState} = useAppController();
  const history = useBrowserHistory();

  const path = appController.runtime?.router.currentPath || '';
  const {workingStep, workingSide} = appState.document;

  let handleClick;

  if (PagesWithoutBackButton.some((page) => page.test(path))) {
    return <Spacer />;
  }

  // history includes current page, so we need to check for more
  // than just the current page.
  const hasHistory = history.length > 1;

  if (/\/document_upload/.test(path)) {
    // Document upload flow.
    if (
      workingStep === UserStep.warmup &&
      workingSide === 'front' &&
      // only goBack when there's history to go back
      hasHistory
    ) {
      // User could exit the document upload flow and go to the previous page.
      // at the first warm up step.
      handleClick = goBack;
    } else if (workingStep === UserStep.reviewImage && workingSide === 'back') {
      // User could exit the document upload flow and go to the previous page.
      handleClick = () => {
        appController.openLayer(DocumentUploadResetConfirmSheet);
      };
    }
  } else if (hasHistory) {
    handleClick = goBack;
  }

  const disabled = !handleClick;

  const buttonStyles = [allReset, Styles.iconButton];
  if (disabled) {
    buttonStyles.push(hidden);
  }

  return (
    <view.button
      data-testid="TopNavigationBar--BackButton"
      disabled={disabled}
      onClick={handleClick}
      uses={buttonStyles}
      className="icon-button-container"
    >
      <Icon icon={arrowLeft} uses={[Styles.icon]} />
    </view.button>
  );
}

function SideMenuButton(props: {
  // Optional action to execute when the side menu button is clicked.
  sideMenuAction?: (() => void) | null | undefined;
}): JSX.Element | null {
  const {sideMenuAction} = props;
  const {appController} = useAppController();

  const path = appController.runtime?.router.currentPath || '';

  let handleAction: (() => void) | null | undefined;

  if (sideMenuAction) {
    handleAction = sideMenuAction;
  } else if (
    experiments.isActive('document_upload_page_v2') || // M2
    experiments.isActive('butter_m3') || // M3
    experiments.isActive('butter_m4_1_end_pages') // M4
  ) {
    // Hide the side menu button after M2
    // We shouldn't need a check for M4 as we assume that M2 should be fully launched.
    // This additional check is just for our tests.
    handleAction = null;
  } else if (/\/verify_auth/.test(path)) {
    // We don't need side menu for this page.
    handleAction = null;
  } else {
    // Default side menu sheet. This block will be removed once Butter is fully
    // launched.
    handleAction = () => appController.openLayer(SideMenuSheet);
  }

  if (!handleAction) {
    return <Spacer />;
  }

  return (
    <view.button
      data-testid="side-menu-button"
      className="icon-button-container"
      uses={[allReset, Styles.iconButton]}
      onClick={handleAction}
    >
      <Icon icon={more} uses={[Styles.icon]} />
    </view.button>
  );
}

/**
 * The caption component.
 */
function Caption(): JSX.Element {
  const {appController} = useAppController();
  const path = appController.runtime?.router.currentPath || '';
  if (/\/document_upload/.test(path)) {
    return <DocumentUploadCaption />;
  } else if (/\/face_upload/.test(path)) {
    return <FaceUploadCaption />;
  } else if (
    /\/link_reuse|link_otp/.test(path) &&
    flags.isActive('idprod_ni_implementation_review')
  ) {
    return <LinkCaption />;
  } else {
    return <BrandCaption />;
  }
}

function LinkCaption(): JSX.Element {
  return (
    <view.div uses={[Styles.caption]}>
      <LinkLogo />
    </view.div>
  );
}

/**
 * The caption component for the document upload.
 */
function DocumentUploadCaption(): JSX.Element {
  const {appState} = useAppController();
  const {workingSide, workingStep, workingInputMethod} = appState.document;

  if (workingStep === 'warmup' && workingSide === 'front') {
    // The first warm up step still shows the brand logo.
    return <BrandCaption />;
  }

  let content;
  if (
    workingStep === 'collect_image' &&
    appState.camera.ready &&
    appState.document.autoCaptureIsSupported &&
    (workingInputMethod === InputMethod.cameraAutoCapture ||
      workingInputMethod === InputMethod.cameraManualCapture)
  ) {
    content = <DocumentManualCaptureToggle />;
  } else if (workingSide === 'back') {
    content = <Message {...Messages.documentBackSideCaption} />;
  } else {
    content = <Message {...Messages.documentFrontSideCaption} />;
  }

  return <view.div uses={[Styles.caption]}>{content}</view.div>;
}

/**
 * The caption component for the face upload.
 */
function FaceUploadCaption(): JSX.Element {
  return <view.div uses={[Styles.caption]} />;
}

/**
 * The caption component for the branding.
 */
function BrandCaption(): JSX.Element {
  const {appController} = useAppController();
  const branding = appController.runtime?.branding;
  const isStripe = branding?.isStripe;
  const src = branding?.platformIcon;
  const color = branding?.platformColor;
  const platformName = getBrandingPlatformName(branding);

  if (appController.state.session?.useConnectIframeDesign) {
    return <></>;
  }

  return (
    <view.div uses={[Styles.caption]}>
      {isStripe && !src ? (
        <StripeLogo css={{height: 'space.250'}} />
      ) : (
        <>
          {!!src && (
            <Logo
              color={color}
              size="medium"
              additionalStyles={[Styles.brandCaptionIcon]}
              src={src}
              hideIfFailed
            />
          )}
          <view.div uses={[Styles.brandCaptionText, ellipsis]}>
            {platformName}
          </view.div>
        </>
      )}
    </view.div>
  );
}

/**
 * The progress bar component.
 */
function ProgressBar(): JSX.Element {
  const {appController, appState} = useAppController();
  const path = appController.runtime?.router.currentPath || '';

  if (
    appState.session?.useConnectIframeDesign ||
    PagesWithoutProgressBar.some((page) => page.test(path))
  ) {
    return <></>;
  }

  const progressValue = /\/success/.test(path)
    ? 100
    : computeProgressValue(appState.session);

  const color =
    /\/link/.test(path) && flags.isActive('idprod_ni_implementation_review')
      ? LINK_GREEN
      : tokens.color.border.action.primary;

  return (
    <view.div uses={[Styles.progressBarContainer]}>
      <view.div uses={[Styles.progressBar]}>
        <view.div
          css={{
            backgroundColor: color,
            width: `${progressValue}%`,
          }}
          uses={[Styles.progressBarValue]}
        />
      </view.div>
    </view.div>
  );
}

type Props = {
  // Optional page title.
  caption?: React.ReactNode | null | undefined;
  // Optional action to execute when the side menu button is clicked.
  sideMenuAction?: (() => void) | null | undefined;
  // Optional param that hides the back button
  hideBackButton?: boolean;
};

// TODOs:
// - Ensure that the progress bar color is always distinct from the background.

export default function TopNavigationBar(props: Props): JSX.Element {
  const {caption, sideMenuAction, hideBackButton = false} = props;

  return (
    <>
      <view.div uses={[Styles.topNavigationBar]}>
        <view.div uses={[Styles.col]}>
          {hideBackButton ? <Spacer /> : <BackButton />}
        </view.div>
        <view.div uses={[Styles.col, Styles.colCenter]}>
          {caption || <Caption />}
        </view.div>
        <view.div uses={[Styles.col]}>
          <SideMenuButton sideMenuAction={sideMenuAction} />
        </view.div>
      </view.div>
      <ProgressBar />
    </>
  );
}
