import {chevronDownSvg as Icon__small__chevronDownSvg} from '../Icon__small/Icon__small--chevronDown.js'
import {chevronDownSvg as Icon__xsmall__chevronDownSvg} from '../Icon__xsmall/Icon__xsmall--chevronDown.js'
import {chevronDownSvg as Icon__xxsmall__chevronDownSvg} from '../Icon__xxsmall/Icon__xxsmall--chevronDown.js'

export var chevronDown = {
  name: 'chevronDown',
  category: 'icon',
  colorable: true,
  svg: Icon__small__chevronDownSvg,
  alt: {
    Icon__small: Icon__small__chevronDownSvg,
    Icon__xsmall: Icon__xsmall__chevronDownSvg,
    Icon__xxsmall: Icon__xxsmall__chevronDownSvg
  },
  combined: ['Icon__small', 'Icon__xsmall', 'Icon__xxsmall']
};
