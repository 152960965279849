import * as React from 'react';
import {useRef, useImperativeHandle, useCallback} from 'react';
import {
  createView,
  view,
  dynamic,
  animate,
  AnimatePresence,
} from '@sail/engine';
import {
  FocusScope,
  useControlledState,
  useFocusManager,
} from '@sail/react-aria';
import type {FocusManager} from '@sail/react-aria';
import {BottomSheetOverlay} from '../BottomSheetOverlay';
import {PopoverHeader} from './PopoverHeader';
import {bottomSheetStyles, bottomSheetAnimation} from '../../utilStyles/sheet';
import {useNestedSheets} from '../../hooks';
import {ButtonConfig} from '../Button';
import {SelectConfig} from '../Select';
import {TextFieldConfig} from '../TextField';
import {SelectFieldConfig} from '../SelectFieldConfig';
import {Layer} from '../layers/Layer';
import type {View} from '../../view';
import {replaceDeprecatedProps, FocusGuard, moveFocus} from './utils';
import {Backdrop} from '../Backdrop';
import type {PopoverProps} from './PopoverProps';
import {useProps} from '../../hooks/useProps';

export const MobilePopoverLayer = createView(
  (allProps: View.RenderProps<PopoverProps>) => {
    const {
      targetRef,
      placement: reactAriaPlacement,
      offset: offsetOptions,
      flip: flipEnabled,
      shift: shouldShift,
      size: sizeOptions,
      inline: inlineEnabled,
      hide: hideOptions,
      onClose,
      subviews,
      dismissible,
      showOnPress,
      showOnFocus,
      showOnHover,
      role,
      defaultOpen,
      open: controlledOpen,
      mobileTitle,
      onOpenChange: setControlledOpen,
      floatingStrategy,
      animate: shouldAnimate = true,
      useReactAriaDismissOutside,
      ...props
    } = replaceDeprecatedProps(allProps);

    const [open, setOpen] = useControlledState(
      controlledOpen ?? (defaultOpen === undefined ? true : undefined),
      defaultOpen,
      setControlledOpen,
    );

    const onOpenChange = useCallback(
      (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
          onClose?.();
        }
      },
      [setOpen, onClose],
    );

    const scope = useRef<FocusManager>(null);
    const ref = React.useRef<HTMLDivElement>(null);
    const isAndroid =
      typeof navigator !== 'undefined' &&
      /(android)/i.test(navigator.userAgent);

    useProps(targetRef, {
      onClick: () => {
        onOpenChange(!open);
      },
    });

    const layer = (
      <Layer inherits={subviews.layer} name="modal">
        <Backdrop
          onPress={() =>
            // Temporary disabling backdrop closures on Android devices until we can fix the root
            // cause, which is onClick and onPress events interacting undesirably.
            !isAndroid && onOpenChange(false)
          }
          uses={[
            animate.inout({
              duration: 150,
              opacity: 0,
              easing: 'ease-in-out',
            }),
          ]}
        />
        <FocusScope restoreFocus>
          <view.div
            // declare this before spreading props so user's test id wins out, but we can
            // still use this test id for sail next tests
            data-testid="popover-layer"
            {...props}
            ref={ref}
            uses={[
              dynamic(() => {
                const manager = useFocusManager();
                useImperativeHandle(scope, () => manager);
              }),
              dynamic(() => {
                const {sheetStackProvider} = useNestedSheets(
                  ref,
                  'bottom-sheet',
                );
                return [sheetStackProvider];
              }),
              ...(shouldAnimate ? [bottomSheetAnimation(false)] : []),
              ButtonConfig.customize({defaults: {size: 'large'}}),
              SelectConfig.customize({defaults: {size: 'large'}}),
              SelectFieldConfig.customize({defaults: {size: 'large'}}),
              TextFieldConfig.customize({defaults: {size: 'large'}}),

              bottomSheetStyles,
            ]}
          >
            <BottomSheetOverlay />
            <PopoverHeader
              onClose={() => onOpenChange(false)}
              title={mobileTitle}
            />
            {props.children}
          </view.div>
        </FocusScope>
      </Layer>
    );

    return (
      <>
        {/* focus guards to allow focus to move between the page and the popover */}
        {open ? <FocusGuard onFocus={moveFocus(scope, 'next')} /> : null}
        <AnimatePresence>{open ? layer : null}</AnimatePresence>
        {open ? <FocusGuard onFocus={moveFocus(scope, 'prev')} /> : null}
      </>
    );
  },
  {
    css: {
      backgroundColor: 'surface',
      border: '1px solid',
      borderColor: 'border',
      borderRadius: 'medium',
      boxShadow: 'overlay',
      maxHeight: 'inherit',
    },
  },
);
