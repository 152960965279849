import {RequestFailedType, batchProdUrl, batchQaUrl} from '../core/types';
import {toQueryString} from './string';
import type {AnalyticsConfig} from '../core/types';

interface AnalyticsEvent {
  [key: string]: unknown;
}

export const MAX_BATCH_SIZE = 5;
// Chrome's maximum payload size for sendBeacon() is 64kB
// AEL backend also contains a 100,000 kB limit:
// https://git.corp.stripe.com/stripe-internal/zoolander/blob/HEAD/uppsala/src/main/java/com/stripe/dscore/analyticseventlogger/server/translation/HttpServer.java#L87
const MAX_PAYLOAD_SIZE = 64000;
// Timeout after which to flush the buffer, even if the max batch size isn't reached
const BATCH_TIMEOUT = 3 * 1000;

/**
 * @param AnalyticsConfig
 * The BatchLogger class is used to buffer analytics events to AEL until the MAX_PAYLOAD_SIZE or MAX_BATCH_SIZE is hit.
 * Then, the events are flushed to AEL's batching endpoint at 'r.stripe.com/b'. Events are also flushed
 * when page's visibility state changes.
 */
export class BatchLogger {
  private logEntries: AnalyticsEvent[] = [];

  private clientId: string;

  private trackingUrl: string;

  private batchSize: number;

  private batchTimer: ReturnType<typeof setTimeout> | null;

  private onAnalyticsRequestFailed?: (
    type: RequestFailedType,
    numEvents: number,
    error?: Error,
  ) => void;

  constructor(config: AnalyticsConfig) {
    this.clientId = config.clientId;

    this.trackingUrl = config.inProduction ? batchProdUrl : batchQaUrl;

    this.batchSize = config.batchSettings?.batchSize
      ? config.batchSettings?.batchSize
      : MAX_BATCH_SIZE;

    this.batchTimer = null;

    this.onAnalyticsRequestFailed = config.onAnalyticsRequestFailed;

    // sendBeacon asynchronously sends analytics data to the AEL batching endpoint
    // when the user moves away from the page
    document.addEventListener('visibilitychange', (_evt) => {
      if (document.visibilityState === 'hidden' && this.logEntries.length > 0) {
        this.handleLogging();
      }
    });
  }

  /**
   * Function adds an analytics event to an ongiong list, and flushes the events when the list's length in
   * bytes is greater than MAX_PAYLOAD_SIZE or its size is at batchSize.
   */
  public pushEvent(event: AnalyticsEvent) {
    // if adding event will cause buffer overflow, send current buffer
    if (
      this.getPayloadSizeInBytes() + JSON.stringify(event).length >=
      MAX_PAYLOAD_SIZE
    ) {
      this.fetchLogs(false);
    }

    // add event to buffer
    this.logEntries.push(event);

    // if batch size is reached, send buffer
    if (this.logEntries.length >= this.batchSize) {
      this.fetchLogs(false);
    } else if (!this.batchTimer) {
      // Set a timer to send the buffer if the batch size isn't reached
      this.batchTimer = setTimeout(() => {
        this.fetchLogs(false);
      }, BATCH_TIMEOUT);
    }
  }

  /**
   * Function returns the buffer of events.
   */
  public getEvents() {
    return this.logEntries;
  }

  /**
   * Function posts the events to the AEL batching endpoint.
   */
  private async fetchLogs(sendAsync: boolean) {
    if (this.logEntries.length === 0) {
      return;
    }
    const numEvents = this.logEntries.length;
    const params = this.getLoggerPayload();
    const body = toQueryString(params);

    this.resetBuffer();

    const typeSuffix = sendAsync ? '-keepalive' : '';

    try {
      const result = await fetch(this.trackingUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body,
        credentials: 'omit',
        keepalive: sendAsync,
      });

      if (!result.ok) {
        this.onAnalyticsRequestFailed?.(
          `ael-batch-error${typeSuffix}`,
          numEvents,
          new Error(`Error received from HTTP response (${result.status})`),
        );
      }

      return result;
    } catch (err) {
      this.onAnalyticsRequestFailed?.(
        `ael-batch-throws${typeSuffix}`,
        numEvents,
        err as Error,
      );

      return null;
    }
  }

  /**
   * Function returns a request payload adhering to the AEL batching schema.
   */
  public getLoggerPayload() {
    return {
      client_id: this.clientId,
      num_requests: this.logEntries.length,
      events: [...this.logEntries],
    };
  }

  /**
   * Function empties the list of entries.
   */
  public resetBuffer() {
    this.logEntries = [];
    if (this.batchTimer) {
      clearTimeout(this.batchTimer);
      this.batchTimer = null;
    }
  }

  /**
   * Function returns the size in bytes of the logged events as a URL-encoded string
   */
  public getPayloadSizeInBytes() {
    const payload = new Blob([toQueryString(this.getLoggerPayload())]);
    return payload.size;
  }

  /**
   * Function checks if sendBeacon is supported.
   */
  private isSendBeaconSupported() {
    return navigator && navigator.sendBeacon;
  }

  /**
   * Function uses navigator.sendBeacon() to send logged events to the trackingUrl.
   */
  private sendBeaconWithPayload() {
    // sendBeacon returns true if the request has successfully been queued for data transfer.
    // A return value of true does NOT mean that the request was successful
    // sendBeacon may return false if the payload is too big (64kb for Chrome)

    // sendBeacon may throw a TypeError if the navigator context isn't bound to the function
    // for example, https://github.com/jehna/ga-lite/issues/380
    // so, we explictly bind navigator to sendBeacon to avoid running into "TypeError: Illegal invocation"
    // sendBeacon may also throw an error if the input URL is not parseble
    const send = navigator.sendBeacon.bind(navigator);
    try {
      const result = send(
        this.trackingUrl,
        new Blob([toQueryString(this.getLoggerPayload())], {
          type: 'application/x-www-form-urlencoded',
        }),
      );

      if (!result) {
        this.onAnalyticsRequestFailed?.(
          'ael-beacon-error',
          this.logEntries.length,
        );
      }

      return result;
    } catch (err) {
      this.onAnalyticsRequestFailed?.(
        'ael-beacon-throws',
        this.logEntries.length,
        err as Error,
      );

      // return false to default to fetch method
      return false;
    }
  }

  /**
   * Function uses sendBeacon() to send requests. If sendBeacon() isn't supported or returns false,
   * function fallbacks to using fetch instead with keepAlive set to true.
   */
  private handleLogging() {
    let sendBeaconSuccess = false;
    if (this.isSendBeaconSupported()) {
      sendBeaconSuccess = this.sendBeaconWithPayload();
    }

    // Fallback to fetch with keepAlive if sendBeacon isn't supported or the data queuing failed.
    if (!sendBeaconSuccess) {
      this.fetchLogs(true);
    }

    this.resetBuffer();
  }
}
