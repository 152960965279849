import type {WatchQueryFetchPolicy} from '@apollo/client';

export default function ensureCorrectNextFetchPolicy(
  fetchPolicy: WatchQueryFetchPolicy | undefined,
  nextFetchPolicy: WatchQueryFetchPolicy | undefined,
): WatchQueryFetchPolicy | undefined {
  // When there's no explicit nextFetchPolicy defined and the fetchPolicy is cache-and-network, we
  // are setting nextFetchPolicy to cache-first to avoid additional network requests when there's
  // another query or mutation that writes to the Apollo cache and thus, causes this query to be
  // refetched.
  // Consider the following scenario:
  // Two components render the same query with fetchPolicy `cache-and-network`, the second component
  // is mounted after the first one already executed the query. The second component updates the
  // Apollo cache, the first component **goes to network again** (and `loading = true`), if the
  // Apollo cache was updated. This often triggers an unexpected (from the user point of view)
  // loading spinner, due to product code rendering it only based on the `loading` field, and not
  // the emptiness of the `data`. We fix this bug by setting nextFetchPolicy to `cache-first` for
  // all queries have that don't specify explicitly `nextFetchPolicy` and have `fetchPolicy` set to
  // `cache-and-network`.
  if (!nextFetchPolicy && fetchPolicy === 'cache-and-network') {
    return 'cache-first';
  }

  return nextFetchPolicy;
}
