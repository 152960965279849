// This file was generated using `pay js:run gql-v2 --type=mutation --name=ClearDataFields --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  Scalars,
  FieldErrors,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation ClearDataFieldsMutation(
    $clearIdDocumentFront: Boolean
    $clearIdDocumentBack: Boolean
    $clearFace: Boolean
  ) {
    clearDataFields(
      clearIdDocumentFront: $clearIdDocumentFront
      clearIdDocumentBack: $clearIdDocumentBack
      clearFace: $clearFace
    ) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        path
        type
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const clearDataFieldsMutation: ClearDataFieldsMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const clearDataFieldsMutationOptions: GelatoMutationOptionsWithoutVariables<ClearDataFieldsMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useClearDataFieldsMutation(
  options: GelatoMutationOptions<ClearDataFieldsMutationDocument> = {},
): GelatoMutationReturn<ClearDataFieldsMutationDocument> {
  return useGelatoMutation(clearDataFieldsMutation, {
    ...clearDataFieldsMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type ClearDataFieldsMutationVariables = Exact<{
  clearIdDocumentFront?: InputMaybe<Scalars['Boolean']>;
  clearIdDocumentBack?: InputMaybe<Scalars['Boolean']>;
  clearFace?: InputMaybe<Scalars['Boolean']>;
}>;

export type ClearDataFieldsMutationData = {
  readonly __typename: 'Mutation';
  readonly clearDataFields: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<{
      readonly __typename: 'ValidationError';
      readonly path: ReadonlyArray<string>;
      readonly type: FieldErrors;
    }>;
  };
};

const clearDataFieldsMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = clearDataFieldsMutationPermissions;

export type ClearDataFieldsMutationPermissions = GelatoPermissionToken<
  typeof clearDataFieldsMutationPermissions
>;

export type ClearDataFieldsMutationDocument = GraphQlDocument<
  ClearDataFieldsMutationData,
  ClearDataFieldsMutationVariables,
  ClearDataFieldsMutationPermissions
>;
