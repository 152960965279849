import {ApolloLink} from '@apollo/client';
import createSplitLink from 'src/public/links/createSplitLink';

import type {GraphQlConfig, InternalOperation} from 'src/types';

const V1_GRAPHQL_TAG = 'gql';
const V2_GRAPHQL_TAG = 'graphql';

const splitOverriddenOperations =
  (overriddenOperationsV1ToV2: Record<string, boolean>) =>
  (operation: InternalOperation): boolean =>
    operation.getContext().queryTag === V1_GRAPHQL_TAG &&
    overriddenOperationsV1ToV2[operation.operationName];

export default function createOverriddenV1ToV2Link(
  graphqlConfig: GraphQlConfig,
): ApolloLink {
  const v2Link = graphqlConfig.schemas[V2_GRAPHQL_TAG]?.apolloLink;
  const overriddenOperationsV1ToV2 =
    graphqlConfig.__DO_NOT_USE_overriddenOperationsV1ToV2 || {};

  return v2Link
    ? ApolloLink.split(
        splitOverriddenOperations(overriddenOperationsV1ToV2),
        v2Link,
        createSplitLink(graphqlConfig),
      )
    : createSplitLink(graphqlConfig);
}
