// This file was generated using `pay js:run gql-v2 --type=mutation --name=PrepareVerificationSessionForConsumerNetworking --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  Scalars,
  FieldErrors,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation PrepareVerificationSessionForConsumerNetworkingMutation(
    $consumerSessionClientSecret: String!
  ) {
    prepareVerificationSessionForConsumerNetworking(
      consumerSessionClientSecret: $consumerSessionClientSecret
    ) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        path
        type
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const prepareVerificationSessionForConsumerNetworkingMutation: PrepareVerificationSessionForConsumerNetworkingMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const prepareVerificationSessionForConsumerNetworkingMutationOptions: GelatoMutationOptionsWithoutVariables<PrepareVerificationSessionForConsumerNetworkingMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function usePrepareVerificationSessionForConsumerNetworkingMutation(
  options: GelatoMutationOptions<PrepareVerificationSessionForConsumerNetworkingMutationDocument> = {},
): GelatoMutationReturn<PrepareVerificationSessionForConsumerNetworkingMutationDocument> {
  return useGelatoMutation(
    prepareVerificationSessionForConsumerNetworkingMutation,
    {
      ...prepareVerificationSessionForConsumerNetworkingMutationOptions,
      ...options,
    },
  );
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type PrepareVerificationSessionForConsumerNetworkingMutationVariables =
  Exact<{consumerSessionClientSecret: Scalars['String']}>;

export type PrepareVerificationSessionForConsumerNetworkingMutationData = {
  readonly __typename: 'Mutation';
  readonly prepareVerificationSessionForConsumerNetworking: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<{
      readonly __typename: 'ValidationError';
      readonly path: ReadonlyArray<string>;
      readonly type: FieldErrors;
    }>;
  };
};

const prepareVerificationSessionForConsumerNetworkingMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions =
  prepareVerificationSessionForConsumerNetworkingMutationPermissions;

export type PrepareVerificationSessionForConsumerNetworkingMutationPermissions =
  GelatoPermissionToken<
    typeof prepareVerificationSessionForConsumerNetworkingMutationPermissions
  >;

export type PrepareVerificationSessionForConsumerNetworkingMutationDocument =
  GraphQlDocument<
    PrepareVerificationSessionForConsumerNetworkingMutationData,
    PrepareVerificationSessionForConsumerNetworkingMutationVariables,
    PrepareVerificationSessionForConsumerNetworkingMutationPermissions
  >;
