import React from 'react';
import type {View} from '@sail/engine';
import {
  assignProps,
  useTokenValue,
  animate,
  createViewConfig,
  usePortalTokenStyles,
  dynamic,
} from '@sail/engine';
import ReactDOM from 'react-dom';
import type {LayerType} from '@sail/global-state';
import {FocusScope} from '@sail/global-state';
import {setupFocus} from '@sail/react-aria';
import {view as viewClassic} from '@sail/theme-classic';
import {themeGlobals, useTokenContext} from '@sail/engine/deprecated';

import {useInteractAnywhere} from '../hooks/useInteractAnywhere';
import type {
  IUILayerMessagePort,
  IUILayerRepositionValues,
  PartialIframeProps,
  RequestIframe,
} from './types';
import {tokens} from '../tokens';
import {useFocusContainment} from './FocusContainmentProvider';
import {generateUUID, getRepositionValues, getScrollParent} from './utils';
import {useScrollbarStyleOverrides} from './IframeScrollbarStyleProvider';
import {useUiConfig} from '../providers/UiConfigProvider';

/**
 * This component is used in IframeOverrides for Popovers and Tooltips.
 * It will call the `handler`, i.e. `onClose` function when clicking on any UI layer.
 */
export const OutsideUILayerInteractions = ({
  handler,
  children,
  uiLayers,
}: {
  handler: () => void;
  children: React.ReactNode;
  uiLayers: Window[];
}) => {
  // We have to recurse to be able to iterate the hook
  const [uiLayer, ...rest] = uiLayers;

  useInteractAnywhere(handler, uiLayer?.document);

  if (rest.length > 0) {
    return (
      <OutsideUILayerInteractions handler={handler} uiLayers={rest}>
        {children}
      </OutsideUILayerInteractions>
    );
  } else {
    return <>{children}</>;
  }
};

export const LayerStylesConfig = createViewConfig({
  context: false,
  element: true,
  props: {} as View.IntrinsicElement<'div'>,
});
const LayerStyles = LayerStylesConfig.createView(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({children, ...props}: any) => {
    const {styles} = useTokenContext();

    return (
      // We still need to include themeGlobals, because some components may still use the Sail stable API themes.
      // Because themeGlobals has directives that are only valid on Sail's stable version, we can't
      // include it in the Sail beta view.div
      <viewClassic.div {...props} uses={[themeGlobals, styles]}>
        {children}
      </viewClassic.div>
    );
  },
  {
    uses: dynamic(() => [usePortalTokenStyles()]),
  },
);

const OverlayAnimator = ({children}: {children: React.ReactNode}) => {
  // Ensure that the dialog transitions are restarted within the content of the iframe. Because
  // we need to wait for the iframe to be created, the content isn't rendered until after the
  // Layer is loaded, and transitions don't otherwise fire at the correct time.
  const timeline = animate.useCurrentTimeline();
  React.useEffect(() => {
    timeline.start('in');
  }, [timeline]);
  return <>{children}</>;
};

const useIframe = (
  type: LayerType,
  initialReposition?: IUILayerRepositionValues,
  open?: boolean,
  shouldSkipIframePortal?: boolean,
) => {
  const uiConfig = useUiConfig();
  // We cannot hit this code unless requestIframe is provided
  const requestIframe = uiConfig?._unstable_requestIframe as RequestIframe;

  const [iframeInfo, setIframeInfo] = React.useState<{
    iframeId: string;
    uiLayerMessagePort: IUILayerMessagePort;
    root: HTMLElement;
  } | null>(null);
  const isDestroyed = React.useRef(false);
  const isMounted = React.useRef(true);
  const shouldBeOpen = open == null || open === true;

  React.useEffect(() => {
    (async () => {
      if (shouldSkipIframePortal) {
        return;
      }

      // When the open prop is supported, the component will always be mounted whether the layer content is shown or not.
      // Therefore, we should only request an iframe if the open prop is true or undefined, and if the open prop is set
      // to false, we should destroy the iframe if it exists since we cannot rely on the component being unmounted to
      // destroy the iframe.
      if (shouldBeOpen) {
        const iframeId = generateUUID();
        const {iframe, uiLayerMessagePort} = await requestIframe(
          iframeId,
          type,
          initialReposition,
        );
        const newRoot = iframe.document.getElementById(
          'root',
        ) as HTMLDivElement | null;
        if (newRoot && isMounted.current) {
          setupFocus(newRoot);
          setIframeInfo({
            iframeId,
            root: newRoot,
            uiLayerMessagePort,
          });
        } else {
          // There is an edge case where the iframe is created but destroyed almost immediately,
          // e.g. mouseenter and mouseleave on a tooltip
          // For such cases, the React state may not be set yet, so we need to delete the iframe via
          // this async function. We also check the isMounted state to prevent memory leaks.
          isDestroyed.current = true;
          uiLayerMessagePort.deleteAccessoryLayer({id: iframeId});
          setIframeInfo(null);
        }
      } else if (!isDestroyed.current && iframeInfo) {
        isDestroyed.current = true;
        iframeInfo.uiLayerMessagePort.deleteAccessoryLayer({
          id: iframeInfo.iframeId,
        });
        setIframeInfo(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBeOpen, requestIframe, type, shouldSkipIframePortal]);

  React.useEffect(() => {
    if (open === true) {
      // Reset isDestroyed and isMounted
      isDestroyed.current = false;
      isMounted.current = true;
    }
  }, [open]);

  React.useEffect(
    () => () => {
      if (!isDestroyed.current && iframeInfo) {
        isDestroyed.current = true;
        iframeInfo.uiLayerMessagePort.deleteAccessoryLayer({
          id: iframeInfo.iframeId,
        });
      }
      isMounted.current = false;
    },
    [iframeInfo],
  );

  return iframeInfo;
};

const usePartialIframePortal = ({
  popoverRef,
  targetRef,
  overlayRef,
  children,
  open,
  placement = 'bottom',
  flip = true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  containerStyles = {},
  inherits,
  shouldSkipIframePortal = false,
  uiLayers,
}: PartialIframeProps) => {
  const {contain} = useFocusContainment();
  const margin = useTokenValue(tokens.space.xsmall);
  const ScrollbarStyleOverrides = useScrollbarStyleOverrides();

  // When requesting the creation of the accessory frame, we can calculate it's
  // approximate position in the document before rendering any UI.
  // This prevents issues, such as those in #ir-atmosphere-pasture, where the
  // accessory frame was positioned off screen on the first render, resulting in
  // the IntersectionObserver closing the accessory frame immediately.
  const initialRepositionValues = targetRef.current
    ? getRepositionValues({
        sourceElement: targetRef.current,
        margin,
        placement,
        flip,
      })
    : undefined;
  const ref = React.useRef<HTMLDivElement>();
  const {iframeId, uiLayerMessagePort, root} =
    useIframe(
      'partial',
      initialRepositionValues,
      open,
      shouldSkipIframePortal,
    ) ?? {};

  const onScroll = React.useCallback(() => {
    // We have to dispatch a synthetic blur event to hide the tooltip, since a
    // regular blur won't work if the targetRef wasn't focused to begin with
    targetRef.current?.dispatchEvent(new CustomEvent('blur'));
  }, [targetRef]);

  const onReposition = React.useCallback(() => {
    if (
      uiLayerMessagePort &&
      iframeId &&
      targetRef?.current &&
      root?.firstElementChild &&
      ref.current
    ) {
      const repositionValues = getRepositionValues({
        sourceElement: targetRef.current,
        margin,
        placement,
        flip,
      });

      // This block of code grabs the relevant styles we want to sync between the LayerStyles element and the iframe.
      const iframeStyles: Record<string, string> = {};
      const layerElement = ref.current;
      // We temporarily remove the inline styles on the LayerStyles element so that the Sail Next class styles can take effect
      layerElement.style.boxShadow = '';
      layerElement.style.border = '';
      layerElement.style.borderRadius = '';
      layerElement.style.marginLeft = '';
      layerElement.style.marginTop = '';
      layerElement.style.marginBottom = '';
      layerElement.style.marginRight = '';
      // We grab the computed styles from the LayerStyles element and apply them to the iframe
      const computedStyles = window.getComputedStyle(layerElement);
      iframeStyles.boxShadow = computedStyles.boxShadow;
      iframeStyles.border = computedStyles.border;
      iframeStyles.borderRadius = computedStyles.borderRadius;
      iframeStyles.marginLeft = computedStyles.marginLeft;
      iframeStyles.marginTop = computedStyles.marginTop;
      iframeStyles.marginBottom = computedStyles.marginBottom;
      iframeStyles.marginRight = computedStyles.marginRight;

      // We reapply the inline styles on the LayerStyles element to disable them.
      layerElement.style.boxShadow = 'none';
      layerElement.style.border = 'none';
      layerElement.style.borderRadius = '0px';
      layerElement.style.marginLeft = '0px';
      layerElement.style.marginTop = '0px';
      layerElement.style.marginBottom = '0px';
      layerElement.style.marginRight = '0px';

      // We grab the computed styles from the Sail's popover element and apply them to the iframe
      // Sail's popover component handles setting the width of the popover to match the width of
      // the trigger element, so where this value is set, we want to sync it to the iframe.
      const popoverStyles = popoverRef?.current?.style;

      if (popoverStyles?.width) {
        layerElement.style.width = popoverStyles.width;
      }

      uiLayerMessagePort.readjustIframe({
        id: iframeId,
        iframeStyles,

        resize: {
          styles: {
            width: getComputedStyle(root?.firstElementChild).width,
            height: getComputedStyle(root?.firstElementChild).height,
          },
        },
        reposition: repositionValues,
      });
    }
  }, [
    iframeId,
    margin,
    placement,
    uiLayerMessagePort,
    root?.firstElementChild,
    flip,
    targetRef,
    popoverRef,
  ]);

  React.useLayoutEffect(() => {
    if (shouldSkipIframePortal) {
      return;
    }

    uiLayerMessagePort?.registerObserver('interactoutside', onClose);
    uiLayerMessagePort?.registerObserver('scrolloutside', onScroll);
    uiLayerMessagePort?.registerObserver('scrolloutside', onReposition);
    const sourceElement = targetRef.current;
    if (sourceElement) {
      targetRef.current.ownerDocument.defaultView?.addEventListener(
        'scroll',
        onReposition,
        // Capturing the event at the window level lets us reposition the tooltip
        // on any child scroll, which prevents us from needing to attach a scroll
        // event to every scrollable ancestor in the DOM.
        {capture: true},
      );
    }

    return () => {
      uiLayerMessagePort?.unregisterObserver('interactoutside', onClose);
      uiLayerMessagePort?.unregisterObserver('scrolloutside', onScroll);
      uiLayerMessagePort?.unregisterObserver('scrolloutside', onReposition);
      if (sourceElement) {
        sourceElement.ownerDocument.defaultView?.removeEventListener(
          'scroll',
          onReposition,
          {capture: true},
        );
      }
    };
  }, [
    onClose,
    onReposition,
    onScroll,
    targetRef,
    uiLayerMessagePort,
    shouldSkipIframePortal,
  ]);

  // This hook will reposition the target iframe when the source element's window is resized
  React.useLayoutEffect(() => {
    if (shouldSkipIframePortal) {
      return;
    }

    const currentOnReposition = onReposition;
    const sourceElement = targetRef.current;
    if (sourceElement) {
      onReposition();
      sourceElement.ownerDocument.defaultView?.addEventListener(
        'resize',
        currentOnReposition,
      );
    }
    if (root) {
      onReposition();
      root.ownerDocument.defaultView?.addEventListener(
        'resize',
        currentOnReposition,
      );
    }
    return () => {
      if (sourceElement) {
        sourceElement.ownerDocument.defaultView?.removeEventListener(
          'resize',
          currentOnReposition,
        );
      }
      if (root) {
        root.ownerDocument.defaultView?.removeEventListener(
          'resize',
          currentOnReposition,
        );
      }
    };
  }, [onReposition, root, targetRef, shouldSkipIframePortal]);

  // This is a workaround for the React aria usePress hook.
  // usePress works by listening to the keydown event on the document, then adding a keyup event listener on the same document
  // When we open a new iframe and focus is moved to the new iframe, the keyup event is captured by the new iframe instead
  // of the original document. This causes the usePress hook to not work as expected.
  // To fix this, we manually dispatch a keyup event on the targetRef when the keyup event is captured by the new iframe.
  React.useEffect(() => {
    if (shouldSkipIframePortal) {
      return;
    }

    const dispatchKeyupEvent = (event: Event) => {
      if (targetRef.current) {
        targetRef.current.dispatchEvent(new KeyboardEvent('keyup', event));
      }
    };

    if (root) {
      root.ownerDocument.addEventListener('keyup', dispatchKeyupEvent);
    }
    return () => {
      if (root) {
        root.ownerDocument.removeEventListener('keyup', dispatchKeyupEvent);
      }
    };
  }, [root, targetRef, shouldSkipIframePortal]);

  // This enables the intersection observer for Popovers and Tooltips
  // See https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  // This allows us to close the Popover and Tooltip when they are out of view from the viewport of the iframe
  React.useLayoutEffect(() => {
    if (shouldSkipIframePortal) {
      return;
    }

    if (targetRef.current) {
      // If scrollParent is null, we default to the browser viewport
      const scrollParent = getScrollParent(targetRef.current) || undefined;

      // We use the owner window of the target element to ensure that the default browser viewport is the target element's window
      const windowObject =
        targetRef.current?.ownerDocument.defaultView || window;

      const observer = new windowObject.IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.intersectionRatio < 0.9) {
            // This tells us the target element is < 90% in view of the root, i.e. scrolling outside of the visible area of the root.
            onClose();
          }
        },
        {root: scrollParent, threshold: 0.9}, // This fires whenever the target element is entering or leaving the root at 90% visibility.
      );

      observer.observe(targetRef.current);
      return () => observer.disconnect();
    }
  }, [onClose, root, targetRef, shouldSkipIframePortal]);

  if (shouldSkipIframePortal) {
    return null;
  }

  if (root) {
    return ReactDOM.createPortal(
      <LayerStyles
        data-testid="partial-layer"
        css={{width: 'fit', height: 'fit', ...containerStyles}}
        inherits={inherits}
        style={{
          // These are inline style attributes that we want to apply to the iframe element instead of its content to avoid any clipping
          border: 'none',
          boxShadow: 'none',
          borderRadius: '0px',
          marginLeft: '0px',
          marginTop: '0px',
          marginBottom: '0px',
          marginRight: '0px',
        }}
        ref={ref}
        uses={[
          assignProps({
            ref: overlayRef,
          }),
        ]}
        // Sail Popover handles this internally, but since we're not rendering within Sail's popover,
        // events will not bubble up to the Popover element and we need to handle this ourselves.
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            onClose?.();
          }
        }}
      >
        <FocusScope restoreFocus={contain}>
          <OutsideUILayerInteractions handler={onClose} uiLayers={uiLayers}>
            <ScrollbarStyleOverrides>{children}</ScrollbarStyleOverrides>
          </OutsideUILayerInteractions>
        </FocusScope>
      </LayerStyles>,
      root,
    );
  }
  return <div style={{display: 'none'}}>{children}</div>;
};

// TODO: Need to handle onClose prop when platform sends us a message to close
export const usePartialIframe = (props: PartialIframeProps) => {
  const {shouldSkipIframePortal, children} = props;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const portal = usePartialIframePortal({
    ...props,
    popoverRef: ref,
    shouldSkipIframePortal,
  });

  return {
    ref: (el: HTMLDivElement) => {
      // need a ref to sail's popover element to grab styles to apply to the iframe
      // but due to the `inherits` usage on LayerStyles, the ref is bound to multiple elements
      // (once to Sail's popover, and once to the iframe layer element)
      if (el && el.parentElement?.id !== 'root') {
        ref.current = el.parentElement as HTMLDivElement | null;
      }
    },
    style: {display: 'none'},
    children: (
      <>
        {
          // We skip iframe portaling when overrides in MobileUIOverrides are applied instead
          // Thus we still render the children here, and allow the overrides downstream to handle the rendering
          shouldSkipIframePortal ? children : portal
        }
      </>
    ),
  };
};

export const useOverlayIframe = ({children}: View.IntrinsicElement<'div'>) => {
  const {root} = useIframe('overlay') ?? {};
  const {contain} = useFocusContainment();
  const ScrollbarStyleOverrides = useScrollbarStyleOverrides();

  return {
    style: {display: 'none'},
    children: root
      ? ReactDOM.createPortal(
          <OverlayAnimator>
            <LayerStyles
              data-testid="overlay-layer"
              css={{width: 'fit', height: 'fit'}}
            >
              <FocusScope
                contain={contain}
                autoFocus={contain}
                restoreFocus={contain}
              >
                <ScrollbarStyleOverrides>{children}</ScrollbarStyleOverrides>
              </FocusScope>
            </LayerStyles>
          </OverlayAnimator>,
          root,
        )
      : null,
  };
};
