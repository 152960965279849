import * as React from 'react';
import {createContext, useContext, useRef, useCallback, useMemo} from 'react';
import {view, createViewConfig} from '@sail/engine';
import type {View} from '@sail/engine';
import {flag} from '@sail/icons/react/Icon';
import type {
  Expandable,
  ItemDesc,
  KeyboardDelegate,
  ListCollection,
  ListCollectionComponent,
  MultipleSelectionStateProps,
  TreeState,
} from '@sail/react-aria';
import {
  useListBox,
  CollectionProvider,
  useListCollection,
  useReactAriaTreeState,
} from '@sail/react-aria';
import {ListBoxItem, ListBoxItemTransforms} from './ListBoxItem';
import {ListBoxGroup, ListBoxGroupTransforms} from './ListBoxGroup';
import type {ListBoxItemProps} from './ListBoxItem';
import type {ListBoxGroupProps} from './ListBoxGroup';
import {Icon} from './Icon';

type SelectionProps = Pick<
  MultipleSelectionStateProps,
  | 'onSelectionChange'
  | 'selectionMode'
  | 'defaultSelectedKeys'
  | 'selectedKeys'
  | 'disabledKeys'
>;

export type ListBoxProps<T> = ListCollectionComponent<
  T,
  ListBoxItemProps,
  typeof ListBoxItemTransforms.defaultField,
  ListBoxGroupProps,
  typeof ListBoxGroupTransforms.idField
> &
  View.IntrinsicElement<
    'div',
    {
      /**
       * Required, but currently only used for accessibility purposes. In the future it may be
       * shown above the listbox in time when it's inline in the page.
       */
      label: React.ReactNode;

      /**
       * Content to render when the list is empty.
       */
      emptyState?: React.ReactNode;
    }
  > &
  SelectionProps &
  Expandable;

export const ListBoxConfig = createViewConfig({
  name: 'ListBox',
  props: {} as ListBoxProps<unknown>,
  defaults: {
    selectionMode: 'single',
    defaultExpandedKeys: 'all',
    expandable: false,
  },
});

const noResults = (
  <view.div
    css={{
      font: 'label.medium',
      marginX: 'xsmall',
      marginY: 'large',
      padding: 'small',
      color: 'subdued',
      textAlign: 'center',
    }}
  >
    <Icon size="xsmall" icon={flag} css={{marginX: 'space.75'}} /> No results
  </view.div>
);

// We allow for users to override advanced behaviors via context, and these are
// the options (in addition to the `state` and `collection`) that can be
// overriden
export interface ListBoxOptions {
  /**
   * An optional keyboard delegate implementation for type to select,
   * to override the default.
   */
  keyboardDelegate?: KeyboardDelegate;
  /**
   * Whether the listbox items should use virtual focus instead of being focused directly.
   */
  shouldUseVirtualFocus?: boolean;
  /** Whether selection should occur on press up instead of press down. */
  shouldSelectOnPressUp?: boolean;
  /** Whether options should be focused when the user hovers over them. */
  shouldFocusOnHover?: boolean;
  /** Whether the collection allows empty selection. */
  readonly disallowEmptySelection?: boolean;

  /** Whether to auto focus the listbox or an option. */
  autoFocus?: boolean;
}

interface ListBoxContextValue {
  state?: TreeState;
  collection?: ListCollection<ListBoxItemProps, ListBoxGroupProps>;
  options?: ListBoxOptions;
}

export const ListBoxContext = createContext<ListBoxContextValue>({});

const useListBoxState = <T extends object>(
  props: Pick<
    ListBoxProps<T>,
    | 'data'
    | 'item'
    | 'group'
    | 'children'
    | 'onSelectionChange'
    | 'selectionMode'
    | 'defaultSelectedKeys'
    | 'selectedKeys'
    | 'disabledKeys'
    | 'expandable'
    | keyof Expandable
  >,
) => {
  const {
    state: inheritedState,
    collection: inheritedCollection,
    options: inheritedOptions,
  } = useContext(ListBoxContext);

  // Track whether we're using inherited state or not so we can't violate the
  // rules of hooks by switching between the two.
  const isUsingInheritedState = useRef(
    Boolean(inheritedState && inheritedCollection),
  );

  if (isUsingInheritedState.current) {
    return {
      state: inheritedState,
      collection: inheritedCollection,
      options: inheritedOptions,
    } as Required<ListBoxContextValue>;
  }

  // Create collection and based on the incoming props

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const collection = useListCollection({
    ...props,
    itemTransforms: ListBoxItemTransforms,
    groupTransforms: ListBoxGroupTransforms,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const state = useReactAriaTreeState({
    getItems: collection.getItems,
    ...props,
  });

  return {collection, state, options: {} as ListBoxOptions};
};

export const ListBox = ListBoxConfig.createGenericView(
  <T extends object>({
    data,
    item,
    group,
    children,
    label,
    onSelectionChange,
    selectionMode,
    defaultSelectedKeys,
    selectedKeys,
    disabledKeys,
    emptyState = noResults,
    expandedKeys,
    defaultExpandedKeys,
    onExpandedChange,
    expandable,
    ...props
  }: View.RenderProps<ListBoxProps<T>>) => {
    const {collection, state, options} = useListBoxState({
      data,
      item,
      group,
      children,
      selectionMode,
      onSelectionChange,
      defaultSelectedKeys,
      selectedKeys,
      disabledKeys,
      expandedKeys,
      defaultExpandedKeys,
      onExpandedChange,
      expandable,
    });
    const filter = useCallback(
      (item: ItemDesc) => {
        return Boolean(item?.key && state.collection.getItem(item.key));
      },
      [state],
    );
    const isEmpty = useMemo(
      () => collection.getItems().filter(filter).length === 0,
      [collection, filter],
    );

    const ref = React.useRef(null);
    const {listBoxProps} = useListBox(
      {label, shouldFocusWrap: true, ...options},
      state,
      ref,
    );

    return (
      <CollectionProvider collection={collection} state={state}>
        <view.div ref={ref} {...props} {...listBoxProps}>
          {collection.render(
            (props) => (
              <ListBoxItem key={props.id} {...props}>
                {props.children || props.textValue}
              </ListBoxItem>
            ),
            (props) => (
              <ListBoxGroup key={props.id} {...props} />
            ),
            filter,
          )}
          {isEmpty && emptyState}
        </view.div>
      </CollectionProvider>
    );
  },
  {
    css: {
      stack: 'y',
      gap: 'space.0',
      backgroundColor: 'surface',
      borderRadius: 'medium',
      keyline: 'border',
      keylineWidth: '1px',
      padding: 'xsmall',
      focusRing: 'none',
    },
  },
);
