// This file was generated using `pay js:run gql-v2 --type=mutation --name=ConfirmDocumentImages --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  Scalars,
  DocumentSide,
  FieldErrors,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation ConfirmDocumentImagesMutation(
    $confirmFile: String!
    $confirmSide: DocumentSide!
  ) {
    confirmDocumentImages(
      confirmFile: $confirmFile
      confirmSide: $confirmSide
    ) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        path
        type
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const confirmDocumentImagesMutation: ConfirmDocumentImagesMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const confirmDocumentImagesMutationOptions: GelatoMutationOptionsWithoutVariables<ConfirmDocumentImagesMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useConfirmDocumentImagesMutation(
  options: GelatoMutationOptions<ConfirmDocumentImagesMutationDocument> = {},
): GelatoMutationReturn<ConfirmDocumentImagesMutationDocument> {
  return useGelatoMutation(confirmDocumentImagesMutation, {
    ...confirmDocumentImagesMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type ConfirmDocumentImagesMutationVariables = Exact<{
  confirmFile: Scalars['String'];
  confirmSide: DocumentSide;
}>;

export type ConfirmDocumentImagesMutationData = {
  readonly __typename: 'Mutation';
  readonly confirmDocumentImages: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<{
      readonly __typename: 'ValidationError';
      readonly path: ReadonlyArray<string>;
      readonly type: FieldErrors;
    }>;
  };
};

const confirmDocumentImagesMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = confirmDocumentImagesMutationPermissions;

export type ConfirmDocumentImagesMutationPermissions = GelatoPermissionToken<
  typeof confirmDocumentImagesMutationPermissions
>;

export type ConfirmDocumentImagesMutationDocument = GraphQlDocument<
  ConfirmDocumentImagesMutationData,
  ConfirmDocumentImagesMutationVariables,
  ConfirmDocumentImagesMutationPermissions
>;
