import {useReports} from '@sail/observability';
import {once} from '@sail/utils';
import {useContext} from 'react';
import DataConfigContext from 'src/internal/config/DataConfigContext';
import defaultConfig from 'src/internal/config/defaultConfig';

import type {Errors} from '@sail/observability';
import type {DataConfig} from 'src/internal/config/types';

class DataConfigError extends Error {
  constructor(message: string) {
    super(`[@sail/data] ${message}`);
  }
}

const reportUninitializedErrorOnce = once((report: Errors['error']) => {
  const error = new DataConfigError(
    'Make sure to provide the required config for data to DataProvider upon initialization.',
  );

  report(error, {
    tags: {namespace: 'data'},
  });
});

export default function useDataConfig(): DataConfig {
  const config = useContext(DataConfigContext);
  const {error} = useReports();

  if (config === defaultConfig) {
    reportUninitializedErrorOnce(error);
  }

  return config;
}
