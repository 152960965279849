import {useMemo} from 'react';
import observabilityConfig from 'src/internal/config';
import ObservabilityConfigContext from 'src/internal/config/ObservabilityConfigContext';
import {DEFAULT_OBSERVABILITY_CONFIG} from 'src/internal/constants';
import useObservabilityConfig from 'src/public/useObservabilityConfig';

import type {ReactElement, ReactNode} from 'react';
import type {ObservabilityConfig} from 'src/types';

type Props = {
  config: ObservabilityConfig;
  children: ReactNode;
};

const getInitConfig = (): ObservabilityConfig | undefined => {
  // If the config is not initialized, we don't want to log an error.
  // We're only interested in reading possible context values
  // (as if it were a <ObservabilityProvider>) which are not critical.
  //
  // Once we move to a declarative initialization, we won't be needing
  // to read from the config anymore, as the <ObservabilityProvider> will
  // be the only way to initialize the `sdk/observability`.
  return observabilityConfig.isInitialized()
    ? observabilityConfig.getAll()
    : undefined;
};

export default function ObservabilityConfigProvider({
  config,
  children,
}: Props): ReactElement {
  const parentConfig = useObservabilityConfig();
  const initialConfig = getInitConfig();
  const defaultConfig = DEFAULT_OBSERVABILITY_CONFIG;
  const mergedConfig = useMemo(
    () => ({
      ...initialConfig,
      ...parentConfig,
      ...config,
      errors: {
        ...initialConfig?.errors,
        ...parentConfig?.errors,
        ...config.errors,
        projects: {
          ...defaultConfig?.errors?.projects,
          ...initialConfig?.errors?.projects,
          ...parentConfig?.errors?.projects,
          ...config?.errors?.projects,
        },
      },
    }),
    [parentConfig, initialConfig, defaultConfig, config],
  );

  return (
    <ObservabilityConfigContext.Provider value={mergedConfig}>
      {children}
    </ObservabilityConfigContext.Provider>
  );
}
