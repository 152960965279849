import * as React from 'react';
import {useMemo} from 'react';
import type {
  ListCollection,
  MakeAccessors,
  ItemTransforms,
  SectionTransforms,
  ItemPropsKeys,
  ItemKeys,
  IgnoredItemFields,
  ExtractKeysOfType,
  Item,
  ItemDesc,
} from './types';

type ObjectEntries<T> = Array<[keyof T, T[keyof T]]>;

type FilterFn<T> = (item: Item, userItem: T) => boolean;

// Throughout this file, you'll see the terms "props", "items", and "render" (or
// "rendered"). A piece of data is represented in many ways:
//
// * "datum" (often referenced as just "d"): the piece of data directly provided by the user
// * "props": an object representing the props that a component takes to render an item
// * "item": a generic item with an interface common to all items (defined in types.ts)
// * "rendered": a rendered component instance representing the item
//
// The code in this file implements a pipeline that transforms a datum into all the above
// representations.

// This is an optimization in `transformItem`: we lazily get the entries for an
// object and want to reuse them since we're calling it for each item
const mappingsEntries = new WeakMap();

// Given a description of how to map properties, take a datum and map it
// appropriately and return props
export function transformItem<ItemProps>() {
  return <T, ItemDefaultField extends ItemPropsKeys<ItemProps>>(
    d: T,
    mappings: MakeAccessors<
      T,
      Omit<ItemProps, IgnoredItemFields>,
      ItemDefaultField
    >,
    defaultKey: ItemDefaultField,
  ) => {
    if (typeof mappings === 'string') {
      const sourceKey = mappings;
      return {[defaultKey]: d[sourceKey]} as ItemProps;
    } else if (typeof mappings === 'function') {
      return {[defaultKey]: mappings(d)} as ItemProps;
    } else {
      let entries: ObjectEntries<typeof mappings> =
        mappingsEntries.get(mappings);
      if (entries == null) {
        entries = Object.entries(mappings) as ObjectEntries<typeof mappings>;
        mappingsEntries.set(mappings, entries);
      }

      const res = {} as ItemProps;
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        const key = entry[0];
        const accessor = entry[1];

        if (typeof accessor === 'function') {
          res[key] = accessor(d) as ItemProps[typeof key];
        } else {
          res[key] = d[
            accessor as keyof T
          ] as unknown as (typeof res)[typeof key];
        }
      }

      return res;
    }
  };
}

// Given a generic item description, normalize it and return a generic item
function fulfillItem(
  item: ItemDesc,
  key: string,
  childItems?: Array<Item>,
): Item {
  return {
    data: item.data,
    type: item.type || 'item',
    key,
    // Allow the user to provide different values for these, but if they
    // only provide one of them make the other one fall back to it
    textValue: item.textValue || item['aria-label'],
    'aria-label': item['aria-label'] || item.textValue || '',
    indexPathString: '',
    childItems,
  };
}

class ListCollectionWithData<
  UserItem extends object,
  ItemProps extends object,
  SectionProps extends object,
> {
  caches: Caches<UserItem, ItemProps>;

  items: Array<Item>;

  itemPropsList: Array<ItemProps>;

  sectionPropsList: Array<SectionPropsTree<SectionProps, ItemProps>>;

  constructor(
    caches: Caches<UserItem, ItemProps>,
    items: Array<Item>,
    itemPropsList: Array<ItemProps>,
    sectionPropsList: Array<SectionPropsTree<SectionProps, ItemProps>>,
  ) {
    this.caches = caches;
    this.items = items;
    this.itemPropsList = itemPropsList;
    this.sectionPropsList = sectionPropsList;
  }

  filterItems<T>(items: Array<Item>, filterFn: FilterFn<T>): Array<Item> {
    const filteredItems = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const userItem = this.caches.userItemCache.get(item) as T;

      if (item.type === 'section' && item.childItems) {
        const filtered = this.filterItems(item.childItems, filterFn);
        if (filtered.some((node) => node.type === 'item')) {
          filteredItems.push({...item, childItems: filtered});
        }
      } else if (
        item.type !== 'item' ||
        (item.type === 'item' && item.textValue && filterFn(item, userItem))
      ) {
        filteredItems.push(item);
      }
    }
    return filteredItems;
  }

  getItems = <T,>(filter?: FilterFn<T>) => {
    if (!filter) {
      return this.items;
    }

    return this.filterItems(this.items, filter);
  };

  render = <T,>(
    renderItem: (x: ItemProps) => React.ReactNode,
    renderSection: (x: SectionProps) => React.ReactNode,
    filter?: FilterFn<T>,
  ) => {
    const {itemCache, renderCache, userItemCache} = this.caches;
    const itemPropsList = this.itemPropsList;
    const sectionPropsList = this.sectionPropsList;
    const rendered = [];

    if (sectionPropsList.length > 0) {
      for (let i = 0; i < sectionPropsList.length; i++) {
        const props = sectionPropsList[i];
        const itemDesc = itemCache.get(props.section);
        const userItem = userItemCache.get(itemDesc!) as T;

        const renderedItems = [];
        for (let c = 0; c < props.childItems.length; c++) {
          const itemProps = props.childItems[c];
          const itemDesc = itemCache.get(itemProps);
          const userItem = userItemCache.get(itemDesc!) as T;
          let cached = renderCache.get(itemProps);
          if (!cached) {
            cached = renderItem(itemProps);
            renderCache.set(itemProps, cached);
          }

          if (!filter || (itemDesc && filter(itemDesc, userItem))) {
            renderedItems.push(cached);
          }
        }

        if (!filter || (itemDesc && filter(itemDesc, userItem))) {
          rendered.push(
            renderSection({
              ...props.section,
              children: <React.Fragment>{renderedItems}</React.Fragment>,
            }),
          );
        }
      }
    } else {
      for (let i = 0; i < itemPropsList.length; i++) {
        const props = itemPropsList[i];
        const itemDesc = itemCache.get(props);
        const userItem = userItemCache.get(itemDesc!) as T;
        let cached = renderCache.get(props);
        if (!cached) {
          cached = renderItem(props);
          renderCache.set(props, cached);
        }

        if (!filter || (itemDesc && filter(itemDesc, userItem))) {
          rendered.push(cached);
        }
      }
    }

    return <React.Fragment>{rendered}</React.Fragment>;
  };
}

type SectionPropsTree<SectionProps, ItemProps> = {
  section: SectionProps;
  childItems: Array<ItemProps>;
};

type CachedSection<SectionProps, ItemProps> = {
  propsTree: SectionPropsTree<SectionProps, ItemProps>;
  item: Item;
};

type Caches<T extends object, ItemProps> = {
  propsCache: WeakMap<object, ItemProps>;
  itemCache: WeakMap<object, Item>;
  renderCache: WeakMap<object, React.ReactNode>;
  userItemCache: WeakMap<object, T>;
};

export function makeListCollectionWithData<
  T extends object,
  ItemProps extends object,
  ItemDefaultField extends ItemPropsKeys<ItemProps>,
  SectionProps extends object,
  SectionIdField extends ItemKeys<ExtractKeysOfType<SectionProps, string>>,
>(
  caches: Caches<T, ItemProps>,
  data: Array<T>,
  itemAccessor: MakeAccessors<
    T,
    Omit<ItemProps, IgnoredItemFields>,
    ItemDefaultField
  >,
  sectionAccessor:
    | MakeAccessors<T, Omit<SectionProps, IgnoredItemFields>, SectionIdField>
    | null
    | undefined,
  itemTransforms: ItemTransforms<ItemProps, ItemDefaultField>,
  sectionTransforms: SectionTransforms<SectionProps, SectionIdField>,
): ListCollection<ItemProps, SectionProps> {
  const {propsCache, itemCache, userItemCache} = caches;
  const sections: Map<
    string,
    CachedSection<SectionProps, ItemProps>
  > = new Map();

  const orderedProps: Array<ItemProps> = [];
  const orderedSectionProps: Array<{
    section: SectionProps;
    childItems: Array<ItemProps>;
  }> = [];
  const orderedItems: Array<Item> = [];

  for (let i = 0; i < data.length; i++) {
    const d = data[i];

    let props: ItemProps;
    if (propsCache.has(d)) {
      props = propsCache.get(d)!;
    } else {
      props = transformItem<ItemProps>()(
        d,
        itemAccessor,
        itemTransforms.defaultField,
      );

      // @ts-expect-error `d` is any object but we are checking if `id` or `key`
      // exists
      const id = d.id != null ? d.id : d.key;

      if (id == null) {
        const str = JSON.stringify(d, null, 2);
        throw new Error(
          `Collection item does not have an "id" or "key" field: ${str}`,
        );
      }

      // @ts-expect-error Need to fix this by enforcing ItemProps to take id
      props.id = id;
      propsCache.set(d, props);
    }

    let item: Item;
    if (itemCache.has(props)) {
      item = itemCache.get(props)!;
    } else {
      item = fulfillItem(
        itemTransforms.makeGenericItem(props),
        // @ts-expect-error Need to fix this by enforcing ItemProps to take id
        props.id,
      );
      itemCache.set(props, item);
    }

    if (!userItemCache.has(d)) {
      userItemCache.set(item, d);
    }

    if (!sectionAccessor) {
      orderedProps.push(props);
      orderedItems.push(item);
    } else {
      const sectionProps = transformItem<SectionProps>()(
        d,
        sectionAccessor,
        sectionTransforms.idField,
      );

      const sectionId = sectionProps[sectionTransforms.idField] as string;

      let cachedSection: CachedSection<SectionProps, ItemProps>;
      if (!sections.has(sectionId)) {
        const section = fulfillItem(
          sectionTransforms.makeGenericItem(sectionProps),
          sectionId,
          [],
        );

        const propsTree = {section: sectionProps, childItems: []};
        orderedSectionProps.push(propsTree);
        orderedItems.push(section);

        cachedSection = {propsTree, item: section};
        sections.set(sectionId, cachedSection);
        itemCache.set(sectionProps, section);
        userItemCache.set(section, d);
      } else {
        // We know this exists because we checked it above

        cachedSection = sections.get(sectionId)!;
      }

      cachedSection.propsTree.childItems.push(props);
      cachedSection.item.childItems?.push(item);
    }
  }

  return new ListCollectionWithData(
    caches,
    orderedItems,
    orderedProps,
    orderedSectionProps,
  );
}

// We're forced to use this in the generic to use TypeScript's
// builtin `Parameters` utility
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParametersWithoutFirst<T extends (...args: any) => any> =
  Parameters<T> extends [unknown, ...infer Rest] ? Rest : [];

export function useListCollectionWithData<
  T extends object,
  ItemProps extends object,
  ItemDefaultField extends ItemPropsKeys<ItemProps>,
  SectionProps extends object,
  SectionIdField extends ItemKeys<ExtractKeysOfType<SectionProps, string>>,
>(
  ...args: ParametersWithoutFirst<
    typeof makeListCollectionWithData<
      T,
      ItemProps,
      ItemDefaultField,
      SectionProps,
      SectionIdField
    >
  >
): ListCollection<ItemProps, SectionProps> {
  const data = args[0];

  // Data is heavily cached throughout the pipeline. Each item's representation
  // will be cached based off the item instance.
  const caches = useMemo(
    () => ({
      propsCache: new WeakMap(),
      itemCache: new WeakMap(),
      renderCache: new WeakMap(),
      userItemCache: new WeakMap(),
    }),
    [],
  );

  return useMemo(
    () => {
      return makeListCollectionWithData(caches, ...args);
    },
    // We assume the item and section mappings to be "uncontrolled" by default:
    // no future changes to them will be reflected. We only use the first
    // version. This is avoid cache misses in the super common case where the
    // mappings are specific as inline functions or objects, so they are
    // recreated each time.
    //
    // In the future, we will provide a way to opt-out of this behavior and
    // allow dynamic mappings where the user must memoize them.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );
}
