import {createTokens, resolveToken} from '@sail/engine';
import type {Token} from '@sail/engine';
import {createColorStates, createColorRoot, hexWithAlpha} from './utils';
import type {ColorSpectrum, ColorStates} from './types';

const hue = createTokens(
  {path: 'color.hue', schema: {} as Record<string, ColorSpectrum>},
  {
    gray: {
      0: '#ffffff',
      25: '#f5f6f8',
      50: '#ebeef1',
      100: '#d8dee4',
      200: '#b6c0cd',
      300: '#99a5b8',
      400: '#818da0',
      500: '#6c7688',
      600: '#596171',
      700: '#474e5a',
      800: '#353a44',
      900: '#21252c',
    },
    blue: {
      0: '#ffffff',
      25: '#e2fbfe',
      50: '#cbf5fd',
      100: '#a7e7fc',
      200: '#6dc9fc',
      300: '#3babfd',
      400: '#088ef9',
      500: '#0072e9',
      600: '#045ad0',
      700: '#0b46ad',
      800: '#0d3485',
      900: '#0a2156',
    },
    green: {
      0: '#ffffff',
      25: '#eafcdd',
      50: '#d1fab3',
      100: '#a8f170',
      200: '#7cd548',
      300: '#58ba27',
      400: '#3da00b',
      500: '#2b8700',
      600: '#217005',
      700: '#1c5a0d',
      800: '#184310',
      900: '#112a0d',
    },
    orange: {
      0: '#ffffff',
      25: '#fdf8c9',
      50: '#fceeb5',
      100: '#fbd992',
      200: '#fcaf4f',
      300: '#f7870f',
      400: '#e46602',
      500: '#cc4b00',
      600: '#b13600',
      700: '#922700',
      800: '#701b01',
      900: '#4a0f02',
    },
    red: {
      0: '#ffffff',
      25: '#fef4f6',
      50: '#fde9ee',
      100: '#fbd3dc',
      200: '#faa9b8',
      300: '#fa7e91',
      400: '#fa4a67',
      500: '#e61947',
      600: '#c0123c',
      700: '#9b0c36',
      800: '#76072f',
      900: '#4e0322',
    },
    purple: {
      0: '#ffffff',
      25: '#f7f5fd',
      50: '#efecfc',
      100: '#e0d9fb',
      200: '#c3b6fb',
      300: '#a497fc',
      400: '#857afe',
      500: '#675dff',
      600: '#533afd',
      700: '#4e11e2',
      800: '#44139f',
      900: '#2f0e63',
    },
  },
);

const neutral = createTokens('color.neutral', hue.gray);
const brand = createTokens('color.brand', hue.purple);

const feedback = createTokens('color.feedback', {
  attention: hue.orange,
  critical: hue.red,
  /**
   * The info feedback colors are neutral in sentiment but provide additional
   * visual emphasis to highlight supplemental content for the user.
   *
   * A good example of informational feedback is a focus state—it doesn’t
   * indicate success, a warning, or any urgency. It’s simply meant to be visible
   * and stand out from the neutral palette of the UI surface.
   */
  info: hue.blue,
  success: hue.green,
  trendNegative: hue.red,
  trendPositive: hue.green,
});

const chart = createTokens('color.chart', {
  categorical: {
    1: '#9966FF',
    2: '#0055BC',
    3: '#00A1C2',
    4: '#ED6804',
    5: '#B3063D',
  },
});

const surface = createTokens('color.background.surface', neutral[0]);

const background = createTokens('color.background', {
  offset: neutral[25],
  surface,
  backdrop: hexWithAlpha(resolveToken(neutral[200]) as string, 0.7),
  action: {
    primary: {
      $default: brand[500],
      hovered: brand[500],
      pressed: brand[600],
      focused: brand[500],
      disabled: brand[500],
    },
    secondary: {
      $default: surface,
      hovered: surface,
      pressed: neutral[25],
      focused: surface,
      disabled: surface,
    } as unknown as Token.Map<ColorStates>,
    destructive: {
      $default: feedback.critical[500],
      hovered: feedback.critical[500],
      pressed: feedback.critical[600],
      focused: feedback.critical[500],
      disabled: feedback.critical[500],
    },
  },
  form: {
    $default: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: neutral[50],
      focused: neutral[0],
      disabled: neutral[50],
    },
    accent: {
      $default: brand[500],
      hovered: brand[500],
      pressed: brand[600],
      focused: brand[500],
      disabled: neutral[100],
    },
  },
  onForm: {
    accent: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: neutral[0],
      focused: neutral[0],
      disabled: neutral[25],
    },
  },
  feedback: {
    neutral: {
      $default: neutral[50],
      subdued: neutral[25],
      emphasized: neutral[500],
    },
    info: {
      $default: feedback.info[50],
      subdued: feedback.info[25],
      emphasized: feedback.info[500],
    },
    success: {
      $default: feedback.success[50],
      subdued: feedback.success[25],
      emphasized: feedback.success[500],
    },
    attention: {
      $default: feedback.attention[50],
      subdued: feedback.attention[25],
      emphasized: feedback.attention[500],
    },
    critical: {
      $default: feedback.critical[50],
      subdued: feedback.critical[25],
      emphasized: feedback.critical[500],
    },
  },
});

const defaultBorder = createTokens('color.border', neutral[100]);

const border = createTokens('color.border', {
  $default: defaultBorder,
  action: {
    primary: {
      $default: brand[500],
      hovered: brand[700],
      pressed: brand[700],
      focused: brand[500],
      disabled: brand[500],
    },
    secondary: {
      $default: defaultBorder,
      hovered: neutral[300],
      pressed: defaultBorder,
      focused: defaultBorder,
      disabled: defaultBorder,
    } as unknown as Token.Map<ColorStates>,
    destructive: {
      $default: feedback.critical[500],
      hovered: feedback.critical[700],
      pressed: feedback.critical[700],
      focused: feedback.critical[500],
      disabled: feedback.critical[500],
    },
  },
  form: {
    $default: {
      $default: neutral[100],
      hovered: neutral[300],
      pressed: neutral[100],
      focused: neutral[100],
      disabled: neutral[100],
    },
    error: {
      $default: feedback.critical[500],
      hovered: feedback.critical[600],
      pressed: feedback.critical[500],
      focused: feedback.critical[500],
      disabled: feedback.critical[500],
    },
    accent: {
      $default: brand[500],
      hovered: brand[600],
      pressed: brand[600],
      focused: brand[500],
      disabled: neutral[100],
    },
  },
  feedback: {
    neutral: {
      $default: neutral[100],
      subdued: neutral[100],
      emphasized: neutral[500],
    },
    info: {
      $default: feedback.info[100],
      subdued: feedback.info[100],
      emphasized: feedback.info[500],
    },
    success: {
      $default: feedback.success[100],
      subdued: feedback.success[100],
      emphasized: feedback.success[500],
    },
    attention: {
      $default: feedback.attention[100],
      subdued: feedback.attention[100],
      emphasized: feedback.attention[500],
    },
    critical: {
      $default: feedback.critical[100],
      subdued: feedback.critical[100],
      emphasized: feedback.critical[500],
    },
  },
});

const defaultTextColor = createColorRoot(
  neutral as Token.Map<ColorSpectrum>,
  800 as Token.Key<ColorSpectrum>,
);

const defaultText = createTokens('color.text', {
  $default: defaultTextColor.root,
  subdued: defaultTextColor.decrement(2),
  emphasized: defaultTextColor.increment(1),
});

const text = createTokens('color.text', {
  $default: defaultText,
  action: {
    primary: createColorStates(
      brand,
      600 as Token.Key<ColorSpectrum>,
      neutral as Token.Map<ColorSpectrum>,
    ),
    // The `pressed` state token is invalid here because we can't increment Neutral 800 by 2 steps
    // so we're leaving it at 900 for now
    secondary: {
      $default: neutral[800],
      hovered: neutral[900],
      pressed: neutral[900],
      focused: neutral[800],
      disabled: neutral[600],
    },
    destructive: createColorStates(
      feedback.critical as Token.Map<ColorSpectrum>,
      600 as Token.Key<ColorSpectrum>,
      neutral as Token.Map<ColorSpectrum>,
    ),
  },
  onAction: {
    primary: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: brand[100],
      focused: neutral[0],
      disabled: neutral[0],
    } as unknown as Token.Map<ColorStates>,
    secondary: {
      $default: neutral[800],
      hovered: neutral[800],
      pressed: neutral[700],
      focused: neutral[800],
      disabled: neutral[800],
    } as unknown as Token.Map<ColorStates>,
    destructive: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: feedback.critical[100],
      focused: neutral[0],
      disabled: neutral[0],
    } as unknown as Token.Map<ColorStates>,
  },
  form: {
    $default: {
      $default: neutral[800],
      hovered: neutral[800],
      pressed: neutral[800],
      focused: neutral[800],
      disabled: neutral[400],
    },
    placeholder: neutral[400],
  },
  feedback: {
    info: {
      $default: feedback.info[600],
      subdued: feedback.info[600],
      emphasized: feedback.info[700],
    },
    success: {
      $default: feedback.success[600],
      subdued: feedback.success[600],
      emphasized: feedback.success[700],
    },
    attention: {
      $default: feedback.attention[600],
      subdued: feedback.attention[600],
      emphasized: feedback.attention[700],
    },
    critical: {
      $default: feedback.critical[600],
      subdued: feedback.critical[600],
      emphasized: feedback.critical[700],
    },
  },
  onFeedback: {
    neutral: {
      $default: neutral[600],
      subdued: neutral[600],
      emphasized: neutral[0],
    },
    info: {
      $default: feedback.info[600],
      subdued: feedback.info[600],
      emphasized: neutral[0],
    },
    success: {
      $default: feedback.success[600],
      subdued: feedback.success[600],
      emphasized: neutral[0],
    },
    attention: {
      $default: feedback.attention[600],
      subdued: feedback.attention[600],
      emphasized: neutral[0],
    },
    critical: {
      $default: feedback.critical[600],
      subdued: feedback.critical[600],
      emphasized: neutral[0],
    },
  },
});

const defaultIconColor = createColorRoot(
  neutral as Token.Map<ColorSpectrum>,
  700 as Token.Key<ColorSpectrum>,
);

const defaultIcon = createTokens('color.icon', {
  $default: defaultIconColor.root,
  subdued: defaultIconColor.decrement(2),
  emphasized: defaultIconColor.increment(1),
});

const icon = createTokens('color.icon', {
  $default: defaultIcon,
  action: {
    primary: createColorStates(
      brand,
      500 as Token.Key<ColorSpectrum>,
      neutral as Token.Map<ColorSpectrum>,
    ),
    secondary: createColorStates(
      defaultIconColor.scale,
      defaultIconColor.scaleValue,
      neutral as Token.Map<ColorSpectrum>,
    ),
    destructive: createColorStates(
      feedback.critical as Token.Map<ColorSpectrum>,
      500 as Token.Key<ColorSpectrum>,
      neutral as Token.Map<ColorSpectrum>,
    ),
  },
  onAction: {
    primary: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: brand[100],
      focused: neutral[0],
      disabled: neutral[0],
    },
    secondary: {
      $default: defaultIcon.$default,
      hovered: defaultIcon.$default,
      pressed: neutral[600],
      focused: defaultIcon.$default,
      disabled: defaultIcon.$default,
    } as unknown as Token.Map<ColorStates>,
    destructive: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: feedback.critical[100],
      focused: neutral[0],
      disabled: neutral[0],
    },
  },
  form: {
    $default: {
      $default: neutral[700],
      hovered: neutral[700],
      pressed: neutral[700],
      focused: neutral[700],
      disabled: neutral[300],
    },
  },
  onForm: {
    accent: {
      $default: neutral[0],
      hovered: neutral[0],
      pressed: neutral[0],
      focused: neutral[0],
      disabled: neutral[25],
    },
  },
  feedback: {
    info: {
      $default: feedback.info[500],
      subdued: feedback.info[500],
      emphasized: feedback.info[600],
    },
    success: {
      $default: feedback.success[500],
      subdued: feedback.success[500],
      emphasized: feedback.success[600],
    },
    attention: {
      $default: feedback.attention[500],
      subdued: feedback.attention[500],
      emphasized: feedback.attention[600],
    },
    critical: {
      $default: feedback.critical[500],
      subdued: feedback.critical[500],
      emphasized: feedback.critical[600],
    },
  },
  onFeedback: {
    neutral: {
      $default: neutral[500],
      subdued: neutral[500],
      emphasized: neutral[0],
    },
    info: {
      $default: feedback.info[500],
      subdued: feedback.info[500],
      emphasized: neutral[0],
    },
    success: {
      $default: feedback.success[500],
      subdued: feedback.success[500],
      emphasized: neutral[0],
    },
    attention: {
      $default: feedback.attention[500],
      subdued: feedback.attention[500],
      emphasized: neutral[0],
    },
    critical: {
      $default: feedback.critical[500],
      subdued: feedback.critical[500],
      emphasized: neutral[0],
    },
  },
});

export const color = createTokens('color', {
  hue,
  neutral,
  brand,
  feedback,
  chart,
  /**
   * Background color tokens are used to style the surfaces on which other UI elements are presented.
   *
   * There are 3 base background color tokens: `surface | offset | backdrop`
   */
  background,
  border,
  text,
  icon,
});
