import {ApolloProvider} from '@apollo/client';
import {useMemo} from 'react';
import {createApolloClient} from 'src/internal/apollo/client';
import DataConfigContext from 'src/internal/config/DataConfigContext';
import useOnChangedUser from 'src/internal/user/useOnChangedUser';

import type {ReactElement, ReactNode} from 'react';
import type {DataConfig} from 'src/internal/config/types';

type Props = {
  children: ReactNode;
  config: DataConfig;
};

function OnChangeLoggedUser({
  onChangeLoggedUser,
}: {
  onChangeLoggedUser: () => void;
}) {
  useOnChangedUser(onChangeLoggedUser);
  return null;
}

export default function DataProvider({children, config}: Props): ReactElement {
  const client = useMemo(
    () => (config.graphql ? createApolloClient(config.graphql) : null),
    [config],
  );

  if (!client) {
    return (
      <DataConfigContext.Provider value={config}>
        {children}
      </DataConfigContext.Provider>
    );
  }

  const handleChangedUser = config.onChangeLoggedUser;

  return (
    <DataConfigContext.Provider value={config}>
      <ApolloProvider client={client}>{children}</ApolloProvider>

      {handleChangedUser ? (
        <OnChangeLoggedUser onChangeLoggedUser={handleChangedUser} />
      ) : (
        <></>
      )}
    </DataConfigContext.Provider>
  );
}
