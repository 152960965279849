// This file was generated using `pay js:run gql-v2 --type=mutation --name=GenerateSecondaryHandoffUrl --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import type {ApolloError, GraphQlDocument} from '@sail/data';
import type {Exact} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  mutation GenerateSecondaryHandoffUrlMutation {
    generateSecondaryHandoffUrl {
      secondaryRedirectUrl
    }
  }
`;

export const generateSecondaryHandoffUrlMutation: GenerateSecondaryHandoffUrlMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const generateSecondaryHandoffUrlMutationOptions: GelatoMutationOptionsWithoutVariables<GenerateSecondaryHandoffUrlMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useGenerateSecondaryHandoffUrlMutation(
  options: GelatoMutationOptions<GenerateSecondaryHandoffUrlMutationDocument> = {},
): GelatoMutationReturn<GenerateSecondaryHandoffUrlMutationDocument> {
  return useGelatoMutation(generateSecondaryHandoffUrlMutation, {
    ...generateSecondaryHandoffUrlMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type GenerateSecondaryHandoffUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GenerateSecondaryHandoffUrlMutationData = {
  readonly __typename: 'Mutation';
  readonly generateSecondaryHandoffUrl: {
    readonly __typename: 'GenerateSecondaryHandoffUrlResult';
    readonly secondaryRedirectUrl: string;
  };
};

const generateSecondaryHandoffUrlMutationPermissions = {} as const;

doc.permissions = generateSecondaryHandoffUrlMutationPermissions;

export type GenerateSecondaryHandoffUrlMutationPermissions =
  GelatoPermissionToken<typeof generateSecondaryHandoffUrlMutationPermissions>;

export type GenerateSecondaryHandoffUrlMutationDocument = GraphQlDocument<
  GenerateSecondaryHandoffUrlMutationData,
  GenerateSecondaryHandoffUrlMutationVariables,
  GenerateSecondaryHandoffUrlMutationPermissions
>;
