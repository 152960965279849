import type React from 'react';

import {useInteractAnywhere} from '../hooks/useInteractAnywhere';

import {type PopoverProps} from '../components/popover';
import {useIsWithinListBoxContext} from './utils';
import {useUiConfig} from '../providers/UiConfigProvider';
import {usePartialIframe} from './OverlayOverrides';

export const usePopoverIframe = (props: PopoverProps) => {
  const uiConfig = useUiConfig();
  const getUILayers = uiConfig?._unstable_getUILayers;
  const shouldSkipIframePortal = uiConfig?._unstable_shouldSkipIframePortal;
  const isListBoxPopover = useIsWithinListBoxContext();

  const handleClose = (event: React.SyntheticEvent) => {
    if (
      props.onClose &&
      event.target !== props.targetRef.current &&
      !props.targetRef.current?.contains(event.target as Node)
    ) {
      props.onClose();
    }
  };
  useInteractAnywhere(handleClose, props.targetRef.current?.ownerDocument);

  return usePartialIframe({
    ...props,
    // Search field popovers should not have mobile overrides applied, and be rendered as normal
    shouldSkipIframePortal: shouldSkipIframePortal && !isListBoxPopover,
    uiLayers: getUILayers ? getUILayers() : [],
  });
};
