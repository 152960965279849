import jscookie from 'js-cookie';
import type {CookieAttributes} from 'js-cookie';
import {PermissionsAwareStorageManager} from '../PermissionsAwareStorageManager';
import {EnforcementMode, RemoveCookieOptions, SetCookieOptions} from '../types';
import {isBrowser, isDomainMatch, log} from '../util';
import {CookieManifest} from './CookieManifest';
import {MonkeyPatcher} from './MonkeyPatcher';

/**
 * Configuration options which can be passed to the constructor of Cookies.
 */
export type CookiesConfig = {
  /**
   * By default, the library will make an XHR call to determine whether the user is currently
   * located in an "enforcement zone" where cookie permissions are required before setting cookies.
   * Passing a value for enforcementMode will skip this call and use the specified value instead.
   */
  enforcementMode?: EnforcementMode;
  /**
   * A map of domain overrides which should be applied to entries in the cookie manifest. This
   * is used for testing; for example, the jest tests for manage/frontend run with the URL
   * set to https://manage-jest.stripe/jest/page. So, passing {'stripe.com': 'manage-jest.stripe'}
   * will cause all cookies which would have been set on stripe.com to be instead set on
   * manage-jest.stripe.
   */
  domainOverrides?: {
    [domain: string]: string;
  };
};

/**
 * Allows reading and writing of cookies while honoring a user's cookie permissions.
 * @param {CookiesConfig} config Optional configuration options.
 */
export class Cookies extends PermissionsAwareStorageManager {
  /** @private */
  _domainOverrides: {
    [domain: string]: string;
  };

  /** @private */
  _documentCookiePatched: boolean;

  constructor(config: CookiesConfig = {}) {
    super(config);
    this._domainOverrides = config.domainOverrides || {};
    this._documentCookiePatched = false;

    // If the user has enabled the Global Privacy Control flag, remove all
    // advertising cookies.
    if (typeof navigator !== 'undefined') {
      const patchedNavigator = navigator as Navigator & {
        globalPrivacyControl: boolean | undefined;
      };

      if (patchedNavigator?.globalPrivacyControl) {
        // Iterate all of the cookies the user has access to in the browser,
        // and remove any that are advertising cookies.
        const cookies = jscookie.get();

        for (const cookieName of Object.keys(cookies)) {
          const cookie = CookieManifest.get(cookieName);

          // Only apply to cookies that are advertising cookies and are not
          // httpOnly. If the cookie is httpOnly, it's not accessible to
          // JavaScript, so we can't remove it.
          if (cookie?.category === 'advertising' && !cookie?.httpOnly) {
            this.remove(cookieName);
          }
        }
      }
    }
  }

  /**
   * Reads the value of the specified cookie.
   * @param {string} name The name of the cookie to read.
   * @returns The value of the cookie, or null if no value is set.
   */
  get(name: string): string | null {
    const cookie = CookieManifest.get(name);

    if (!cookie) {
      log.warn(
        `No cookie matching the name ${name} was found in the cookies.yaml or cookies-next.yaml manifests. ` +
          `Reading the value of the cookie will work, but attempting to set the cookie ` +
          `will result in an error. If you're adding a new cookie, please visit go/cookies ` +
          `for more information!`,
      );
    }

    const value = jscookie.get(name);
    return value === undefined ? null : value;
  }

  /**
   * Attempts to write the specified value to the specified cookie. This will
   * only succeed if:
   *
   *   1. The cookie belongs to a necessary category, OR
   *   2. The user has set permissions which allow cookies of the category
   *      to which the cookie belongs, OR
   *   3. The enforcement mode is currently set to "open", indicating that
   *      the current user is not located within an enforcement zone.
   *
   * @param {string} name The name of the cookie to write.
   * @param {string} value The value to write for the cookie.
   * @param {SetCookieOptions} options Options for setting the cookie.
   * @returns A promise for a value which indicates whether an attempt to set
   *   the cookie was made. It does not indicate whether the cookie was
   *   actually set into the browser.
   */
  async set(
    name: string,
    value: string,
    options: SetCookieOptions = {},
  ): Promise<boolean> {
    const cookie = CookieManifest.get(name);

    if (!cookie) {
      log.error(
        `No cookie matching the name ${name} was found in the cookies.yaml or cookies-next.yaml manifests. ` +
          `If you're adding a new cookie, please visit go/cookies for more information!`,
      );
      return false;
    }

    if (cookie.httpOnly) {
      log.error(
        `Cannot set the cookie ${name} via JavaScript, since it is marked HttpOnly. ` +
          `Please visit go/cookies for more information!`,
      );
      return false;
    }

    let domain;
    let expires;

    try {
      domain = cookie.resolveDomain(options.domain, this._domainOverrides);
      expires = cookie.resolveExpiry(options.lifetime);
    } catch (error: unknown) {
      if (error instanceof Error) {
        log.error(error.message);
      } else {
        log.error('Unexpected error', error);
      }
      return false;
    }

    // If the cookie is in a category that isn't allowed for the user, block it.
    const isAllowed = await this.isCategoryAllowed(cookie.category);
    if (!isAllowed) {
      log.warn(
        `Attempting to set cookie ${name} without the correct permissions: ${cookie.category} ` +
          `Please accept cookies and try again.`,
      );
      return false;
    }

    // Only conduct a cookie domain match check if the cookie doesn't start
    // with __Host-. These cookies should not be set with a domain by browser
    // spec.
    // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/06-Session_Management_Testing/02-Testing_for_Cookies_Attributes#host-prefix
    if (
      isBrowser &&
      !cookie.name.startsWith('__Host-') &&
      !isDomainMatch(window.location.hostname, domain)
    ) {
      log.warn(
        `The cookie ${name} will be set on the domain ${domain}, which doesn't match ` +
          `the current domain (${window.location.hostname}). This will result in the ` +
          `cookie being silently ignored by the browser. Please check to ensure the ` +
          `domain(s) for the cookie are correct in cookies[-next].yaml, or visit go/cookies ` +
          `for more information.`,
      );
    }

    // Override secure attribute if defined in options
    const secure =
      options.secure === undefined ? cookie.secure : options.secure;

    const attributes = this._getCookieAttributes({
      domain,
      expires,
      secure,
      sameSite: cookie.sameSite,
    });

    // __Host- cookie paths must have a value of / so it would be sent to every request to the host.
    if (cookie.name.startsWith('__Host-')) {
      attributes.path = '/';
    }

    jscookie.set(name, value, attributes);

    return true;
  }

  /**
   * Deletes the value for the specified cookie.
   * @param {string} name The name of the cookie to delete.
   * @param {RemoveCookieOptions} options Options for removing the cookie.
   * @returns True if the cookie was successfully removed, otherwise false.
   */
  remove(name: string, options: RemoveCookieOptions = {}): boolean {
    const cookie = CookieManifest.get(name);

    if (!cookie) {
      log.error(
        `No cookie matching the name ${name} was found in the cookies.yaml or cookies-next.yaml manifests. ` +
          `If you're adding a new cookie, please visit go/cookies for more information!`,
      );
      return false;
    }

    let domain;

    try {
      domain = cookie.resolveDomain(options.domain, this._domainOverrides);
    } catch (error: unknown) {
      if (error instanceof Error) {
        log.error(error.message);
      } else {
        log.error('Unexpected error', error);
      }
      return false;
    }

    if (isBrowser && !isDomainMatch(window.location.hostname, domain)) {
      log.warn(
        `The cookie ${name} will be set on the domain ${domain}, which doesn't match ` +
          `the current domain (${window.location.hostname}). This will result in the ` +
          `cookie being silently ignored by the browser. Please check to ensure the ` +
          `domain(s) for the cookie are correct in cookies[-next].yaml, or visit go/cookies ` +
          `for more information.`,
      );
    }

    const attributes = this._getCookieAttributes({
      domain,
      secure: cookie.secure,
      sameSite: cookie.sameSite,
    });

    jscookie.remove(name, attributes);

    return true;
  }

  /**
   * This function checks the cookies keys and sees if the user has consented
   * to each value. If not, remove the offending item.
   */
  async refresh() {
    Object.keys(jscookie.get()).forEach((name) => {
      const item = CookieManifest.get(name);

      // The existing logic implicitly considered values outside of the
      // manifest are categorized as necessary. See the get method warning for
      // more context.
      if (item && jscookie.get(name)) {
        const categoryAllowed = this.isCategoryAllowedMaybeSync(item.category);

        // If the item is in a category that isn't allowed for the user, remove it.
        if (categoryAllowed === false) {
          this.remove(name);
        }

        if (categoryAllowed instanceof Promise) {
          categoryAllowed.then((value) => {
            if (!value) {
              this.remove(name);
            }
          });
        }
      }
    });
  }

  /**
   * Attempts to patch the document.cookie property to trap attempts to set cookies
   * directly, routing through the Cookies instance instead. This can be used to
   * guarantee compliance with the cookie manifest, but it should be used VERY CAREFULLY,
   * and is not a replacement for using Cookies.set() for cookies that we control.
   */
  trapDocumentCookie() {
    if (!this._documentCookiePatched) {
      this._documentCookiePatched = MonkeyPatcher.patchDocumentCookie(this);
    }
  }

  /**
   * Reverses trapDocumentCookie(), and stops trapping attempts to set cookies directly.
   */
  untrapDocumentCookie() {
    if (this._documentCookiePatched) {
      MonkeyPatcher.restoreDocumentCookie();
      this._documentCookiePatched = false;
    }
  }

  /**
   * @private
   */
  _getCookieAttributes(attrs: CookieAttributes) {
    const attributes = {...attrs};

    // If the document.cookie monkey-patch is active, all attempts to set cookies
    // will be routed into set(). To avoid an infinite loop, we set an additional
    // attribute on the cookie to signal back to the monkey-patch that the set()
    // operation has already passed through the Cookies instance and it shouldn't
    // be routed back again.
    if (this._documentCookiePatched) {
      attributes.allowed = 'true';
    }

    return attributes;
  }
}
