import type {AriaMenuProps} from '@react-types/menu';
import type {
  DOMAttributes,
  KeyboardDelegate,
  FocusableElement,
} from '@react-types/shared';
import type {Key, RefObject} from 'react';
import {filterDOMProps, mergeProps} from '../utils';
import {useSelectableList} from '../selection';
import type {ReactAriaTreeState as TreeState} from '../../@react-stately/tree';

export interface MenuAria {
  /** Props for the menu element. */
  menuProps: DOMAttributes;
}

export interface AriaMenuOptions<T> extends Omit<AriaMenuProps<T>, 'children'> {
  /** Whether the menu uses virtual scrolling. */
  isVirtualized?: boolean;

  /**
   * An optional keyboard delegate implementation for type to select,
   * to override the default.
   */
  keyboardDelegate?: KeyboardDelegate;
}

interface MenuData {
  onClose?: () => void;
  onAction?: (key: Key) => void;
}

export const menuData = new WeakMap<TreeState<unknown>, MenuData>();

/**
 * Provides the behavior and accessibility implementation for a menu component.
 * A menu displays a list of actions or options that a user can choose.
 * @param props - Props for the menu.
 * @param state - State for the menu, as returned by `useListState`.
 */
export function useMenu<T>(
  props: AriaMenuOptions<T>,
  state: TreeState<T>,
  ref: RefObject<HTMLElement>,
): MenuAria {
  const {shouldFocusWrap = true, ...otherProps} = props;

  if (!props['aria-label'] && !props['aria-labelledby']) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'An aria-label or aria-labelledby prop is required for accessibility.',
      );
    }
  }

  const domProps = filterDOMProps(props, {labelable: true});
  const {listProps} = useSelectableList({
    ...otherProps,
    ref,
    selectionManager: state.selectionManager,
    collection: state.collection,
    disabledKeys: state.disabledKeys,
    shouldFocusWrap,
    linkBehavior: 'override',
  });

  menuData.set(state, {
    onClose: props.onClose,
    onAction: props.onAction,
  });

  return {
    menuProps: mergeProps(domProps, {
      role: 'menu',
      // this forces AT to move their cursors into any open sub dialogs, the sub dialogs contain hidden close buttons in order to come back to this level of the menu
      'aria-hidden': state.expandedKeys.size > 0 ? true : undefined,
      ...listProps,
      onKeyDown: (e: React.KeyboardEvent<FocusableElement>) => {
        // don't clear the menu selected keys if the user is presses escape since escape closes the menu
        if (e.key !== 'Escape') {
          listProps.onKeyDown?.(e);
        }
      },
    }),
  };
}
