import type {DOMAttributes, FocusableElement} from '@react-types/shared';
import type {Key, RefObject} from 'react';
import {isFocusVisible, useHover} from '../interactions';
import {getItemCount} from '../../@react-stately/collections';
import {filterDOMProps, isMac, isWebKit, mergeProps, useSlotId} from '../utils';
import type {SelectableItemStates} from '../selection';
import {useSelectableItem} from '../selection';
import type {ListState} from '../../@react-stately/list';
import {getItemId, listData} from './utils';

export interface OptionAria extends SelectableItemStates {
  /** Props for the option element. */
  optionProps: DOMAttributes;

  /** Props for the main text element inside the option. */
  labelProps: DOMAttributes;

  /** Props for the description text element inside the option, if any. */
  descriptionProps: DOMAttributes;

  /** Whether the option is currently focused. */
  isFocused: boolean;

  /** Whether the option is keyboard focused. */
  isFocusVisible: boolean;
}

export interface AriaOptionProps {
  /**
   * Whether the option is disabled.
   * @deprecated
   */
  isDisabled?: boolean;

  /**
   * Whether the option is selected.
   * @deprecated
   */
  isSelected?: boolean;

  /** A screen reader only label for the option. */
  'aria-label'?: string;

  /** The unique key for the option. */
  key: Key;

  /**
   * Whether selection should occur on press up instead of press down.
   * @deprecated
   */
  shouldSelectOnPressUp?: boolean;

  /**
   * Whether the option should be focused when the user hovers over it.
   * @deprecated
   */
  shouldFocusOnHover?: boolean;

  /**
   * Whether the option is contained in a virtual scrolling listbox.
   * @deprecated
   */
  isVirtualized?: boolean;

  /**
   * Whether the option should use virtual focus instead of being focused directly.
   * @deprecated
   */
  shouldUseVirtualFocus?: boolean;
}

/**
 * Provides the behavior and accessibility implementation for an option in a listbox.
 * See `useListBox` for more details about listboxes.
 * @param props - Props for the option.
 * @param state - State for the listbox, as returned by `useListState`.
 */
export function useOption<T>(
  props: AriaOptionProps,
  state: ListState<T>,
  ref: RefObject<FocusableElement>,
): OptionAria {
  const {key} = props;
  const data = listData.get(state);
  const isDisabled = props.isDisabled ?? state.disabledKeys.has(key);
  const isSelected = props.isSelected ?? state.selectionManager.isSelected(key);
  const shouldSelectOnPressUp =
    props.shouldSelectOnPressUp ?? data?.shouldSelectOnPressUp;
  const shouldFocusOnHover =
    props.shouldFocusOnHover ?? data?.shouldFocusOnHover;
  const shouldUseVirtualFocus =
    props.shouldUseVirtualFocus ?? data?.shouldUseVirtualFocus;
  const isVirtualized = props.isVirtualized ?? data?.isVirtualized;

  const labelId = useSlotId();
  const descriptionId = useSlotId();

  const optionProps: DOMAttributes = {
    role: 'option',
    'aria-disabled': isDisabled || undefined,
    'aria-selected':
      state.selectionManager.selectionMode !== 'none' ? isSelected : undefined,
  };

  // Safari with VoiceOver on macOS misreads options with aria-labelledby or aria-label as simply "text".
  // We should not map slots to the label and description on Safari and instead just have VoiceOver read the textContent.
  // https://bugs.webkit.org/show_bug.cgi?id=209279
  if (!(isMac() && isWebKit())) {
    optionProps['aria-label'] = props['aria-label'];
    optionProps['aria-labelledby'] = labelId;
    optionProps['aria-describedby'] = descriptionId;
  }

  const item = state.collection.getItem(key);
  if (isVirtualized) {
    const index = Number(item?.index);
    optionProps['aria-posinset'] = Number.isNaN(index) ? undefined : index + 1;
    optionProps['aria-setsize'] = getItemCount(state.collection);
  }

  const {itemProps, isPressed, isFocused, hasAction, allowsSelection} =
    useSelectableItem({
      selectionManager: state.selectionManager,
      key,
      ref,
      shouldSelectOnPressUp,
      allowsDifferentPressOrigin: shouldSelectOnPressUp && shouldFocusOnHover,
      isVirtualized,
      shouldUseVirtualFocus,
      isDisabled,
      onAction: data?.onAction ? () => data?.onAction?.(key) : undefined,
      linkBehavior: data?.linkBehavior,
    });

  const {hoverProps} = useHover({
    isDisabled: isDisabled || !shouldFocusOnHover,
    onHoverStart() {
      if (!isFocusVisible()) {
        state.selectionManager.setFocused(true);
        state.selectionManager.setFocusedKey(key);
      }
    },
  });

  const domProps = filterDOMProps(item?.props, {isLink: !!item?.props?.href});
  delete domProps.id;

  return {
    optionProps: {
      ...optionProps,
      ...mergeProps(domProps, itemProps, hoverProps),
      id: getItemId(state, key),
    },
    labelProps: {
      id: labelId,
    },
    descriptionProps: {
      id: descriptionId,
    },
    isFocused,
    isFocusVisible: isFocused && isFocusVisible(),
    isSelected,
    isDisabled,
    isPressed,
    allowsSelection,
    hasAction,
  };
}
