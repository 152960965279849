import {useState, useCallback, useMemo} from 'react';
import type {Item, RegisterableCollection} from './types';
import {
  useIndexedChildren,
  compareIndexPaths,
  treeFromItems,
} from './useIndexedChildren';

/**
 * This is the starting point for creating a component that uses collections.
 * Use this in the top-level component to create the data structure that holds
 * items. `useItem` is complementary to this and will be used inside item
 * components to register items.
 */
export function useRegisterableCollection(
  children: React.ReactNode,
): RegisterableCollection {
  const [version, setVersion] = useState(Math.random());
  const itemMap = useMemo(() => new Map(), []);

  const register = useCallback(
    (key, item) => {
      itemMap.set(key, item);
      setVersion(Math.random());
      return () => {
        itemMap.delete(key);
        setVersion(Math.random());
      };
    },
    [itemMap],
  );

  const getItems = useMemo(() => {
    let cache: Item[] | null = null;

    return () => {
      if (cache) {
        return cache;
      }

      // We trick React into thinking we depend on `version` so we
      // can control when `getItems` is recreated. We don't recreate
      // `itemMap` so we can't use it as a signal to recreate
      // `getItems`. The goal is to avoid constantly recreating
      // the `Map` for `itemMap`.
      //
      // eslint-disable-next-line no-unused-expressions
      version;

      const items = Array.from(itemMap.entries())
        .sort((a, b) =>
          compareIndexPaths(a[1].indexPathString, b[1].indexPathString),
        )
        .map((entry) => entry[1]);

      cache = treeFromItems(items) as Item[];
      return cache;
    };
  }, [itemMap, version]);

  const indexedChildren = useIndexedChildren(children);

  return {
    register,
    getItems,
    render() {
      return indexedChildren;
    },
  };
}
