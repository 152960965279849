import {createKeyframes, createView, createViewConfig} from '@sail/engine';
import {css, Icon} from '@sail/ui';
import React, {useEffect, useState} from 'react';

import type {IconAsset, SvgType} from '@sail/icons/types';
import type {View, IconProps} from '@sail/ui';

const fadeIn = createKeyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const rotate = createKeyframes({
  0: {
    transform: 'scaleX(-1) rotateZ(0)',
  },
  50: {
    transform: 'scaleX(-1) rotateZ(-180deg)',
  },
  100: {
    transform: 'scaleX(-1) rotateZ(-360deg)',
  },
});

/**
 * @external
 */
export interface SpinnerProps extends Omit<IconProps, 'icon' | 'size'> {
  /**
   * Delay applied to animation
   * @external
   */
  delay?: number;

  /**
   * Size of Spinner component
   * @external
   */
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

const SpinnerAsset = createView((props: View.IntrinsicElement<'svg'>) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M52.5 28C52.5 23.1544 51.0631 18.4175 48.371 14.3885C45.6789 10.3595 41.8525 7.2193 37.3757 5.36495C32.899 3.5106 27.9728 3.02542 23.2203 3.97076C18.4678 4.9161 14.1023 7.2495 10.6759 10.6759C7.2495 14.1023 4.9161 18.4678 3.97076 23.2203C3.02542 27.9728 3.5106 32.899 5.36495 37.3757C7.2193 41.8525 10.3595 45.6789 14.3885 48.371C18.4175 51.0631 23.1544 52.5 28 52.5"
        stroke="url(#paint0_linear_6699_10004)"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6699_10004"
          x1="52.5"
          y1="15.6506"
          x2="16.4943"
          y2="60.9585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F566B" />
          <stop offset="0.427083" stopColor="#4F566B" stopOpacity="0.5573" />
          <stop offset="1" stopColor="#4F566B" stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  );
});

const spinnerIcon: IconAsset = {
  category: 'icon',
  colorable: true,
  name: 'spinner',
  svg: SpinnerAsset as SvgType,
};

export const SpinnerConfig = createViewConfig({
  props: {} as SpinnerProps,
  name: 'Spinner',
  defaults: {
    size: 'medium',
  },
});

const Spinner = SpinnerConfig.createView(
  ({delay, ...props}) => {
    const [shown, setShown] = useState(!delay);

    useEffect(() => {
      if (delay) {
        const timeout = setTimeout(() => {
          setShown(true);
        }, delay);

        return () => clearTimeout(timeout);
      }
    }, [delay, setShown]);

    if (!shown) {
      return null;
    }

    return (
      <Icon role="status" aria-label="Loading" {...props} icon={spinnerIcon} />
    );
  },
  {
    css: {
      animation: `${fadeIn} 250ms normal ease, ${rotate} .7s linear infinite`,
      transitionProperty: 'opacity, transform',
      transitionTimingFunction: 'ease',
      transformOrigin: '50% 50%',
      fill: 'transparent',
    },
    variants: {
      size: {
        small: css({
          height: 'space.150',
          width: 'space.150',
        }),
        medium: css({
          height: 'medium',
          width: 'medium',
        }),
        large: css({
          height: 'large',
          width: 'large',
        }),
        xlarge: css({
          height: 'xlarge',
          width: 'xlarge',
        }),
        xxlarge: css({
          height: 'space.700',
          width: 'space.700',
        }),
      },
    },
  },
);

export default Spinner;
