// This file was generated using `pay js:run gql-v2 --type=fragment --name=SessionDynamic --dir=path/to/dir`.
// To generate yours, do not copy and paste; use the command above instead. Do not delete this comment.

import {graphql} from '@sail/data';

import type {GraphQlDocument} from '@sail/data';
import type {
  Exact,
  VerificationErrorCode,
  VerificationIntentErrorDetail,
  IndividualFields,
  SessionOperatingMode,
  VerificationIntentStatus,
  ConsentPermissionGiven,
  SelfieVerificationMethod,
  ConsentVersions,
  Country,
  DocumentTypes,
  NetworkedDirection,
} from '@stripe-internal/data-gelato/schema/types';
import type {GelatoPermissionToken} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  fragment SessionDynamicFragment on Session {
    closed
    collectedData {
      individual {
        address {
          city
          country
          line1
          line2
          state
          zip
        }
        consent {
          accepted
          permissions
          selfieVerificationMethod
          version
        }
        dob {
          day
          month
          year
        }
        email {
          merchantProvidedAddress
          otpDeclined
          userProvidedAddress
        }
        face {
          image
        }
        idDocument {
          back
          country
          docTypeHasMbDecodableBack
          forceSkipBackImage
          front
          mbFrontDecoded
          type
        }
        idNumber {
          country
        }
        name {
          firstName
          lastName
        }
        phoneNumber {
          merchantProvidedPhoneNumber
          otpDeclined
          userProvidedPhoneNumber
        }
      }
    }
    emailForHandoff
    ipCountry
    ipCountrySupportable
    lastErrorCode
    lastErrorDetails
    missingFields
    operatingMode
    phoneForHandoff
    redacted
    requiredFields
    sanctionedDocumentCountry
    status
    submitted
    underConsentAge
    unsupportedCountry
    disableImageValidation
    networkingData {
      consentsToDocumentNetworking
      networkedDirection
      skipsDocumentNetworking
    }
  }
`;

export const sessionDynamicFragment: SessionDynamicFragmentDocument = doc;

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SessionDynamicFragmentData = {
  readonly __typename: 'Session';
  readonly closed: boolean;
  readonly emailForHandoff: string | null;
  readonly ipCountry: string | null;
  readonly ipCountrySupportable: boolean;
  readonly lastErrorCode: VerificationErrorCode | null;
  readonly lastErrorDetails: VerificationIntentErrorDetail | null;
  readonly missingFields: ReadonlyArray<IndividualFields>;
  readonly operatingMode: SessionOperatingMode;
  readonly phoneForHandoff: string | null;
  readonly redacted: boolean;
  readonly requiredFields: ReadonlyArray<IndividualFields>;
  readonly sanctionedDocumentCountry: boolean | null;
  readonly status: VerificationIntentStatus;
  readonly submitted: boolean;
  readonly underConsentAge: boolean | null;
  readonly unsupportedCountry: boolean | null;
  readonly disableImageValidation: boolean | null;
  readonly collectedData: {
    readonly __typename: 'CollectedData';
    readonly individual: {
      readonly __typename: 'IndividualData';
      readonly address: {
        readonly __typename: 'AddressData';
        readonly city: string | null;
        readonly country: string | null;
        readonly line1: string | null;
        readonly line2: string | null;
        readonly state: string | null;
        readonly zip: string | null;
      };
      readonly consent: {
        readonly __typename: 'ConsentData';
        readonly accepted: boolean | null;
        readonly permissions: ReadonlyArray<ConsentPermissionGiven> | null;
        readonly selfieVerificationMethod: SelfieVerificationMethod | null;
        readonly version: ConsentVersions | null;
      };
      readonly dob: {
        readonly __typename: 'DobData';
        readonly day: string | null;
        readonly month: string | null;
        readonly year: string | null;
      };
      readonly email: {
        readonly __typename: 'EmailData';
        readonly merchantProvidedAddress: string | null;
        readonly otpDeclined: boolean | null;
        readonly userProvidedAddress: string | null;
      };
      readonly face: {
        readonly __typename: 'FaceData';
        readonly image: string | null;
      };
      readonly idDocument: {
        readonly __typename: 'DocumentData';
        readonly back: string | null;
        readonly country: Country | null;
        readonly docTypeHasMbDecodableBack: boolean | null;
        readonly forceSkipBackImage: boolean | null;
        readonly front: string | null;
        readonly mbFrontDecoded: boolean | null;
        readonly type: DocumentTypes | null;
      };
      readonly idNumber: {
        readonly __typename: 'IdNumberData';
        readonly country: Country | null;
      };
      readonly name: {
        readonly __typename: 'NameData';
        readonly firstName: string | null;
        readonly lastName: string | null;
      };
      readonly phoneNumber: {
        readonly __typename: 'PhoneNumberData';
        readonly merchantProvidedPhoneNumber: string | null;
        readonly otpDeclined: boolean | null;
        readonly userProvidedPhoneNumber: string | null;
      };
    };
  };
  readonly networkingData: {
    readonly __typename: 'NetworkingData';
    readonly consentsToDocumentNetworking: boolean | null;
    readonly networkedDirection: NetworkedDirection | null;
    readonly skipsDocumentNetworking: boolean | null;
  };
};

export type SessionDynamicFragmentVariables = Exact<{[key: string]: never}>;

const sessionDynamicFragmentPermissions = {} as const;

doc.permissions = sessionDynamicFragmentPermissions;

export type SessionDynamicFragmentPermissions = GelatoPermissionToken<
  typeof sessionDynamicFragmentPermissions
>;

export type SessionDynamicFragmentDocument = GraphQlDocument<
  SessionDynamicFragmentData,
  SessionDynamicFragmentVariables,
  SessionDynamicFragmentPermissions
>;
