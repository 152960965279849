import {createContext, useContext, useMemo} from 'react';
import type {Key} from 'react';
import type {Node} from '@react-types/shared';
import type {TreeState} from '@sail/react-aria';
import {useCollectionState} from '@sail/react-aria';
import {FauxTreeItem} from '../components/FauxTreeItem';

type GroupItem = {
  selectedCount: number;
  totalCount: number;
  selectedForeignCount: number;
  checkableKeys: Key[];
  uncheckableKeys: Key[];
  isDisabled: boolean;
};

type SelectAllItem = {
  selectedCount: number;
  totalCount: number;
  selectedHiddenCount: number;
  checkableKeys: Key[];
  uncheckableKeys: Key[];
  isDisabled: boolean;
};

type DomItems = {
  groupedDomItems: {
    [k: Key]: Set<Key>;
  };
  allDomItems: Key[];
};

type DataItems = {
  groupedDataItems: {
    [k: Key]: Set<Key>;
  };
  allDataItems: Key[];
};

const FauxTreeProviderContext = createContext<
  React.ReactNode | null | undefined
>(null);

export const FauxTreeProvider = FauxTreeProviderContext.Provider;

export function useDomItems(): React.ReactNode {
  return useContext(FauxTreeProviderContext) || [];
}

function getDataItems(dataCollection: TreeState['collection']) {
  const groupedItems = {} as {[k: Key]: Set<Key>};
  let previousItems = new Set<Key>();
  const allItems = new Set<Key>();

  const collectionKeys = [...dataCollection.getKeys()];

  for (let i = 0; i < collectionKeys.length; i++) {
    const currentKey = collectionKeys[i];
    const currentItem = dataCollection.getItem(currentKey);

    if (currentItem) {
      if (currentItem.type === 'section') {
        if (typeof currentItem.childNodes !== 'undefined') {
          const childItems =
            [...(currentItem.childNodes ?? [])].map((item) => item.key) ?? [];
          childItems.forEach((childItem: Key) => allItems.add(childItem));
          groupedItems[currentItem.key] = new Set(childItems);
        } else {
          // Note: if the section does not have childNodes, it's
          // because the previous ones are the children.
          groupedItems[currentItem.key] = new Set(previousItems);
          previousItems = new Set<Key>();
        }
      } else {
        previousItems.add(currentItem.key);
        allItems.add(currentItem.key);
      }
    }
  }

  return {groupedDataItems: groupedItems, allDataItems: [...allItems]};
}

export function getFullDataItems(dataCollection: TreeState['collection']) {
  const allItems = new Map<Key, Node<unknown>>();

  const collectionKeys = [...dataCollection.getKeys()];

  for (let i = 0; i < collectionKeys.length; i++) {
    const currentKey = collectionKeys[i];
    const currentItem = dataCollection.getItem(currentKey);

    if (currentItem) {
      if (currentItem.type === 'section') {
        if (typeof currentItem.childNodes !== 'undefined') {
          const childItems = [...(currentItem.childNodes ?? [])];
          childItems.forEach((childItem) => {
            allItems.set(childItem.key, childItem);
          });
        }
      } else {
        allItems.set(currentKey, currentItem);
      }
    }
  }

  return allItems;
}

function getDomItems(domItems: React.ReactNode): DomItems {
  // Note: If the FauxTree sets DOM elements to render items, use them to select
  // all the elements
  const groupedDomItems = {} as {[k: Key]: Set<Key>};
  const allDomItems: Key[] = [];

  if (Array.isArray(domItems)) {
    domItems.forEach((element) => {
      if (element?.type === FauxTreeItem) {
        allDomItems.push(element.props.id);
      } else {
        const groupId = element.props.id;
        if (Array.isArray(element.props.children)) {
          const childItems = element.props.children.flatMap(
            (child: {props: {id: Key}}) => child.props?.id ?? [],
          );
          groupedDomItems[groupId] = new Set(childItems);
          allDomItems.push(...childItems);
        }
      }
    });
  }

  return {groupedDomItems, allDomItems};
}

function getGroupedItems(
  dataItems: DataItems,
  domItems: DomItems,
  selectedItems: Set<Key>,
  disabledItems: Set<Key>,
) {
  // Step 1: Calculate all the data items and group them.
  let {groupedDataItems: groupedItems, allDataItems: allItems} = dataItems;

  // Step 2: If there are elements in DOM, use them instead of
  // the data collection.
  if (domItems.allDomItems.length) {
    groupedItems = domItems.groupedDomItems;
    allItems = domItems.allDomItems;
  }

  // Step 3: Calculate selected items that are visible (if the data is filtered, i.e. faux tree in a search field).
  const selectedFilteredItems = [...selectedItems].filter((item) =>
    allItems.some((dataItem) => dataItem === item),
  );
  const selectedHiddenItems = [...selectedItems].filter(
    (item) => !selectedFilteredItems.some((dataItem) => dataItem === item),
  );
  const allItemsAreDisabled = allItems.every((item) => disabledItems.has(item));

  // Step 4: Calculate all the info needed for select all item.
  const selectAllItem: SelectAllItem = {
    selectedCount: selectedFilteredItems.length,
    totalCount: allItems.length,
    selectedHiddenCount: selectedHiddenItems.length,
    checkableKeys: allItems
      .filter((item) => !(disabledItems.has(item) && !selectedItems.has(item)))
      .concat(selectedHiddenItems),
    uncheckableKeys: [...disabledItems]
      .filter((item) => selectedItems.has(item))
      .concat(selectedHiddenItems),
    isDisabled: allItemsAreDisabled,
  };

  // Step 5: Calculate all the info needed for select group items.
  const selectGroupItems = new Map<Key, GroupItem>();
  const groups = Object.keys(groupedItems);

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    const childItems = [...groupedItems[group]];

    // Note: we need to keep the previous state of the rest of the groups
    // when we are checking or unchecking the "select group"
    const selectedItemsOutsideGroup = [...selectedItems].filter(
      (item) => !groupedItems[group].has(item),
    );
    const allGroupItemsAreDisabled = childItems.every((item) =>
      disabledItems.has(item),
    );

    selectGroupItems.set(group, {
      selectedCount: childItems.filter((item) => selectedItems.has(item))
        .length,
      totalCount: childItems.length,
      selectedForeignCount: selectedItemsOutsideGroup.length,
      checkableKeys: childItems
        .filter(
          (item) => !(disabledItems.has(item) && !selectedItems.has(item)),
        )
        // Note: we have to preserved the rest of unchecked checkboxes outside of this group as unchecked
        .concat(selectedItemsOutsideGroup),
      isDisabled: allGroupItemsAreDisabled,
      uncheckableKeys: childItems
        .filter((item) => disabledItems.has(item) && selectedItems.has(item))
        // Note: we have to preserve the checked checkboxes outside of this group as checked
        .concat(selectedItemsOutsideGroup),
    });
  }

  return {selectGroupItems, selectAllItem};
}

export default function useFauxTreeState() {
  const state = useCollectionState();
  const treeCollection = (state as TreeState).collection;

  const originalDomItems = useDomItems();

  const dataItems = useMemo(
    () => getDataItems(treeCollection),
    [treeCollection],
  );

  const domItems = useMemo(
    () => getDomItems(originalDomItems),
    [originalDomItems],
  );

  const selectedItems = state.selectionManager.selectedKeys as Set<Key>;
  const disabledItems = state.selectionManager.disabledKeys as Set<Key>;

  const {selectGroupItems, selectAllItem} = useMemo(
    () => getGroupedItems(dataItems, domItems, selectedItems, disabledItems),
    [dataItems, disabledItems, domItems, selectedItems],
  );

  const isMultipleSelection =
    state.selectionManager.selectionMode === 'multiple';

  return {
    isMultipleSelection,
    selectGroupItems,
    selectAllItem,
  };
}
