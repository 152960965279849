import * as React from 'react';
import {createViewConfig, view} from '@sail/engine';
import type {View} from '@sail/engine';
import {useCollectionState} from '@sail/react-aria';
import type {NativeCheckboxProps} from './Checkbox';
import {Checkbox} from './Checkbox';
import useFauxTreeState from '../hooks/useFauxTreeState';
import type {FormFieldProps} from './FormField';

const defaultSelectionText = (itemsCount: number) => `${itemsCount} selected`;

export type FauxTreeSelectAllItemProps = View.IntrinsicElement<
  'div',
  {
    selectedText?: (itemsCount: number, allCount: number) => string;
    subviews?: FormFieldProps<NativeCheckboxProps>['subviews'];
    selectAllRef?: React.MutableRefObject<HTMLInputElement | null>;
  }
>;

export const FauxTreeSelectAllItemConfig = createViewConfig({
  name: 'FauxTreeSelectAllItem',
  props: {} as FauxTreeSelectAllItemProps,
  defaults: {
    selectedText: defaultSelectionText,
  },
});

export const FauxTreeSelectAllItem = FauxTreeSelectAllItemConfig.createView(
  (allProps) => {
    const {subviews, selectedText, selectAllRef, ...props} = allProps;

    const state = useCollectionState();

    const {selectAllItem, selectGroupItems} = useFauxTreeState();

    const {
      selectedCount,
      totalCount,
      selectedHiddenCount,
      checkableKeys,
      uncheckableKeys,
      isDisabled,
    } = selectAllItem;

    const areAllSelected = totalCount === selectedCount;
    const areAllEnabledSelected =
      checkableKeys.length - selectedHiddenCount === selectedCount;

    // TODO: Sail Checkbox has a visual bug in Chrome when the values of indeterminate and checked are modified in the same render.
    // This is a workaround to avoid it, but this bug must be fixed directly in Checkbox
    const [indeterminate, setIndeterminate] = React.useState(
      selectedCount !== 0 && !areAllSelected,
    );

    React.useEffect(() => {
      setIndeterminate(selectedCount !== 0 && !areAllSelected);
    }, [selectedCount, areAllSelected]);

    const allWildcard = state.selectionManager.rawSelection === 'all';

    React.useEffect(() => {
      // Note: If default selection was 'all' or cmd+a shortcut has been pressed.
      // we have to manually modify the state of the
      if (allWildcard) {
        state.selectionManager.setSelectedKeys(checkableKeys);
      }
    }, [allWildcard, checkableKeys, state.selectionManager]);

    return (
      <view.div
        {...props}
        css={{
          marginBottom: selectGroupItems.size > 0 ? 'space.50' : 0,
          cursor: !isDisabled ? 'pointer' : 'default',
        }}
      >
        <Checkbox
          tabIndex={-1}
          {...(selectAllRef ? {ref: selectAllRef} : {})}
          indeterminate={indeterminate}
          label={
            selectedText
              ? selectedText(selectedCount, totalCount)
              : defaultSelectionText(selectedCount)
          }
          checked={areAllSelected}
          disabled={isDisabled}
          subviews={{
            label: {
              css: {
                marginLeft: '-2px', // Customize gap between checkbox and label
                font: 'label.small.emphasized',
                color: 'subdued',
                cursor: !isDisabled ? 'pointer' : 'default',
              },
              ...subviews?.label,
            },
            input: {
              css: {
                ':focus': {
                  focusRing: 'none',
                },
              },
              ...subviews?.input,
            },
            ...subviews,
          }}
          onChange={() => {
            if (areAllEnabledSelected) {
              state.selectionManager.setSelectedKeys(uncheckableKeys);
            } else {
              state.selectionManager.setSelectedKeys(checkableKeys);
            }
          }}
        />
      </view.div>
    );
  },
  {
    css: {
      stack: 'x',
      font: 'label.small.emphasized',
      color: 'subdued',
      gap: 'space.75',
      borderRadius: 'xsmall',
      paddingY: 'space.75',
      paddingX: 'space.125',
      alignX: 'start',
      alignY: 'center',
      minHeight: 'xlarge',
    },
  },
);
