import {css, view, Spinner, Banner} from '@sail/ui';
import * as React from 'react';

import {useHandoffWarningSheet} from 'gelato/frontend/src/components/HandoffWarningSheet';
import LayerRoot from 'gelato/frontend/src/components/LayerRootV2';
import {
  deviceCss,
  tColorNormal,
  withPageTransitionAnimation,
  withScrollShadows,
  withoutPageTransitionAnimation,
} from 'gelato/frontend/src/components/stylesV2';
import TestModeBanner, {
  useTestMode,
} from 'gelato/frontend/src/components/TestModeBannerV2';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import experiments from 'gelato/frontend/src/lib/experiments';
import useAppCameraDevicesList from 'gelato/frontend/src/lib/hooks/useAppCameraDevicesList';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import useAppRedirectIfHasSessionError from 'gelato/frontend/src/lib/hooks/useAppRedirectIfHasSessionError';
import useElementSizeAsCSSVariables from 'gelato/frontend/src/lib/hooks/useElementSizeAsCSSVariables';
import usePreloadIDDetectors from 'gelato/frontend/src/lib/hooks/usePreloadIDDetectors';
import usePreloadInspectors from 'gelato/frontend/src/lib/hooks/usePreloadInspectors';
import usePreloadPagesForNextPageTransition from 'gelato/frontend/src/lib/hooks/usePreloadPagesForNextPageTransition';
import usePullToRefreshBlocker from 'gelato/frontend/src/lib/hooks/usePullToRefreshBlocker';
import useScrollableArea from 'gelato/frontend/src/lib/hooks/useScrollableArea';
import useViewportSizeAsCSSVariables from 'gelato/frontend/src/lib/hooks/useViewportSizeAsCSSVariables';
import {isInIframe, postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import {
  trackLoadTimeout,
  stopLoadTimeout,
} from 'gelato/frontend/src/lib/trackLoadTimeout';

const {useEffect, useLayoutEffect, useRef} = React;

type BodySectionProps = {
  alignBodyItems?: 'center';
  bodySectionStyles?: Array<ReturnType<typeof css>> | null | undefined;
};

export type PageCardProps = BodySectionProps & {
  'data-testid'?: string;
  body?: React.ReactNode | null;
  footer?: React.ReactNode | null;
  header?: React.ReactNode | null;
  loading?: boolean;
  redirectIfHasSessionError?: boolean;
  contentStyles?: Array<ReturnType<typeof css>> | null | undefined;
};

// The height of the modal dialog opened by StripeJS.
// This value should match the modal size "400x600" used at this file:
// stripe-js-v3/stripeJs/identity/actions/openIdentityFrame.ts.
const STRIPE_JS_MODAL_HEIGHT = 600;
const MODAL_TOP_MARGIN = 112;

// This is the minimum percent of the screen that it should take up vertically and will
// be used to recalculate the expected height of the card when contained w/in a modal
// This is taken by doing the following:
//   STRIPE_JS_MODAL_HEIGHT / (2 * MODAL_TOP_MARGIN + STRIPE_JS_MODAL_HEIGHT)
const STRIPE_JS_MODAL_VERTICAL_COVERAGE = 73;

const Styles = {
  alignBodyItemsCenter: css({
    alignX: 'center',
    alignY: 'center',
    gap: 'medium',
    stack: 'y',
  }),
  body: deviceCss({
    all: {
      padding: 'space.250',
      height: 'fill',
      zIndex: 1,
    },
    mobile: {
      // Mobile only
      overflowY: 'auto',
    },
  }),
  header: css({
    zIndex: 2,
  }),
  pageCard: deviceCss({
    all: {
      alignY: 'top',
      backgroundColor: 'white',
      position: 'relative',
      stack: 'y',
      zIndex: 0,
    },
    desktop: {
      // Desktop only
      borderRadius: '10px',
      boxShadow: 'medium',
      marginTop: `min(calc((var(--viewport-height, 100vh) - ${STRIPE_JS_MODAL_HEIGHT}px) / 2), ${MODAL_TOP_MARGIN}px)`,
      marginX: 'auto',
      overflow: 'hidden',
      width: '400px',
    },
    mobile: {
      // Mobile only
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none',
      height: '100%',
      width: '100%',
    },
  }),
  pageCardContentControl: deviceCss({
    desktop: {
      minHeight: '600px',
      overflow: 'hidden',
    },
  }),
  pageCardContentTreatment: deviceCss({
    desktop: {
      minHeight: `min(600px, ${STRIPE_JS_MODAL_VERTICAL_COVERAGE}vh)`,
      overflow: 'hidden',
    },
  }),

  pageCardContent: deviceCss({
    all: {
      alignY: 'top',
      font: 'body.medium',
      position: 'relative',
      stack: 'y',
      // Text selection is disabled by default. This is needed to prevent the
      // the drag gesture from accidentally selecting text. This does not
      // stop the user from selecting text with the <input /> element.
      userSelect: 'none',
      zIndex: 0,
    },
    desktop: {
      // Desktop only
      borderRadius: '10px',
      width: '100%',
    },
    mobile: {
      // Mobile only.
      // Note: We use CSS variables to workaround the issue that 100vh includes
      // the height of the address bar on mobile browsers and makes the page
      // too tall to use. Instead, we use the computed viewport height and width
      // generated by useViewportSizeAsCSSVariables() hook.
      // See https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
      // for more details.
      height: 'var(--viewport-height, 100vh)',
      width: 'var(--viewport-width, 100vw)',
      transition: 'var(--viewport-transition, none)',
    },
  }),
  testModePageCardContent: deviceCss({
    desktop: {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    },
    mobile: {
      height: 'calc(var(--viewport-height, 100vh) - 24px)',
    },
  }),
  section: css({
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 1,
  }),
  loadingOverlay: css({
    alignX: 'center',
    alignY: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 'space.0',
    gap: 'medium',
    left: 'space.0',
    position: 'absolute',
    right: 'space.0',
    stack: 'y',
    top: 'space.0',
    zIndex: 1000,
  }),
};

/**
 * The side effect that sets the measured page card size as CSS variables.
 * @param domRef The ref to the page card DOM element.
 */
function usePageCardSizeAsCSSVariables(
  domRef: React.RefObject<HTMLDivElement>,
) {
  // This sets the CSS variables (--element-offset-height and
  // --element-offset-width) to the element.
  useElementSizeAsCSSVariables(domRef);

  useEffect(() => {
    const dom = domRef.current;
    if (!dom) {
      return;
    }
    dom.style.setProperty(
      '--page-card-offset-height',
      'var(--element-offset-height)',
    );
    dom.style.setProperty(
      '--page-card-offset-width',
      'var(--element-offset-width)',
    );
  }, [domRef]);
}

/**
 * This hook informs the containing iframe that the page inside the iframe has
 * been loaded.
 */
function usePostMessageForIframeLoad() {
  useLayoutEffect(() => {
    isInIframe() && postIframeEvent('load');
  }, []);
}

/**
 * This hook informs the containing iframe to adjust its height.
 */
function usePostMessageForIframeHeight(
  domRef: React.RefObject<HTMLDivElement>,
) {
  useLayoutEffect(() => {
    if (!isInIframe()) {
      return;
    }

    let rid = 0;
    const resizeModalEnabled = experiments.isActive('butter_resize_modal');
    const postMessage = () => {
      try {
        let data: Record<string, number | string> = {
          height: STRIPE_JS_MODAL_HEIGHT,
        };
        if (resizeModalEnabled) {
          data = {
            height: `min(${STRIPE_JS_MODAL_HEIGHT}px, ${STRIPE_JS_MODAL_VERTICAL_COVERAGE}vh)`,
            padding: 0,
          };
        }

        // Inform the parent window about the height of iframe.
        // This expects the parent to adjust the height of the iframe
        // accordingly.
        window.parent.postMessage(
          // In Butter, the page height is fixed to 600px by default
          // In shorter screens, we do resize this down
          {...data, type: 'resize'},
          '*',
        );
        analytics.track('postIframeMessage', {
          iframe: true,
          state: {type: 'resize', body: data},
        });
      } catch (ex) {
        const cause = asError(ex);
        const error = new Error(ErrorCode.failedToPostMessage, {cause});
        handleException(error, String(cause));
      }
    };

    const handleResize = () => {
      cancelAnimationFrame(rid);
      // Debounce postMessage to the next frame to avoid layout thrashing.
      rid = requestAnimationFrame(postMessage);
    };

    window.addEventListener('resize', handleResize, true);
    postMessage();
    return () => {
      cancelAnimationFrame(rid);
      window.removeEventListener('resize', handleResize, true);
    };
  }, [domRef]);
}

/**
 * The overlay that shows a spinner when the page is loading.
 */
function LoadingOverlay(): JSX.Element {
  return (
    <view.div uses={[Styles.loadingOverlay]}>
      <Spinner data-testid="loading-spinner" size="large" />
    </view.div>
  );
}

/**
 * This renders the outer frame that contains the page component.
 */
export function OuterFrame(props: {children: React.ReactNode}): JSX.Element {
  const {children} = props;
  return <view.div>{children}</view.div>;
}

/**
 * This renders the inner frame that contains the page component.
 */
export default function PageCard(props: PageCardProps): JSX.Element {
  const {
    'data-testid': dataTestId,
    alignBodyItems,
    body,
    bodySectionStyles,
    footer,
    header,
    loading,
    redirectIfHasSessionError,
    contentStyles: explicitContentStyles,
  } = props;
  // Only track the initial events
  const pageLoading = useRef(false);
  const pageLoaded = useRef(false);
  const testMode = useTestMode();

  const domRef = useViewportSizeAsCSSVariables();
  const willRedirect = useAppRedirectIfHasSessionError(
    !!redirectIfHasSessionError,
  );
  // Preload the list of available camera devices.
  useAppCameraDevicesList();
  usePreloadPagesForNextPageTransition();
  usePullToRefreshBlocker();
  usePageCardSizeAsCSSVariables(domRef);

  useHandoffWarningSheet();

  const shouldLoadM2Models = experiments.isActive('document_upload_page_v2');
  const resizeModalEnabled = experiments.isActive('butter_resize_modal');
  usePreloadIDDetectors(shouldLoadM2Models === false);
  usePreloadInspectors(shouldLoadM2Models === true);
  usePostMessageForIframeHeight(domRef);
  usePostMessageForIframeLoad();
  const showLoadingContent = loading || willRedirect;
  const loadTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (showLoadingContent) {
      if (!pageLoading.current) {
        loadTimeout.current = trackLoadTimeout();
        pageLoading.current = true;
      }
    } else if (pageLoading.current && !pageLoaded.current) {
      stopLoadTimeout(loadTimeout.current);
      pageLoaded.current = true;
    }
  }, [showLoadingContent]);

  const contentStyles = [Styles.pageCardContent, withPageTransitionAnimation];
  if (testMode) {
    contentStyles.push(Styles.testModePageCardContent);
  }
  if (resizeModalEnabled) {
    contentStyles.push(Styles.pageCardContentTreatment);
  } else {
    contentStyles.push(Styles.pageCardContentControl);
  }

  let effectiveContentStyles = contentStyles;

  if (explicitContentStyles) {
    effectiveContentStyles = effectiveContentStyles.concat(
      explicitContentStyles,
    );
  }

  const content = (
    <>
      <TestModeBanner />
      <view.div uses={effectiveContentStyles}>
        <LayerRoot />
        {header && <HeaderSection>{header}</HeaderSection>}
        {body && (
          <BodySection
            alignBodyItems={alignBodyItems}
            bodySectionStyles={bodySectionStyles}
          >
            {body}
          </BodySection>
        )}
        {footer && <FooterSection>{footer}</FooterSection>}
      </view.div>
    </>
  );

  const loadingContent = (
    <view.div uses={contentStyles}>
      <LayerRoot />
      <LoadingOverlay />
    </view.div>
  );

  return (
    <view.div
      data-testid={dataTestId}
      uses={[Styles.pageCard, tColorNormal]}
      ref={domRef}
    >
      {showLoadingContent ? loadingContent : content}
    </view.div>
  );
}

/**
 * The heading section of the page card. This section will be fixed to the top
 * and remain visible even when the body section scrolls in mobile layout.
 */
function HeaderSection(props: {children: React.ReactNode}): JSX.Element {
  const {children} = props;
  return (
    <view.section
      uses={[Styles.section, Styles.header, withoutPageTransitionAnimation]}
      data-testid="page-card-header"
    >
      {children}
    </view.section>
  );
}

/**
 * The body section of the page card. This section will scroll if the content
 * extdends beyond the height of the card in mobile layout.
 */
function BodySection(
  props: BodySectionProps & {children: React.ReactNode | null | undefined},
): JSX.Element {
  const domRef = useRef<HTMLElement>(null);
  useScrollableArea(domRef);
  const {alignBodyItems, bodySectionStyles, children} = props;
  const styles = [Styles.section, Styles.body, withScrollShadows];
  if (alignBodyItems === 'center') {
    styles.push(Styles.alignBodyItemsCenter);
  }
  if (bodySectionStyles) {
    styles.push(...bodySectionStyles);
  }
  return (
    <view.section ref={domRef} uses={styles} data-testid="page-card-body">
      {children}
    </view.section>
  );
}

/**
 * The footer section of the page card. This section will be fixed to the bottom
 * and remain visible even when the body section scrolls in mobile layout.
 */
function FooterSection(props: {children: React.ReactNode}): JSX.Element {
  const {children} = props;
  return (
    <view.section uses={[Styles.section]} data-testid="page-card-footer">
      {children}
    </view.section>
  );
}
