import * as React from 'react';

import analytics from 'gelato/frontend/src/lib/analytics';
import {logInDev} from 'gelato/frontend/src/lib/assert';
import ErrorCap from 'gelato/frontend/src/lib/errorCap';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {handleException, isIgnoredError} from 'gelato/frontend/src/lib/sentry';
import {isSessionExpired} from 'gelato/frontend/src/lib/sessionError';

type Props = {
  children: React.ReactNode;
  uuid: string;
};

type State = {
  error: Error | null | undefined;
};

export const shouldTrackError = (error: Error) =>
  !isIgnoredError(error) && !isSessionExpired();

const trackErrorAnalytics = async (error: Error) => {
  try {
    await analytics.track('uncaughtError', {
      errorName: error.name,
      errorMessage: error.message,
    });
  } catch (err: any) {
    if (err.name !== 'NetworkError') {
      handleException(err, 'Error tracking event', {
        tags: {
          eventName: 'uncaughtError',
        },
      });
    }
  }
};

const reportErrorMetrics = async (error: Error) => {
  try {
    reportMetric({
      metric: 'gelato_frontend_uncaught_exception',
      operation: 'count',
      value: 1,
      storytime: [
        {
          key: 'errorName',
          value: error.name,
        },
        {
          key: 'errorMessage',
          value: error.message,
        },
      ],
    });
  } catch (err: any) {
    if (err.name !== 'NetworkError') {
      handleException(err, 'Error reporting metric', {
        tags: {
          metric: 'gelato_frontend_uncaught_exception',
        },
      });
    }
  }
};

// Tracks analytic event and logs metric when children throw an error
class AnalyticsErrorBoundary extends React.Component<Props, State> {
  errorCap: ErrorCap;

  constructor(props: Props) {
    super(props);
    // @ts-expect-error - TS2741 - Property 'error' is missing in type '{}' but required in type 'Readonly<State>'.
    this.state = {};
    this.errorCap = new ErrorCap({errorLimit: 5});
  }

  // eslint-disable-next-line @sail/observability-no-error-boundary
  componentDidCatch(error: Error) {
    if (shouldTrackError(error)) {
      this.errorCap.incrementErrorCount(error);
      if (this.errorCap.shouldDropError(error)) {
        // Don't report the error if we've already seen it a bunch of times
        return;
      }
      trackErrorAnalytics(error);
      reportErrorMetrics(error);
    } else {
      logInDev('Ignored uncaught exception', error);
    }

    // Re-throw so it's caught by ErrorBoundary
    this.setState({error});
  }

  render() {
    const {children} = this.props;
    const {error} = this.state;

    if (error) {
      throw error;
    }
    return children;
  }
}

export default AnalyticsErrorBoundary;
