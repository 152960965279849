import {isServer} from '@sail/engine';
import {LAYERS_CONTAINER_NAME} from '@sail/global-state';

/*
 * On iOS Safari, blocking the document scroll is a bit more complex than just setting overflow: hidden on the body.
 * For context:
 *  - https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
 *  - https://www.bram.us/2021/09/13/prevent-items-from-being-hidden-underneath-the-virtual-keyboard-by-means-of-the-virtualkeyboard-api/
 *
 * This solution is heavily inspired by https://github.com/willmcpo/body-scroll-lock
 */

declare global {
  interface Window {
    MSStream?: unknown;
  }
}

function isIOS() {
  if (isServer) {
    return false;
  }

  // Check 1: Direct Platform Check (Works for iPhone, iPod, older iPads)
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  }

  // Check 2: Handle iPadOS 13+ pretending to be macOS
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) {
    // This is a strong indicator of an iPad running iPadOS 13+
    return true;
  }

  // Check 2: Less reliable User Agent String check as a last resort
  // Added '&& !window.MSStream' to avoid matching IE11 emulation mode
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

// Finds the closest parent element with scroll. The element must have overflow: auto/scroll
// and its content be taller/wider than the scrolling viewport.
function findScrollableParent(element: HTMLElement | null): HTMLElement | null {
  if (!element) {
    return null;
  }

  let currentElement: HTMLElement | null = element.parentElement;

  while (currentElement) {
    // Stop traversing up the tree if we reach the Layer element
    if (currentElement.classList.contains(LAYERS_CONTAINER_NAME)) {
      return null;
    }

    const style = window.getComputedStyle(currentElement);
    const hasOverflow = ['auto', 'scroll'].includes(
      style.overflow.toLowerCase(),
    );
    const hasOverflowX = ['auto', 'scroll'].includes(
      style.overflowX.toLowerCase(),
    );
    const hasOverflowY = ['auto', 'scroll'].includes(
      style.overflowY.toLowerCase(),
    );

    if (
      (hasOverflow || hasOverflowX) &&
      currentElement.scrollWidth > currentElement.clientWidth
    ) {
      return currentElement;
    }

    if (
      (hasOverflow || hasOverflowY) &&
      currentElement.scrollHeight > currentElement.clientHeight
    ) {
      return currentElement;
    }

    // Move up to the next parent
    currentElement = currentElement.parentElement;
  }

  return null;
}

const isIOSDevice = isIOS();
let currentScrollableContainer: HTMLElement | null = null;

function allowTouchMove(event: TouchEvent): boolean {
  if (
    (event.target as HTMLElement)?.closest('[data-body-scroll-lock-ignore]')
  ) {
    return true;
  }

  return false;
}

function preventDefault(rawEvent: TouchEvent): boolean {
  const event = rawEvent || window.event;

  // For the case whereby consumers adds a touchmove event listener to document.
  if (allowTouchMove(event)) {
    return true;
  }

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom).
  if (event.touches.length > 1) {
    return true;
  }

  if (event.preventDefault) {
    event.preventDefault();
  }

  return false;
}

function handleScroll(event: TouchEvent): void {
  if (event.targetTouches.length === 1) {
    if (allowTouchMove(event)) {
      return;
    }

    if (
      !currentScrollableContainer ||
      !currentScrollableContainer.contains(event.target as HTMLElement)
    ) {
      preventDefault(event);
      return;
    }

    event.stopPropagation();
  }
}

function removeCurrentScrollableListeners(): void {
  if (currentScrollableContainer) {
    currentScrollableContainer.removeEventListener('touchmove', handleScroll);
    currentScrollableContainer = null;
  }
}

function touchStart(event: TouchEvent): void {
  if (event.targetTouches.length > 1) {
    return;
  }

  currentScrollableContainer = findScrollableParent(
    event.target as HTMLElement,
  );

  if (currentScrollableContainer) {
    currentScrollableContainer.addEventListener('touchmove', handleScroll, {
      passive: false,
    });
  }
}

function touchEnd(event: TouchEvent) {
  if (event.targetTouches.length > 1) {
    return;
  }

  removeCurrentScrollableListeners();
}

function lockScrollEvents() {
  document.addEventListener('touchstart', touchStart);
  // Some browsers set touch events to passive by default, which means we can't prevent them.
  // That's why we explicitly set passive to false here - https://developer.chrome.com/blog/scrolling-intervention
  document.addEventListener('touchmove', preventDefault, {passive: false});
  document.addEventListener('touchend', touchEnd);
  document.addEventListener('touchcancel', touchEnd);
}

function unlockScrollEvents() {
  document.removeEventListener('touchstart', touchStart);
  document.removeEventListener('touchmove', preventDefault);
  document.removeEventListener('touchend', touchEnd);
  document.removeEventListener('touchcancel', touchEnd);

  removeCurrentScrollableListeners();
}

export function scrollLock() {
  if (isIOSDevice) {
    window.requestAnimationFrame(() => {
      if (typeof document.body.dataset.scrollY === 'undefined') {
        const {scrollY, innerHeight} = window;

        // For iOS: prevent layout shift when document scroll is at the end
        window.scrollTo(0, -1);

        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
        document.body.style.overflow = 'hidden'; // In iOs this is only needed to hide the scrollbar, not to lock the scrolling

        document.body.dataset.scrollY = `${scrollY}`;

        setTimeout(
          () =>
            window.requestAnimationFrame(() => {
              // Attempt to check if the bottom bar appeared due to the position change
              const bottomBarHeight = innerHeight - window.innerHeight;

              if (bottomBarHeight && scrollY >= innerHeight) {
                // Move the content further up so that the bottom bar doesn't hide it
                document.body.style.top = `${-(scrollY + bottomBarHeight)}`;
              }
            }),
          300,
        );
      }
    });

    lockScrollEvents();
  } else {
    document.body.style.overflow = 'hidden';
  }
}

export function scrollUnlock() {
  if (isIOSDevice) {
    window.requestAnimationFrame(() => {
      if (document.body.dataset.scrollY) {
        const scrollY = parseInt(document.body.dataset.scrollY || '0', 10);

        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.overflow = '';

        delete document.body.dataset.scrollY;

        window.scrollTo(0, scrollY);
      }
    });

    unlockScrollEvents();
  } else {
    document.body.style.overflow = '';
  }
}
