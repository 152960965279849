import {createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {css, baseline} from '../css';
import {tokens} from '../tokens';
import {IconConfig} from './Icon';

export type BadgeProps = View.IntrinsicElement<
  'span',
  {
    /**
     * Content to be displayed inside the badge
     */
    children: React.ReactNode;
    /**
     * **Neutral**: Neutral label, no action needed, things are working as expected
     *
     * **Info**: Identifies an object or action with an important attribute or a key state
     *
     * **Positive**: Positive, neutral and important outcome or category, no action needed
     *
     * **Negative**: Negative, important outcome or category, no action needed
     *
     * **Warning**: Needs immediate action, optional to resolve
     *
     * **Urgent**: Needs immediate action, strong requirement to resolve
     *
     * **New**: Used to highlight new or changed functionality
     */
    type:
      | 'urgent'
      | 'warning'
      | 'negative'
      | 'positive'
      | 'info'
      | 'neutral'
      | 'new';
  }
>;

export const BadgeConfig = createViewConfig({
  props: {} as BadgeProps,
  name: 'Badge',
  defaults: {
    type: 'neutral',
  },
  flattens: true,
});

/**
 * Badges are used to indicate states an item or object may move through or
 * arrive at. It is common that an item can move through many states during its
 * lifecycle.
 *
 * @see https://sail.stripe.me/components/badge/
 */
export const Badge = BadgeConfig.createView('span', {
  css: {
    borderRadius: 'xsmall',
    display: 'inline',
    font: 'label.small.emphasized',
    gap: 'xsmall',
    paddingX: 'space.75',
    // Optical adjustment to ensure the default height is 20px
    paddingY: `calc(${tokens.space.xxsmall} - 1px)`,
    stack: 'x',
    whiteSpace: 'nowrap',
    border: '1px solid',
  },

  uses: [
    baseline.css('x-middle'),
    IconConfig.customize({
      defaults: {
        size: 'xsmall',
      },
    }),
  ],

  variants: {
    type: {
      neutral: css({
        color: 'onFeedback.neutral',
      }),
      info: css({
        color: 'onFeedback.info',
      }),
      positive: css({
        color: 'onFeedback.success',
      }),
      negative: css({
        color: 'onFeedback.critical',
      }),
      warning: css({
        color: 'onFeedback.attention',
      }),
      urgent: css({
        color: 'onFeedback.critical.emphasized',
      }),
      new: css({
        color: tokens.color.brand[800].toString(),
        fill: tokens.color.brand[800].toString(),
        borderColor: tokens.color.brand[200].toString(),
        backgroundColor: tokens.color.brand[200].toString(),
      }),
    },
  },
});
