import {createViewConfig} from '@sail/engine';
import type {View} from '../view';

export const HeaderConfig = createViewConfig({
  name: 'Header',
  props: {} as View.IntrinsicElement<'header'>,
  flattens: true,
});

/**
 * Headers are used to represent introductory content within a page, or a section of a page.
 *
 * `Header` provides no specific styling by itself, but receives customizations from the parent container.
 *
 * @see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/header
 */
export const Header = HeaderConfig.createView('header');
