import {createCustomProperty} from '@sail/engine';
import {Style} from '@sail/engine';

export type FontProperties = {
  current: Style.CustomProperty;
  inherited: Style.CustomProperty;
};

function createFontProperties(
  name: `--${string}`,
  options: Style.CustomPropertyOptions,
): FontProperties {
  return {
    current: createCustomProperty(name, options),
    inherited: createCustomProperty(`${name}-ref`, options),
  };
}

export const fontSizeProp = createFontProperties('--font-size', {
  initialValue: '0px',
  inherits: true,
});
export const lineHeightProp = createFontProperties('--line-height', {
  initialValue: '0px',
  inherits: true,
});
export const ascenderProp = createFontProperties('--ascender', {
  initialValue: '0',
  inherits: true,
});
export const capHeightProp = createFontProperties('--cap-height', {
  initialValue: '0',
  inherits: true,
});
export const xHeightProp = createFontProperties('--x-height', {
  initialValue: '0',
  inherits: true,
});
export const descenderProp = createFontProperties('--descender', {
  initialValue: '0',
  inherits: true,
});
export const hasBaselineFont = createCustomProperty(
  '--font-metrics-multiplier',
  {
    initialValue: '0',
  },
);
