import React, {useContext} from 'react';
import {animate, view, AnimatePresence} from '@sail/engine';
import {SheetStackContext} from '../hooks/useNestedSheets';

// Add an overlay just over background bottom sheet's surface area. Another approach is to
// only show a backdrop behind the top most element, but that approach
// causes a flicker as the top backdrop is removed and the next top backdrop
// is added. This approach gives the illusion that there is a backdrop between the top most bottom sheet
// and the sheets behind it, without the flicker issue.
// This can't just be a pseudo element because we want to animate it.
export const BottomSheetOverlay = () => {
  const sheetContext = useContext(SheetStackContext);
  const showSheetOverlay = sheetContext?.showSheetOverlay;
  return (
    <AnimatePresence>
      {showSheetOverlay ? (
        <view.span
          data-testid="bottom-sheet-overlay"
          uses={[
            animate.inout({
              duration: 150,
              opacity: 0,
              easing: 'ease-in-out',
            }),
          ]}
          css={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'backdrop',
            borderRadius: 'inherit',
            height: 'fill',
          }}
        />
      ) : null}
    </AnimatePresence>
  );
};
