import type * as React from 'react';
import {useRef} from 'react';
import {useListCollectionWithData} from './useListCollectionWithData';
import {useRegisterableCollection} from './useRegisterableCollection';
import type {
  ItemPropsKeys,
  ItemKeys,
  MakeAccessors,
  ExtractKeysOfType,
  IgnoredItemFields,
  ItemTransforms,
  SectionTransforms,
  ListCollection,
} from './types';

type ListCollectionArgs<
  T,
  ItemProps extends object,
  ItemDefaultField extends ItemPropsKeys<ItemProps>,
  SectionProps extends object,
  SectionIdField extends ItemKeys<ExtractKeysOfType<SectionProps, string>>,
> = {
  children?: React.ReactNode;
  data?: Array<T> | null;
  item?: MakeAccessors<T, Omit<ItemProps, IgnoredItemFields>, ItemDefaultField>;
  group?: MakeAccessors<
    T,
    Omit<SectionProps, IgnoredItemFields>,
    SectionIdField
  >;
  itemTransforms: ItemTransforms<ItemProps, ItemDefaultField>;
  groupTransforms: SectionTransforms<SectionProps, SectionIdField>;
};

/**
 * Process a list of data into a structure shaped by items and groups. This
 * handles two different ways of providing data: rendering (use JSX to define
 * static data) and a data array (provide a dynamic list of items in the `data`
 * prop). These take two very different code paths, and have different tradeoffs,
 * but overall achieve the same functionality.
 *
 * Eventually, we should provide a `useTableCollection` that provides this functionality
 * for table-shaped data (instead of mapping items and groups, it would take a column
 * mapping to generate a table).
 */
export function useListCollection<
  T extends object,
  ItemProps extends object,
  ItemDefaultField extends ItemPropsKeys<ItemProps>,
  SectionProps extends object,
  SectionIdField extends ItemKeys<ExtractKeysOfType<SectionProps, string>>,
>({
  children,
  data,
  item,
  group,
  itemTransforms,
  groupTransforms,
}: ListCollectionArgs<
  T,
  ItemProps,
  ItemDefaultField,
  SectionProps,
  SectionIdField
>): ListCollection<ItemProps, SectionProps> {
  // Storing this in a ref means we'll never do this check again, so if users
  // accidentally provide items in a way that switches these code paths we will
  // only respect the first one, and avoid changing hook order
  const useChildren = useRef(children || !data);

  if (useChildren.current) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRegisterableCollection(children);
  }

  if (item == null) {
    throw new Error(
      'Collection provided the `data` prop without an `item` prop. ' +
        'Please provide `item` to define how to map fields',
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useListCollectionWithData<
    T,
    ItemProps,
    ItemDefaultField,
    SectionProps,
    SectionIdField
  >(data || [], item, group, itemTransforms, groupTransforms);
}
