import {memo, useCallback, useMemo, useState} from 'react';

import type {ReactNode} from 'react';

const border = 2;
const vtPad = 1;
const hzPad = 3;

const outerStyles = {
  background: 'transparent',
  border: 'solid 2px transparent',
  boxSizing: 'border-box',
  display: 'block',
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: '300', // Arbitrarily chosen based on dashboard observations.
} as const;

const innerStyles = {
  boxSizing: 'border-box',
  color: '#fff',
  display: 'block',
  fontFamily: 'Menlo, Consolas, monospace',
  fontSize: '10px',
  fontStyle: 'none',
  fontWeight: 'bold',
  maxWidth: '100%',
  overflow: 'hidden',
  padding: `${vtPad}px ${hzPad + border}px ${vtPad + border}px ${hzPad}px`,
  position: 'absolute',
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
} as const;

type Props = {
  category?: string;
  children: ReactNode;
  color: `#${string}`;
  name?: string;
  visible?: boolean;
};

function addOpacity(color: Props['color']) {
  switch (color.length) {
    case 4:
      return `${color}9`;

    case 7:
      return `${color}99`;

    default:
      throw new Error(`Invalid color passed: ${color}`);
  }
}

type ActiveInterval =
  | ReturnType<typeof setInterval>
  | ReturnType<typeof clearInterval>;

const initialSizeStyles = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
};

const pollMap = new Map<(x: any) => void, HTMLDivElement>();

let activeInterval: ActiveInterval;
let activeCount = 0;

function updateCount(add: 1 | -1) {
  activeCount += add;

  if (activeCount >= 0 && !activeInterval) {
    activeInterval = setInterval(pollPositions, 100);
  }

  if (activeCount <= 0 && activeInterval) {
    activeInterval = clearInterval(activeInterval);
  }
}

function pollPositions() {
  pollMap.forEach((node, setSizeStyles) => {
    const el = node.nextElementSibling;

    if (el instanceof HTMLElement) {
      setSizeStyles({
        left: el.offsetLeft,
        top: el.offsetTop,
        width: el.offsetWidth,
        height: el.offsetHeight,
      });
    } else {
      setSizeStyles({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      });
    }
  });
}

export default memo(function DebugBoundary({
  category,
  children,
  color,
  name,
  visible,
}: Props) {
  const [sizeStyles, setSizeStyles] = useState(initialSizeStyles);

  const outerStyle = useMemo(
    () => ({
      ...outerStyles,
      ...sizeStyles,
      borderColor: addOpacity(color),
    }),
    [sizeStyles, color],
  );

  const innerStyle = useMemo(
    () => ({
      ...innerStyles,
      backgroundColor: addOpacity(color),
    }),
    [color],
  );

  const updateSize = useMemo(() => {
    let oldValue = initialSizeStyles;

    return (newValue: typeof initialSizeStyles) => {
      if (
        newValue.left !== oldValue.left ||
        newValue.top !== oldValue.top ||
        newValue.width !== oldValue.width ||
        newValue.height !== oldValue.height
      ) {
        oldValue = newValue;
        setSizeStyles(newValue);
      }
    };
  }, []);

  const nodeRef = useCallback(
    (node) => {
      if (node) {
        pollMap.set(updateSize, node);
        updateCount(+1);
      } else {
        pollMap.delete(updateSize);
        updateCount(-1);
      }
    },
    [updateSize],
  );

  const node =
    name && category && visible ? (
      <div ref={nodeRef} data-testid="debug-boundary" style={outerStyle}>
        <div style={innerStyle}>{`${name} (${category})`}</div>
      </div>
    ) : null;

  return (
    <>
      {node}
      {children}
    </>
  );
});
