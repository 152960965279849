import * as React from 'react';
import {useContext} from 'react';
import {useFloatingParentNodeId, FloatingTree} from '@floating-ui/react';
import {assignProps, toIntent} from '@sail/engine';
import {css} from '../../css';
import {PopoverLayer as DeprecatedPopoverLayer} from '../../deprecated/components/layers/PopoverLayer';
import {useUiConfig} from '../../providers/UiConfigProvider';
import {PopoverConfig} from './PopoverConfig';
import {usePopoverIframe} from '../../iframe-overrides/PopoverOverrides';
import {MobilePopoverLayer} from './Popover.mobile';
import {DesktopPopoverLayer} from './Popover.desktop';

const popoverIframeOverrides = toIntent([
  assignProps(usePopoverIframe),
  assignProps({shouldCloseOnBlur: false}),
  css({
    backgroundColor: 'surface',
    border: '1px solid',
    borderColor: 'border',
    borderRadius: 'medium',
    boxShadow: 'overlay',
    maxHeight: 'inherit',
  }),
]);

// this is a weird place for this to live but hopefully only temporary
// until we get SUS's iframe/media query stuff sorted. Doesn't feel right in
// the deprecated channel either.
//
// used to swap out the old PopoverLayer in SUS
export const DeprecatedPopoverContext = React.createContext<{
  useDeprecatedPopoverComponents: boolean;
}>({
  useDeprecatedPopoverComponents: false,
});

export const Popover = PopoverConfig.createView((props) => {
  const uiConfig = useUiConfig();
  const isSmallScreen = !!uiConfig?.isSmallScreen;
  const isTouch = !!uiConfig?.isTouch;
  const enabledBottomSheets = !!uiConfig?.enableBottomSheets;
  const parentId = useFloatingParentNodeId();

  const shouldApplyIframeOverrides = !!uiConfig?._unstable_requestIframe;

  const isMobileAndBottomSheetEnabled =
    isSmallScreen && isTouch && enabledBottomSheets;

  const PopoverComponent = isMobileAndBottomSheetEnabled
    ? MobilePopoverLayer
    : DesktopPopoverLayer;

  const popover = (
    <PopoverComponent
      uses={shouldApplyIframeOverrides ? popoverIframeOverrides : undefined}
      {...props}
    />
  );

  const {useDeprecatedPopoverComponents} = useContext(DeprecatedPopoverContext);

  // If there is no ancestor popover, wrap the popover in a FloatingTree
  // so that nested popovers can coordinate dismissal behavior.
  return parentId === null ? (
    <FloatingTree>
      {useDeprecatedPopoverComponents ? (
        <DeprecatedPopoverLayer
          {...props}
          uses={shouldApplyIframeOverrides ? popoverIframeOverrides : undefined}
        />
      ) : (
        popover
      )}
    </FloatingTree>
  ) : (
    popover
  );
});
